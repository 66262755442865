import React, { useContext, useEffect, useState } from "react";
import { AppButton, AppInput, Label } from "..";
import { Box, Grid, Modal } from "@mui/material";
import { COLORS, MAP_API_KEY } from "../../constants";
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg";
import AppContext from "../../Context";
import {
  createTryout,
  getTargetTeamCompetition,
  getTeams,
  searchUserProfile,
} from "../../api/auth";
import { getError } from "../../utils/getError";
import { useSnackbar } from "notistack";
import AutoComplete from "react-google-autocomplete";
import { ReactComponent as CloseIcon } from "../../assets/svg/closeIcon.svg";
import moment from "moment";
import validator from 'validator';
import { validateURL } from "../../utils/ValidateEmail";

function Tryout({ getData, teamID, visible, handleClose }) {
  const { grades } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const [state, setState] = useState({
    loading: false,
    arrival_time: new Date(),
    time: new Date(),
    date: "",
    duration: "",
    location: "",
    note: "",
    grade: "",
    teams: [],
    commited_to_team: "",
    committed_to_team_custom: "",
    player_level: "",
    fee: "",
    payment_link: "",
    visibility: "",
    searchText: "",
    latitude: "",
    longitude: "",
    userList: [],
    selectedUsers: [],
    targetTeamCompetition: [],
    isURLValid: false
  });
  const {
    arrival_time,
    time,
    duration,
    location,
    note,
    date,
    grade,
    loading,
    commited_to_team,
    teams,
    committed_to_team_custom,
    player_level,
    fee,
    payment_link,
    visibility,
    searchText,
    userList,
    selectedUsers,
    targetTeamCompetition,
    latitude,
    longitude,
    isURLValid
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    _getTeams();
    _getTargetTeamCompetition();
  }, [visible]);

  const _getTeams = async () => {
    try {
      handleChange("loading", true);
      const res = await getTeams(token);
      handleChange("teams", res?.data);
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleSubmit = async () => {
    try {
      handleChange("loading", true);
      const payload = {
        date,
        arrival_time: moment(new Date(arrival_time)).format("HH:mm"),
        time: moment(new Date(time)).format("HH:mm"),
        duration,
        location,
        latitude,
        longitude,
        notes: note,
        grade,
        team_level: committed_to_team_custom,
        player_level,
        fee,
        payment_link,
        visibility: visibility?.toLowerCase(),
        team: teamID,
        players: selectedUsers?.map((user) => user?.player?.id),
      };
      const res = await createTryout(payload, token);
      handleChange("loading", false);
      handleReset();
      getData?.();
    } catch (error) {
      handleChange("loading", false);
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };


  const handleReset = () => {
    handleChange("date", "");
    handleChange("arrival_time", new Date());
    handleChange("time", new Date());
    handleChange("duration", "");
    handleChange("location", "");
    handleChange("latitude", "");
    handleChange("longitude", "");
    handleChange("notes", "");
    handleChange("grade", "");
    handleChange("team_level", "");
    handleChange("player_level", "");
    handleChange("fee", "");
    handleChange("payment_link", "");
    handleChange("visibility", "");
    handleChange("players", []);
    handleClose();
  };

  const handleSearch = async (place) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      );
      const res1 = await res.json();
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address;
      var address_components = res1?.results[0]?.address_components;
      let dState = "";
      let country = "";
      let city = "";
      let postal_code = "";
      if (address_components !== undefined) {
        const addrComp = address_components;
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0];
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name;
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name;
          } else if (typ === "country") {
            country = addrComp[i]?.long_name;
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name;
          }
        }
      }
      handleChange("location", place?.formatted_address);
      handleChange("latitude", place?.geometry.location?.lat());
      handleChange("longitude", place?.geometry.location?.lng());
      handleChange("street_address", address);
      handleChange("city", city);
      handleChange("country", country);
      handleChange("selectedState", dState);
      handleChange("zip_code", postal_code);
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const _getProfile = async (searchText) => {
    try {
      handleChange("searchText", searchText);
      const token = localStorage.getItem("token");
      if (searchText) {
        const res = await searchUserProfile(
          `?search=${searchText}&role=Player`,
          token
        );
        handleChange("userList", res?.data?.results);
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data);
      alert(`Error: ${errorText[0]}`);
    }
  };

  const list1 = grades?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ));
  const option2 = <option value={""}>Select Grades</option>;

  const gradesPlayOptions = [option2, ...list1];
  const list = ["Private", "Public"]?.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ));
  const option = <option value={""}>Select Visibility</option>;
  const list3 = targetTeamCompetition?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ));
  const option3 = <option value={""}>Select Team</option>;

  const targetTeamCompetitionOptions = [option3, ...list3];
  const visibliltyOptions = [option, ...list];

  const list4 = ["00", "15", "30", "45"]?.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ));
  const option4 = <option value={""}>Select Time</option>;

  const timeSelectOption = [option4, ...list4];

  const handleUserClick = (user) => {

    handleChange("searchText", "");
    if (selectedUsers?.some((e) => e?.id === user?.id)) {
      const removed = selectedUsers?.filter((e) => e?.id !== user?.id);
      handleChange("selectedUsers", removed);
    } else {
      handleChange("selectedUsers", [...selectedUsers, user]);
    }
  };

  const _getTargetTeamCompetition = async () => {
    try {
      const token = localStorage.getItem("token");

      const res = await getTargetTeamCompetition(token);
      handleChange("targetTeamCompetition", res?.data?.results);
    } catch (error) {
      //console.log(error)
    }
  };

  const _isURLValid = (url) => {
    if (url) {
      const isValid = validateURL(url)
      if (!isValid) {
        handleChange("isURLValid", true)
      } else {
        handleChange("isURLValid", false)
      }
    }
  }

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, height: "90%", overflow: "auto" }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Tryout</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross className="mb-1 mr-1" />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"date"}
              min={moment().format("YYYY-MM-DD")}
              height={40}
              placeholder={""}
              label={"Date"}
              value={date}
              name={"date"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"time"}
              height={40}
              placeholder={""}
              muiTime
              label={"Arrival Time"}
              value={arrival_time}
              name={"arrival_time"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"time"}
              height={40}
              placeholder={""}
              muiTime
              label={"Time"}
              value={time}
              select
              selectOptions={timeSelectOption}
              name={"time"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              type={'number'}
              label={"Duration (hrs)"}
              value={duration}
              name={"duration"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <Label text={"Location"} fontNormal />
              <AutoComplete
                apiKey={MAP_API_KEY}
                className="locationSearch"
                options={{ types: ["geocode", "establishment"] }}
                style={{ height: 40, backgroundColor: "#fff" }}
                onChange={(e) => handleChange("location", e?.target?.value)}
                defaultValue={location}
                onPlaceSelected={(place) => {
                  handleSearch(place);
                }}
                placeholder=""
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              marginTop={1}
              placeholder={""}
              label={"Notes(Optional)"}
              minHeightMulti={25}
              textAreaMarginTop={7}
              marginBottom={5}
              textAreaWidth={'95%'}
              multiline
              value={note}
              name={"note"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              select
              selectOptions={gradesPlayOptions}
              value={grade}
              name={"grade"}
              placeholder={""}
              label={"Grade (Optional)"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Team Level (Optional)"}
              value={committed_to_team_custom}
              name={"committed_to_team_custom"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Player Level (Optional)"}
              select
              selectOptions={targetTeamCompetitionOptions}
              value={player_level}
              name={"player_level"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Fee"}
              prefix={<div className="mt-2">$</div>}
              value={fee}
              name={"fee"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Payment Link"}
              onBlur={() => _isURLValid(payment_link)}
              // prefix={<div className="httpLink">https://</div>}
              value={payment_link}
              name={"payment_link"}
              errorText={isURLValid ? "URL is invalid" : ""}
              onChange={(name, value) => {
                _isURLValid(value)
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Visibility"}
              select
              selectOptions={visibliltyOptions}
              value={visibility}
              name={"visibility"}
              onChange={handleChange}
            />
          </Grid>
          {visibility === "Private" && (
            <Grid item xs={12}>
              <div className="searchTryoutUser">
                {selectedUsers?.map((user, index) => (
                  <div key={index} className="uerTag">
                    {user?.name ||
                      user?.first_name + " " + user?.last_name ||
                      "No Name"}
                    <CloseIcon
                      className="ml-2 cursor"
                      onClick={() => handleUserClick(user)}
                    />
                  </div>
                ))}
                <input
                  className="searchInput searchUserInput"
                  placeholder="Search..."
                  value={searchText}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    }
                  }}
                  onChange={(e) => _getProfile(e.target.value)}
                />
              </div>
              {searchText !== "" && userList?.length > 0 && (
                <div className="searchBox1">
                  {userList?.map((user, index) => (
                    <div
                      onClick={() => handleUserClick(user)}
                      className="saerchList"
                      key={index}
                    >
                      {user?.name ||
                        user?.first_name + " " + user?.last_name ||
                        "No Name"}
                    </div>
                  ))}
                </div>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <AppButton
            title={<div>Cancel</div>}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            color={"#000000D9"}
            width={100}
            height={40}
            className={"mt-5 mr-3"}
            onClick={handleReset}
          />
          <AppButton
            title={"Save"}
            disabled={
              !date ||
              !arrival_time ||
              !time ||
              !duration ||
              !location ||
              !fee ||
              !payment_link ||
              !visibility || isURLValid || visibility === "Private" && selectedUsers?.length === 0
            }
            loading={loading}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={100}
            height={40}
            className={"mt-5"}
            onClick={handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  );
}

export default Tryout;
