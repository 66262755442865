import React, { useContext, useEffect, useState } from "react"
import { AddStats, AppButton, HomeHeader, Loader } from "../../components"
import {
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AddIcon from "@mui/icons-material/Add"
import { styled } from "@mui/material/styles"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete.svg"
import { ReactComponent as EditIcon } from "../../assets/svg/edit_blue.svg"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import AppContext from "../../Context"
import { useParams } from "react-router-dom"
import {
  deletePlayerStats,
  getAllPlayerSeason,
  getPlayerSeasons,
  getPlayerStats,
  getPlayerStatsWithSeason,
  getUserProfile
} from "../../api/auth"
import DeleteStatModal from "../../components/GeneralModal/DeleteStat"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: "#000000D9"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const StatTableComp = ({
  loading,
  headers,
  playerStats,
  deletePlayerStatsData,
  editStats,
  isDeleteStat,
  handleChange
}) => {
  return (
    <>
      <TableContainer>
        {loading ? (
          <div className="divCenter fullWidthWeb">
            <Loader />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers?.map((v, i) => {
                  return <StyledTableCell>{v}</StyledTableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {[0, 0, 0].map(team => ( */}
              {playerStats?.map((v, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {v?.date_of_game}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.points_scored}
                  </StyledTableCell>
                  <StyledTableCell align="left">{v?.assists}</StyledTableCell>
                  <StyledTableCell align="left">{v?.rebounds}</StyledTableCell>
                  <StyledTableCell align="left">{v?.steals}</StyledTableCell>
                  <StyledTableCell align="left">{v?.blocks}</StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.field_goals_made}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.field_goals_attempted}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.three_point_field_goals_made}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.three_point_field_goals_attempted}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.free_throws_made}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {v?.free_throws_attempted}
                  </StyledTableCell>
                  <StyledTableCell align="left">{v?.turnovers}</StyledTableCell>
                  <StyledTableCell align="left">{v?.fouls}</StyledTableCell>
                  <StyledTableCell sx={{ px: 0 }}>
                    <div style={{ display: "flex", gap: 0 }}>
                      <IconButton onClick={() => editStats(v?.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        // onClick={() => handleChange("isDeleteStat",true)}
                        onClick={() => deletePlayerStatsData(v?.id)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {/* {teams?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={teams?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )} */}
      </TableContainer>
      <AddStats
      // visible={statsVisible}
      // teamID={teamID}
      // organization
      // handleClose={handleClose}
      />
    </>
  )
}

const StatsTable = () => {
  const [state, setState] = useState({
    loading: false,
    statsVisible: false,
    playerStats: [],
    userProfileData: null,
    anchorEl: null,
    selectedIndex: 0,
    playerSeasons: [],
    season: null,
    playerId: null,
    statData: {},
    isDeleteStat: false,
    statId: null
  })
  const {
    loading,
    statsVisible,
    playerStats,
    userProfileData,
    anchorEl,
    selectedIndex,
    playerSeasons,
    season,
    playerId,
    statData,
    isDeleteStat,
    statId
  } = state
  const { user, _getProfile, isPlayer, isParent } = useContext(AppContext)
  const open = Boolean(anchorEl)
  const token = localStorage.getItem("token")
  const { id } = useParams()
  console.log(id, "params id ")
  useEffect(() => {
    _getProfileData()
  }, [id, statsVisible, isDeleteStat])

  const getPlayerStatsData = async (id, token, season) => {
    try {
      const playerStatsData = await getPlayerStatsWithSeason(id, token, season)
      console.log(playerStatsData, "playerStatsData")
      handleChange("playerStats", playerStatsData?.data?.results || [])
    } catch (error) {
      console.error("Error fetching player stats:", error)
    }
  }
  const deletePlayerStatsData = async (id, token) => {
    handleChange("isDeleteStat", true)
    handleChange("statId", id)
  }
  const getPlayerSeasonsData = async (id, token) => {
    try {
      const playerSeasonData = await getPlayerSeasons(id, token)
      let arr = playerSeasonData?.data?.results
      console.log(playerSeasonData, "playerSeasonData")
      handleChange("playerSeasons", playerSeasonData?.data?.results || [])
      handleChange(
        "season",
        playerSeasonData?.data?.results[arr.length-1]?.season || null
      )
      getPlayerStatsData(
        id,
        token,
        playerSeasonData?.data?.results[selectedIndex]?.season
      )
    } catch (error) {
      console.error("Error fetching player seasons:", error)
    }
  }
  const _getProfileData = async (noLoading, u_id) => {
    console.log("_getProfileData", "_getProfileData")
    try {
      if (!noLoading && !u_id) {
        handleChange("loadingData", true)
      }
      const token = localStorage.getItem("token")
      const res = await getUserProfile(id, token)
      console.log(res, "profile res")
      handleChange("userProfileData", res?.data)
      handleChange("playerId", res?.data?.player?.id)
      getPlayerSeasonsData(res?.data?.player?.id, token)
      // getPlayerStatsData(res?.data?.player?.id, token)
    } catch (error) {
      console.log(error, "lol")
      handleChange("loadingData", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const headers = [
    "Date",
    "Points",
    "Assists",
    "Rebounds",
    "Steals",
    "Blocks",
    "FGM",
    "FGA",
    "3P FGM",
    "3P FGA",
    "FTM",
    "FTA",
    "Turnovers",
    "Fouls",
    "Actions"
  ]
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
  }

  const handleClickListItem = event => {
    handleChange("anchorEl", event.currentTarget)
  }

  const handleMenuItemClick = (event, index, season) => {
    console.log(event, index, season, "season value")
    handleChange("anchorEl", null)
    handleChange("selectedIndex", index)
    getPlayerStatsData(playerId, token, season)
  }

  const handleSeasonClose = () => {
    handleChange("anchorEl", null)
  }
  const editStats = id => {
    const singleStat = playerStats?.filter(item => item?.id === id)
    console.log(singleStat, "singleStats")
    handleChange("statData", singleStat?.[0])
    handleChange("statsVisible", true)
  }
  const handleCloseStatModal = () => {
    handleChange("isDeleteStat", false)
  }

  return (
    <div className="bg-white">
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ paddingTop: "3rem" }}>
            <section className="container pb-3 mb-0 bg-transparent">
              <Grid className="mb-3 border round-1">
                <Grid
                  className="mt-2 mb-2 p-2"
                  alignItems={"center"}
                  container
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <img
                        src={userProfileData?.player?.picture || AvatarIcon}
                        className={"c-pointer memberprofile"}
                      />
                      <div>
                        <div
                          className="font-bold text-black font-14 d-flex"
                          style={{ gap: 10 }}
                        >
                          {userProfileData?.name}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item>
                        <List
                          component="nav"
                          aria-label="Device settings"
                          sx={{
                            bgcolor: "background.paper",
                            border: "1px solid #D9D9D9"
                          }}
                        >
                          <ListItemButton
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickListItem}
                            sx={{ px: 2, py: 0.3 }}
                          >
                            <ListItemText
                              primary={
                                playerSeasons[selectedIndex]?.season
                                  ? `Season ${playerSeasons[selectedIndex]?.season}`
                                  : "Season"
                              }
                            />
                          </ListItemButton>
                        </List>
                        <Menu
                          id="lock-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleSeasonClose}
                          MenuListProps={{
                            "aria-labelledby": "lock-button",
                            role: "listbox"
                          }}
                        >
                          {playerSeasons?.map((option, index) => (
                            <MenuItem
                              key={index}
                              selected={index === selectedIndex}
                              onClick={event =>
                                handleMenuItemClick(
                                  event,
                                  index,
                                  option?.season
                                )
                              }
                            >
                              Season{option?.season}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Grid>
                      <Grid item>
                        {(user?.role === "Parent" ||
                          user?.role === "Player") && (
                          <AppButton
                            color={"#000"}
                            width={120}
                            height={35}
                            className={"mr-2"}
                            fontWeight={"400"}
                            title={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: -5 }}
                              >
                                <AddIcon
                                  fontSize="small"
                                  style={{ marginTop: -3 }}
                                />
                                <span
                                  className="ml-1"
                                  style={{ fontSize: "16px" }}
                                >
                                  Add Stats
                                </span>
                              </div>
                            }
                            onClick={() => {
                              handleChange("statsVisible", true)
                              // handleChange("playerData", {
                              //   id: userData?.player?.id,
                              //   name: userData?.player?.user?.name
                              // })
                            }}
                            // loading={!followVisible && loadingFollow}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <StatTableComp
                  headers={headers}
                  playerStats={playerStats}
                  deletePlayerStatsData={deletePlayerStatsData}
                  editStats={editStats}
                  handleChange={handleChange}
                  isDeleteStat={isDeleteStat}
                />
              </Grid>
            </section>
          </div>
        </>
      )}
      <AddStats
        visible={statsVisible}
        handleClose={handleClose}
        statData={statData}
      />
      <DeleteStatModal
        visible={isDeleteStat}
        handleClose={handleCloseStatModal}
        statId={statId}
        season={season}
      />
    </div>
  )
}

export default StatsTable
