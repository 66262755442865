import React, { useContext, useEffect, useState } from "react"
import { AppButton } from ".."
import { Box, Grid, Modal, useMediaQuery, useTheme } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import {
  reqLinkToPlayer,
  searchUserProfile,
  sendFeedback
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import { useNavigate, useParams } from "react-router-dom"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"

function LinkPlayer({ visible, handleClose, data }) {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const theme = useTheme();
  const { user } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? '320px' : isMediumScreen ? '400px' : '500px',
    borderRadius: 6,
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    py: 4,
    px: 2,
    overflowY: "scroll"
  }
  const [state, setState] = useState({
    message: "",
    email: "",
    loading: false,
    selectedItem: null,
    otherItem: null,
    filteredList: [],
    sentRequest: { request: false, id: null },
    allPlayers: [],
    nextPageUrl: null
  })
  const {
    email,
    message,
    selectedItem,
    filteredList,
    sentRequest,
    allPlayers,
    nextPageUrl
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    // if (list) {
    //   handleChange("filteredList", list)
    // }
    // handleChange("filteredList", [])

    getAllPlayers("?role=Player")
  }, [])

  const handleSubmit = async id => {
    try {
      handleChange("loading", true)
      const payload = {
        player_id: id
      }
      const req = await reqLinkToPlayer(payload, token)
      console.log(req, "req for mail requws")
      handleChange("loading", false)
      handleChange("sentRequest", { request: true, id: id })
      // handleClose(true)
      enqueueSnackbar(`Linking request sent successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleUserClick = user => {
    handleClose()
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : `/parent-profile/${user?.id}`
    navigate(route)
  }

  const filtered = value => {
    if (value) {
      // const filtered = list?.filter(e =>
      //   e?.name?.toLowerCase()?.includes(value?.toLowerCase())
      // )
      _getProfile(value)
    } else {
      // handleChange("filteredList", filteredList)
      getAllPlayers("?role=Player")
    }
  }

  const getAllPlayers = async params => {
    try {
      handleChange("loading", true)
      const res = await searchUserProfile(params, token)
      console.log(res, "list of user")
      const localPlayers = [...allPlayers, ...res?.data?.results]
      console.log(localPlayers, "localPlayers")
      handleChange("allPlayers", localPlayers)
      handleChange("nextPageUrl", res?.data?.next)
      handleChange("filteredList", localPlayers)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleScroll = event => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight
    console.log(bottom,'bottom')
    if (bottom && nextPageUrl) {
      let paramURL = nextPageUrl.split("?")[1]
      console.log(nextPageUrl, paramURL, "paramURL")
      getAllPlayers(`?${paramURL}`)
    }
  }

  const _getProfile = async searchText => {
    try {
      handleChange("searchText", searchText)
      const token = localStorage.getItem("token")
      if (searchText) {
        const res = await searchUserProfile(`?role=Player&search=${searchText}`, token)
        handleChange("filteredList", res?.data?.results)
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const currentUser = id == user?.id
  const currentUserID = user?.id

  return (
    <Modal
      open={visible}
      onClose={() => {
        handleClose(false)
      }}
      sx={{ zIndex: 9999999 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} onScroll={handleScroll}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-bold greyColor">Link Player</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <div className="searchInputHeaderBox mb-3" style={{ width: "100%" }}>
          <input
            className="searchInput"
            placeholder="Search..."
            onChange={e => filtered(e.target.value)}
          />
          <div className="saerchIconHeaderDiv">
            <SearchGrey />
          </div>
        </div>
        <div>
          {filteredList?.map((member, index) => (
            <div className="parentGreyBox" key={index}>
              <div className="flex-between" style={{ width: "100%" }}>
                <div className="d-flex" style={{ alignItems: "center" }}>
                  <img
                    src={member?.player?.picture || AvatarIcon}
                    className={"c-pointer memberprofile"}
                  />
                  <div>
                    <div
                      className="font-bold text-black font-14 d-flex"
                      style={{ gap: 10 }}
                    >
                      {member?.name || "Guest"}
                    </div>
                  </div>
                </div>
                <div className="d-flex" style={{ gap: 10 }}>
                  {sentRequest?.id === member?.player?.id &&
                  sentRequest?.request ? (
                    <div
                      className="text_blue_link font-14 cursor"
                      // onClick={() => {
                      //   handleSubmit(95)
                      //   handleSubmit(member?.player?.id)
                      // }}
                    >
                      Sent
                    </div>
                  ) : (
                    <div
                      className="text_blue_link font-14 cursor"
                      onClick={() => {
                        // handleSubmit(171)
                        handleSubmit(member?.player?.id)
                      }}
                    >
                      Link
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <Grid className="followerListDiv">
          {Array.isArray(filteredList) &&
            filteredList?.map((user, index) => (
              <Grid
                key={index}
                className="followerListGrid"
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid
                  container
                  alignItems={"center"}
                  item
                  xs={8}
                  onClick={() => handleUserClick(user)}
                >
                  <img
                    src={
                      user?.role === "Coach"
                        ? user?.coach?.picture
                        : user?.role === "Organization"
                          ? user?.organization?.logo
                          : user?.role === "Player"
                            ? user?.player?.picture
                            : user?.role === "Parent"
                              ? user?.parent?.picture
                              : AvatarIcon
                    }
                    className="followerProfile"
                  />
                  <div>
                    {user?.name ||
                      user?.first_name + " " + user?.last_name ||
                      "No Name"}
                  </div>
                </Grid>
                {currentUserID !== user?.id && (
                  <AppButton
                    disabled={
                      loading === user?.id || loadingRemove === user?.id
                    }
                    color={
                      !currentUser
                        ? !checkUserIsFollow(user)
                          ? COLORS.white
                          : "#000"
                        : "#000"
                    }
                    backgroundColor={
                      !currentUser
                        ? !checkUserIsFollow(user)
                          ? COLORS.primary
                          : "#fff"
                        : "#fff"
                    }
                    borderColor={"#D9D9D9"}
                    width={"auto"}
                    height={32}
                    title={
                      !currentUser
                        ? checkUserIsFollow(user)
                          ? "Unfollow"
                          : "Follow"
                        : isFollower
                          ? "Remove"
                          : "Unfollow"
                    }
                    onClick={() =>
                      currentUser && isFollower
                        ? _removeFollower(user?.id)
                        : isFollower
                          ? _addFollow(
                              user?.id,
                              checkUserIsFollow(user) ? "unfollow" : "follow"
                            )
                          : _addFollow(
                              user?.id,
                              checkUserIsFollow(user) ? "unfollow" : "follow"
                            )
                    }
                    fontWeight={"400"}
                  />
                )}
              </Grid>
            ))}
        </Grid> */}
      </Box>
    </Modal>
  )
}

export default LinkPlayer
