import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import Label from "../AppInput/Label"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function MultipleSelect({
  label,
  data = [],
  onChange,
  value,
  ...props
}) {
  const handleChange = event => {
    const {
      target: { value }
    } = event

    console.log(typeof value === "string" ? value.split(",") : value)
    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  console.log(value, "VALUE")

  return (
    <div>
      <Label fontNormal text={label} />

      <FormControl style={{ marginTop: 5 }} fullWidth>
        <Select
          multiple
          value={value}
          size="small"
          onChange={handleChange}
          renderValue={selected => {
            return (
              <span style={{ fontSize: 14, paddingBottom: 2 }}>
                {selected
                  ?.map(v => (v?.id ? v.id : v))
                  .map(v => data?.find(j => j.id === v)?.name)
                  .join(", ")}
              </span>
            )
          }}
          sx={{
            "& legend": { display: "none" },
            "& .MuiInputLabel-shrink": {
              opacity: 0,
              transition: "all 0.2s ease-in"
            }
          }}
          MenuProps={MenuProps}
          style={{ height: 35, borderRadius: 10 }}
          {...props}
          inputProps={{
            placeholder: "op"
          }}
        >
          {data.map(name => (
            <MenuItem key={name?.id} value={name?.id}>
              <Checkbox checked={value?.some(v => name.id === (v?.id || v))} />
              <ListItemText primary={name?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
