// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, Loader, AdminLayout } from "../../components"
import { Box, Checkbox, Grid, Modal, Typography } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { useNavigate, useParams } from "react-router-dom"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import { getUserProfile } from "../../api/auth"
import { ReactComponent as DeleteOrangeIcon } from "../../assets/svg/admin/deleteOrange.svg"
import { deleteUser } from "../../api/admin"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 6,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
}

export default function AdminPlayerProfileForm({}) {
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const token = localStorage.getItem("admintoken")
  const [state, setState] = useState({
    name: "",
    last_name: "",
    age: "",
    height_ft: "",
    height_in: "",
    weight: "",
    first_position_play: "",
    second_position_play: "",
    school: "",
    grade: "",
    date_of_graduation: "",
    phone_number: "",
    address: "",
    city: "",
    zip_code: "",
    selectedState: "",
    commited_to_team: "",
    committed_to_team_custom: "",
    previous_team: "",
    previous_team_custom: "",
    tiktok_username: "",
    display_twitter: false,
    facebook_username: "",
    preferred_jersey_number: "",
    display_facebook: false,
    instagram_username: "",
    display_instagram: false,
    twitter_username: "",
    display_tiktok: false,
    editMode: false,
    profileImg: null,
    statsVisible: false,
    loadingData: false,
    teams: [],
    userProfile: null,
    loadingDelete: false,
    visible: false
  })

  const {
    first_name,
    last_name,
    age,
    height_ft,
    height_in,
    weight,
    grade,
    first_position_play,
    second_position_play,
    school,
    date_of_graduation,
    preferred_jersey_number,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    commited_to_team,
    committed_to_team_custom,
    previous_team,
    previous_team_custom,
    tiktok_username,
    display_twitter,
    facebook_username,
    display_facebook,
    instagram_username,
    display_instagram,
    twitter_username,
    display_tiktok,
    editMode,
    profileImg,
    userProfile,
    loadingData,
    visible,
    loadingDelete
  } = state

  useEffect(() => {
    if (id) {
      _getProfileData()
    }
  }, [id])
  console.log(userProfile, "userProfile")
  const _getProfileData = async noLoading => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("admintoken")
      const res = await getUserProfile(id, token)
      const userProfile = res?.data
      handleChange("userProfile", userProfile)
      handleChange("first_name", userProfile?.first_name)
      handleChange("last_name", userProfile?.last_name)
      handleChange("name", userProfile?.name)
      handleChange("height_ft", userProfile?.player?.height_ft?.toString())
      handleChange("height_in", userProfile?.player?.height_in?.toString())
      handleChange(
        "first_position_play",
        userProfile?.player?.first_position_play?.name
      )
      handleChange(
        "second_position_play",
        userProfile?.player?.second_position_play?.name
      )
      handleChange("school", userProfile?.player?.school?.name)
      handleChange("age", userProfile?.player?.age?.toString())
      handleChange(
        "date_of_graduation",
        userProfile?.player?.date_of_graduation
      )
      handleChange(
        "commited_to_team",
        userProfile?.player?.organization_data?.organization_name
      )
      handleChange(
        "committed_to_team_custom",
        userProfile?.player?.commited_to_team?.team_name ||
          userProfile?.player?.committed_to_team_custom
      )
      handleChange("previous_team", userProfile?.player?.previous_team)
      handleChange(
        "previous_team_custom",
        userProfile?.player?.previous_team_custom
      )
      handleChange("tiktok_username", userProfile?.player?.tiktok_username)
      handleChange(
        "instagram_username",
        userProfile?.player?.instagram_username
      )
      handleChange("twitter_username", userProfile?.player?.twitter_username)
      handleChange("facebook_username", userProfile?.player?.facebook_username)
      handleChange("display_twitter", userProfile?.player?.display_twitter)
      handleChange("display_instagram", userProfile?.player?.display_instagram)
      handleChange("display_facebook", userProfile?.player?.display_facebook)
      handleChange("display_tiktok", userProfile?.player?.display_tiktok)
      handleChange("grade", userProfile?.player?.grade?.name)
      handleChange(
        "preferred_jersey_number",
        userProfile?.player?.preferred_jersey_number?.toString()
      )
      handleChange("weight", userProfile?.player?.weight?.toString())
      handleChange("phone_number", userProfile?.phone_number)
      handleChange("email", userProfile?.email)
      handleChange("city", userProfile?.city)
      handleChange("street_address", userProfile?.street_address)
      handleChange("zip_code", userProfile?.zip_code)
      handleChange("selectedState", userProfile?.state)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      handleChange("loadingDelete", true)
      await deleteUser(id, token)
      handleChange("loadingDelete", false)
      navigate(-1)
      enqueueSnackbar("User is Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleOpenDelete = () => {
    handleChange("visible", true)
  }
  const handleCloseDelete = () => {
    handleChange("visible", false)
  }

  return (
    <div className="">
      <AdminLayout>
        {loadingData ? (
          <Loader />
        ) : (
          <section className="container pb-3 mb-0 bg-transparent">
            <Grid className="mb-3" container justifyContent={"space-between"}>
              <div className="pageHeading">Profile</div>
            </Grid>
            <Grid
              className="mb-3 mt-4"
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div className="">
                <img
                  src={profileImg || userProfile?.player?.picture || AvatarIcon}
                  className={"c-pointer profileIMG"}
                />
              </div>
              <AppButton
                width={100}
                height={35}
                borderRadius={5}
                onClick={() => handleOpenDelete()}
                backgroundColor={COLORS.white}
                color={"#595959"}
                borderColor={"#D9D9D9"}
                className={"min-width-remove"}
                title={"Delete"}
              />
            </Grid>
            {/* {
              !editMode &&
              <div className="statsProfileDiv">
                <div className="container">
                  <Grid container justifyContent={'space-between'}>
                    {
                      stats?.map((item, index) => (
                        <Grid key={index} style={{ borderRight: stats?.length - 1 === index ? "none" : "1px solid #0000000F" }} md={index === 0 ? 1.5 : 1} xs={3} className="statsValueProfileDiv">
                          <div className="wp-label">{item?.key}</div>
                          <div className="wp-value">{item?.value}</div>
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
              </div>
            } */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"First Name"}
                  height={40}
                  onChange={handleChange}
                  value={first_name}
                  name={"first_name"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"Last Name"}
                  height={40}
                  onChange={handleChange}
                  value={last_name}
                  name={"last_name"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"Age"}
                  height={40}
                  onChange={handleChange}
                  value={age}
                  name={"age"}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <AppInput
                  disabled={!editMode}
                  label={"Height (Ft)"}
                  placeholder={"Ft"}
                  height={40}
                  onChange={handleChange}
                  value={height_ft}
                  name={"height_ft"}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <AppInput
                  disabled={!editMode}
                  label={"(In)"}
                  placeholder={"In"}
                  height={40}
                  onChange={handleChange}
                  value={height_in}
                  name={"height_in"}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <AppInput
                  disabled={!editMode}
                  label={"Weight (lbs)"}
                  height={40}
                  onChange={handleChange}
                  value={weight}
                  name={"weight"}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <AppInput
                  disabled={!editMode}
                  label={"First position play"}
                  height={40}
                  onChange={handleChange}
                  value={first_position_play}
                  name={"first_position_play"}
                />
              </Grid>
              <Grid item xs={12} md={2.4}>
                <AppInput
                  disabled={!editMode}
                  label={"Second position play"}
                  height={40}
                  onChange={handleChange}
                  value={second_position_play}
                  name={"second_position_play"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"School"}
                  height={40}
                  onChange={handleChange}
                  value={school}
                  name={"school"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"Date of graduation"}
                  type={"date"}
                  height={40}
                  onChange={handleChange}
                  value={date_of_graduation}
                  name={"date_of_graduation"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"Class"}
                  height={40}
                  onChange={handleChange}
                  value={grade}
                  name={"grade"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"Phone number"}
                  type={"number"}
                  placeholder={"123-456-7890"}
                  height={40}
                  onChange={handleChange}
                  value={phone_number}
                  name={"phone_number"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={true}
                  label={"Street address"}
                  height={40}
                  onChange={handleChange}
                  value={street_address}
                  name={"street_address"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"City"}
                  height={40}
                  onChange={handleChange}
                  value={city}
                  name={"city"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"Zipcode"}
                  height={40}
                  onChange={handleChange}
                  value={zip_code}
                  name={"zip_code"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  label={"State"}
                  height={40}
                  onChange={handleChange}
                  value={selectedState}
                  name={"selectedState"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"Committed to (Team)"}
                  height={40}
                  onChange={handleChange}
                  value={commited_to_team}
                  name={"commited_to_team"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"Previous Team"}
                  height={40}
                  onChange={handleChange}
                  value={previous_team_custom}
                  name={"previous_team_custom"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppInput
                  disabled={!editMode}
                  label={"Preferred Jersey Number"}
                  height={40}
                  onChange={handleChange}
                  value={preferred_jersey_number}
                  name={"preferred_jersey_number"}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 0.2 }} spacing={3}>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  prefix={<Tiktok style={{ marginTop: 12 }} />}
                  label={"Tiktok Username"}
                  height={40}
                  onChange={handleChange}
                  value={tiktok_username}
                  name={"tiktok_username"}
                />
                <div className="checkboxDiv mb-3">
                  <Checkbox
                    disabled={!editMode}
                    defaultChecked={false}
                    checked={display_tiktok}
                    onClick={() =>
                      handleChange("display_tiktok", !display_tiktok)
                    }
                    className="checkbox1"
                    style={{
                      marginTop: -3
                    }}
                    sx={{
                      color: "rgba(201, 208, 216, 1)",
                      "&.Mui-checked": {
                        color: COLORS.primary
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    className="greyColor checkboxLabel"
                  >
                    Display on social media profile.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  prefix={<Facebook style={{ marginTop: 12 }} />}
                  label={"Facebook Username"}
                  height={40}
                  onChange={handleChange}
                  value={facebook_username}
                  name={"facebook_username"}
                />
                <div className="checkboxDiv mb-3">
                  <Checkbox
                    defaultChecked={false}
                    disabled={!editMode}
                    checked={display_facebook}
                    onClick={() =>
                      handleChange("display_facebook", !display_facebook)
                    }
                    className="checkbox1"
                    style={{
                      marginTop: -3
                    }}
                    sx={{
                      color: "rgba(201, 208, 216, 1)",
                      "&.Mui-checked": {
                        color: COLORS.primary
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    className="greyColor checkboxLabel"
                  >
                    Display on social media profile.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  prefix={<Insta style={{ marginTop: 12 }} />}
                  label={"Instagram User name"}
                  height={40}
                  onChange={handleChange}
                  value={instagram_username}
                  name={"instagram_username"}
                />
                <div className="checkboxDiv mb-3">
                  <Checkbox
                    disabled={!editMode}
                    checked={display_instagram}
                    defaultChecked={false}
                    onClick={() =>
                      handleChange("display_instagram", !display_instagram)
                    }
                    className="checkbox1"
                    style={{
                      marginTop: -3
                    }}
                    sx={{
                      color: "rgba(201, 208, 216, 1)",
                      "&.Mui-checked": {
                        color: COLORS.primary
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    className="greyColor checkboxLabel"
                  >
                    Display on social media profile.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  disabled={!editMode}
                  prefix={<Twitter style={{ marginTop: 12 }} />}
                  label={"Twitter Username"}
                  height={40}
                  onChange={handleChange}
                  value={twitter_username}
                  name={"twitter_username"}
                />
                <div className="checkboxDiv mb-3">
                  <Checkbox
                    disabled={!editMode}
                    defaultChecked={false}
                    checked={display_twitter}
                    onClick={() =>
                      handleChange("display_twitter", !display_twitter)
                    }
                    className="checkbox1"
                    style={{
                      marginTop: -3
                    }}
                    sx={{
                      color: "rgba(201, 208, 216, 1)",
                      "&.Mui-checked": {
                        color: COLORS.primary
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    className="greyColor checkboxLabel"
                  >
                    Display on social media profile.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </section>
        )}
      </AdminLayout>
      <Modal
        open={visible}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className="mt-3" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: 2 }}
              >
                Are you sure you want to delete this user? You won’t be able to
                recover it
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-3">
            <AppButton
              title={"Delete"}
              height={40}
              loading={loadingDelete}
              onClick={() => _deleteUser()}
              width={"100%"}
              fontWeight={"normal"}
              backgroundColor={COLORS.orange}
              color={COLORS.white}
            />
            <AppButton
              title={"Cancel"}
              height={40}
              onClick={handleCloseDelete}
              width={"100%"}
              backgroundColor={"#fff"}
              className={"mt-2"}
              color={"#595959"}
              borderColor={"#D9D9D9"}
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
