import React, { useContext, useEffect, useRef, useState } from "react"
import AppInput from "../AppInput"
import { ReactComponent as SendIcon } from "../../assets/svg/Send.svg"
import AvatarIcon from "../../assets/images/avatar.png"
import Postimage from "../../assets/images/dashboard.jpg"
import { ReactComponent as LocationOnGrey } from "../../assets/svg/location_on_grey.svg"
import { ReactComponent as LikeIcon } from "../../assets/svg/Like.svg"
import { ReactComponent as MessageIcon } from "../../assets/svg/Message.svg"
import { ReactComponent as ShareIcon } from "../../assets/svg/share_icon.svg"
import Microlink from "@microlink/react"
import { Waypoint } from "react-waypoint"
import { getError } from "../../utils/getError"
import {
  DeleteUserPosts,
  addComments,
  getComments,
  getLike,
  getUsers,
  postLike
} from "../../api/auth"
import { CircularProgress } from "@mui/material"
import { COLORS } from "../../constants"
import { Link, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import MentionTextBox from "../Mention/Mention"
import Button from "@mui/material/Button"
import UserLikeModal from "../GeneralModal/UserLikeModal"
import UserShareModal from "../GeneralModal/UserShareModal"
import { getAllUsers } from "../../api/admin"
import { IconButton } from "@mui/material"
import { GridMoreVertIcon } from "@mui/x-data-grid"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useSnackbar } from "notistack"
import DeletePostModal from "../GeneralModal/DeletePost"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import MenuList from "@mui/material/MenuList"

export default function Post({
  index,
  getData,
  post,
  state,
  handleChange,
  allPlayers,
  videoPlaying,
  allUsers,
  getUserLoader,
  allChannels,
  mentionUsers,
  textTOHTML
}) {
  const videoRef = useRef()
  const { user } = useContext(AppContext)
  const _handleLeave = () => {
    videoRef?.current?.pause()
  }
  const [link, setLink] = useState("")
  const [postId, setPostId] = useState("")
  const [url, setURL] = useState("")
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleOpneDeleteModal = postId => {
    setIsDeleteModalVisible(true)
    setPostId(postId)
    setAnchorEl(null)
  }
  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const handleOpneShareModal = () => {
    setIsShareModalVisible(true)
  }
  const handleCloseShareModal = () => {
    setIsShareModalVisible(false)
  }
  const handleOpneModal = () => {
    console.log(post?.id, "post?.id")
    setVisible(true)
    _getUserLikes(post?.id)
  }
  const handleClose = () => {
    setVisible(false)
  }
  const [data, setData] = useState("")
  const [taggedUser, setTaggedUser] = useState()
  const [localstate, setLocalState] = useState({
    likes: [],
    loader: false,
    comments: [],
    users: [
      {
        id: "",
        display: ""
      }
    ],
    page: 0,
    limit: 20,
    showComment: false,
    loadingComment: false
  })

  const {
    showComment,
    comments,
    users,
    likes,
    page,
    limit,
    loadingComment,
    loader
  } = localstate
  const [commentLoading, setCommentLoading] = useState(false)
  const handleLocalChange = (key, value) => {
    setLocalState(pre => ({ ...pre, [key]: value }))
  }
  useEffect(() => {
    if (videoPlaying !== post?.id) {
      videoRef?.current?.pause()
    }
    if (videoPlaying === post?.id) {
      videoRef?.current?.start?.()
    }
  }, [videoPlaying])

  const handleUserClick = user => {
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : role === "Coach"
            ? `/coach-profile/${user?.id}`
            : `/parent-profile/${user?.id}`
    navigate(route)
  }
  const _postLike = async post => {
    try {
      handleChange("loader", true)
      const payload = { post }
      const token = localStorage.getItem("token")
      await postLike(payload, token)
      getData(true)
      handleChange("loader", false)
    } catch (error) {
      handleChange("loader", false)
      getError(error)
    }
  }
  const _addComments = async (post, index) => {
    try {
      setCommentLoading(true)
      const mentionsInText = data.match(/\(([^)]+)\)/g) || []
      const regex = /\[@(.+?)\s\(.+?\)\]/g || []
      let tagged_users = mentionsInText.map(mention =>
        parseInt(mention.slice(1, -1), 10)
      )
      let comment = data.replace(regex, "")
      const payload = { post, tagged_users, user: user, comment }
      const token = localStorage.getItem("token")
      await addComments(payload, token)
      setData("")
      getData(true)
      handleChange(`comment${index}`, "")
      setCommentLoading(false)
    } catch (error) {
      setCommentLoading(false)
      getError(error)
    }
  }
  const _getComments = async post => {
    try {
      handleLocalChange("loadingComment", true)
      const offset = limit * page
      const payload = `?post__id=${post}&limit=${limit}&offset=${offset}`
      const nextPage = page + 1
      const token = localStorage.getItem("token")
      handleChange("offset", offset)
      handleChange("page", nextPage)
      const res = await getComments(payload, token)
      handleLocalChange(`comments`, [...comments, ...res?.data?.results])
      handleLocalChange("loadingComment", false)
    } catch (error) {
      handleLocalChange("loadingComment", false)
      getError(error)
    }
  }

  const _getUserLikes = async id => {
    try {
      const token = localStorage.getItem("token")
      handleLocalChange("loader", true)
      const res = await getLike(id, token)
      handleLocalChange(`likes`, res?.data?.results)
      handleLocalChange("loader", false)
    } catch (error) {
      handleLocalChange("loader", false)
      getError(error)
    }
  }
  const commentList = comments?.length > 0 ? comments : post?.recent_comments
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // Check if post text contains a valid URL
  const text = post?.text

  const [anchorEl, setAnchorEl] = React.useState(null)
  const MenuOpen = Boolean(anchorEl)
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleListClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const arrivalTime = textTOHTML?.["Arrival Time"] || "Not specified"
  const date = textTOHTML?.["Date"] || "Not specified"
  const duration = textTOHTML?.["Duration"] || "Not specified"
  const fee = textTOHTML?.["Fee"] || "Not specified"
  const paymentlink = textTOHTML?.["Payment Link"] || "Not specified"
  const location = textTOHTML?.["Location"] || "Not specified"
  const formattedPaymentLink =
    paymentlink &&
      !paymentlink.startsWith("http://") &&
      !paymentlink.startsWith("https://")
      ? `http://${paymentlink}`
      : paymentlink

  const width = window.innerWidth

  const aspectRatios = [
    { ratio: 4 / 5, height: ((width - 40) * 5) / 4 },
    { ratio: 1 / 1, height: width - 40 },
    { ratio: 1.91 / 1, height: (width - 40) / 1.91 }
  ]

  // Determine the maximum height based on aspect ratios
  const maxHeight = Math.max(...aspectRatios.map(ar => ar.height))
  // Apply a scaling factor to make the image smaller
  const scalingFactor = 0.8 // 80% of the original size
  // Image styles
  const imageStyles = {
    // maxWidth: `${(width - 40) * scalingFactor}px`,
    // maxHeight: `${maxHeight * scalingFactor}px`,
    objectFit: "cover",
    width: post?.aspect_ratio === "2" ? 300 : 336,
    height: post?.aspect_ratio === "2" ? 500 : post?.aspect_ratio === "1" ? 250 : 336,
    marginBottom: 10
  }
  // Determine the class name based on the image aspect ratio
  const imageClass = aspectRatios.reduce((acc, ar) => {
    if (post?.images?.includes(ar.ratio)) {
      return ar.className
    }
    return acc
  }, "")

  return (
    <>
      <Waypoint onLeave={_handleLeave}>
        <div className="mt-3 mb-3">
          <div className="listpool">
            <div
              className="d-flex align-items-center cursor"
            // onClick={() => handleUserClick(post?.user)}
            >
              <img
                src={
                  post?.user?.profile_picture ||
                  post?.user?.profile?.picture ||
                  AvatarIcon
                }
                className={"c-pointer userprofile"}
                onClick={() => handleUserClick(post?.user)}
              />
              <div className="" onClick={() => handleUserClick(post?.user)}>
                {post?.user?.name ||
                  post?.user?.first_name ||
                  post?.user?.last_name ||
                  post?.user?.username ||
                  "No Name"}
                {post?.location && (
                  <div>
                    <LocationOnGrey />
                    <span className="locationText">{post?.location}</span>
                  </div>
                )}
              </div>
              {user?.id === post?.user?.id && (
                <IconButton
                  onClick={handleToggle}
                  ref={anchorRef}
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent"
                    },
                    backgroundColor: "transparent"
                  }}
                >
                  <GridMoreVertIcon />
                </IconButton>
              )}

              {/* Delete post menu */}

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom"
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleListClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => handleOpneDeleteModal(post?.id)}
                          >
                            Delete Post
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div className="postDescription">
              {post?.url_field ? (
                <div>
                  <div className="pb-2">{text}</div>
                  <Microlink url={post?.url_field} style={{ width: post?.aspect_ratio === "2" ? 300 : 336 }} />
                </div>
              ) : post?.text.includes("\n ") ? (
                <div>
                  <h4>Upcoming Tryout!</h4> <p>Date: {date}</p>
                  <p>Arrival Time: {arrivalTime}</p> <p>Duration: {duration}</p>
                  <p>Fee: {fee}</p>
                  <p>
                    Payment Link:
                    <a href={formattedPaymentLink} target="_blank">
                      Pay Here
                    </a>
                  </p>
                  <p>Location: {location}</p>
                </div>
              ) : (
                <Link
                  to={`/post/${post?.id}`}
                  style={{ textDecoration: "none", color: "#00000073" }}
                >
                  {post?.text}
                </Link>
              )}
            </div>
          </div>
          {post?.images && (
            <img
              src={post?.images || Postimage}
              // className="postimage"
              // className={`postimage ${imageClass}`}
              style={imageStyles}
            />
          )}
          {post?.video_file && (
            <video
              ref={videoRef}
              onPlay={() => {
                handleChange("videoPlaying", post?.id)
              }}
              // onEnded={() => handleChange('videoPlaying', '')}
              // onPause={() => handleChange("videoPlaying", '')}
              width="336px"
              height="340"
              controls
            >
              <source src={post?.video_file} type="video/mp4" />
              <source src={post?.video_file} type="video/ogg" />
            </video>
          )}
          <div className="d-flex align-items-center">
            {/* <StarIcon />
                                                            <span className="lightgreyColor ml-2">125</span>
                                                            <div className="vLine" /> */}
            <div
              className="d-flex align-items-center cursor"
              onClick={() => _postLike(post?.id)}
            >
              <LikeIcon />
            </div>
            <span
              className="lightgreyColor ml-2"
              onClick={() => {
                handleOpneModal()
              }}
            >
              {post?.likes_count || 0}
            </span>
            <div className="vLine" />
            <div
              className={post?.comments_allowed ? "d-flex cursor align-items-center" : "d-flex align-items-center"}
              style={{ opacity: post?.comments_allowed ? 1 : 0.5 }}
              onClick={() => {
                post?.comments_allowed && handleLocalChange("showComment", !showComment)
              }}
            >
              <MessageIcon />
              <span className="lightgreyColor ml-2">
                {post?.comments_count || 0}
              </span>
            </div>
            <div className="vLine" />
            <div
              className="d-flex cursor align-items-center"
              onClick={() => {
                handleOpneShareModal()
              }}
            >
              <ShareIcon />
              <span className="lightgreyColor ml-2">
                {post?.share_count || 0}
              </span>
            </div>
          </div>
          {showComment && (
            <div>
              <div className="commentsText">Comments</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  outline: "none",
                  width: "100%",
                  borderRadius: "10px",
                  border: "1px solid rgba(0,0,0,0.15)",
                  lineHeight: 1.3,
                  marginBottom: "10px"
                }}
              >
                <div style={{ flex: 1 }}>
                  <MentionTextBox
                    setURL={setURL}
                    data={data}
                    setData={setData}
                    border={"1px solid #cee4e5"}
                    allUsers={mentionUsers}
                  />
                </div>
                <div>
                  {commentLoading ? (
                    <CircularProgress
                      style={{ width: 20, height: 20, color: COLORS.primary }}
                    />
                  ) : (
                    <SendIcon
                      className="cursor"
                      style={{
                        opacity: state[`comment${0}`] ? 1 : 0.6
                      }}
                      onClick={() => {
                        if (data.trim() !== "") {
                          handleLocalChange("comments", [])
                          handleLocalChange("page", 0)
                          _addComments(post?.id, 0)
                        } else {
                          alert("Please enter a comment before adding.")
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              {/* <AppInput
                value={state[`comment${index}`]}
                borderRadius={10}
                // height={40}
                multiline
                height={"auto"}
                minHeightMulti={40}
                multiPadding={"0px 0px 0px 0px"}
                name={`comment${index}`}
                textareaClassName={"width95  ml-2Percent commentTextarea"}
                postfix={
                  <SendIcon
                    className="cursor"
                    style={{
                      marginTop: 10,
                      marginRight: 5,
                      opacity: state[`comment${index}`] ? 1 : 0.6
                    }}
                    onClick={() => {
                      handleLocalChange("comments", [])
                      handleLocalChange("page", 0)
                      _addComments(post?.id, state[`comment${index}`], index)
                    }}
                  />
                }
                placeholder={"Add comment"}
                onChange={(name, value) =>
                  handleChange(`comment${index}`, value)
                }
              /> */}
              {/* {post?.recent_comments?.map((comment, indexx) => (
								<div key={indexx} className="mt-3 mb-3 ">
									<div className="font-bold">
										{comment?.user?.name ||
											comment?.user?.first_name +
												" " +
												comment?.user?.last_name ||
											"Guest"}
									</div>
									<div className="locationText">{comment?.comment}</div>
								</div>
							))} */}
              {commentList?.map((comment, index) => {
                return (
                  <div key={index} className="mt-3 mb-3 ">
                    <div
                      className="font-bold cursor"
                      onClick={() => handleUserClick(comment?.user)}
                    >
                      {comment?.user?.name ||
                        comment?.user?.first_name +
                        " " +
                        comment?.user?.last_name ||
                        "Guest"}
                    </div>
                    <div className="locationText">{comment?.comment}</div>
                  </div>
                )
              })}
              {loadingComment && (
                <CircularProgress
                  style={{ width: 20, height: 20, color: COLORS.primary }}
                />
              )}
              {post?.comments_count > 3 &&
                comments?.length < post?.comments_count && (
                  <div
                    className="text-left mb-5 c-pointer"
                    onClick={() => {
                      _getComments(post?.id, index)
                    }}
                  >
                    <p className="font-bold text_primary">Show More</p>
                  </div>
                )}
            </div>
          )}
        </div>
      </Waypoint>
      <UserLikeModal
        visible={visible}
        handleClose={handleClose}
        likes={likes}
      />
      <UserShareModal
        visible={isShareModalVisible}
        handleClose={handleCloseShareModal}
        post={post}
        allUsers={allUsers}
        getUserLoader={getUserLoader}
        allChannels={allChannels}
      />
      <DeletePostModal
        visible={isDeleteModalVisible}
        handleClose={handleCloseDeleteModal}
        postId={postId}
        getData={getData}
      />
    </>
  )
}
