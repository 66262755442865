import * as React from "react"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { AppButton, Loader } from ".."
import { COLORS } from "../../constants"
import { useNavigate } from "react-router-dom"
import { ReactComponent as DeleteIcon } from "../../assets/svg/admin/delete.svg"
import { ReactComponent as DeleteOrangeIcon } from "../../assets/svg/admin/deleteOrange.svg"
import { ReactComponent as BanIcon } from "../../assets/svg/admin/ban.svg"
import { ReactComponent as UnBanIcon } from "../../assets/svg/admin/unban.svg"
import { Box, Grid, Modal, Pagination, Typography } from "@mui/material"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: "#000000D9"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 6,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
}

export default function UserTable({
  users,
  loading,
  clickNext,
  page,
  count,
  loadingDelete,
  _deleteUser,
  _banUser,
  _unBanUser
}) {
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    selectedID: "",
    visible: false,
    isBlockModalVisible: false
  })

  const { visible, selectedID, isBlockModalVisible } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleEditTeam = (id, role) => {
    if (role === "Player") {
      navigate(`/webadmin/users/player/${id}`)
    }
    if (role === "Parent") {
      navigate(`/webadmin/users/parent/${id}`)
    }
    if (role === "Organization") {
      navigate(`/webadmin/users/organization/${id}`)
    }
  }
  const handleOpenBan = (id, token) => {
    handleChange("selectedID", id)
    handleChange("isBlockModalVisible", true)
  }
  const handleCloseBan = () => {
    handleChange("selectedID", "")
    handleChange("isBlockModalVisible", false)
  }
  const handleOpenDelete = id => {
    handleChange("selectedID", id)
    handleChange("visible", true)
  }
  const handleCloseDelete = () => {
    handleChange("selectedID", "")
    handleChange("visible", false)
  }
  const renderSerialNumber = (currentPage, index) => {
    return (currentPage - 0) * 10 + index + 1
  }
  console.log(users, "users")
  return (
    <>
      <TableContainer>
        {loading ? (
          <div className="divCenter fullWidthWeb">
            <Loader />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell align="left">Email ID</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                <StyledTableCell align="left">User Type</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user, index) => (
                <StyledTableRow key={user?.team_name}>
                  <StyledTableCell>
                    {renderSerialNumber(page, index)}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    onClick={() => handleEditTeam(user?.id, user?.role)}
                  >
                    {`${user?.name || user?.first_name + " " + user?.last_name}`}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    onClick={() => handleEditTeam(user?.id, user?.role)}
                  >
                    {user?.username ? user.username : "No username"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleEditTeam(user?.id, user?.role)}
                  >
                    {user?.email}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleEditTeam(user?.id, user?.role)}
                  >
                    {user?.phone_number}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    onClick={() => handleEditTeam(user?.id, user?.role)}
                  >
                    {user?.role}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className="d-flex" style={{ marginLeft: -10 }}>
                      <AppButton
                        width={50}
                        height={30}
                        className={"min-width-remove"}
                        title={<DeleteIcon />}
                        color={COLORS.primary}
                        backgroundColor={"transparent"}
                        fontWeight={400}
                        onClick={() => handleOpenDelete(user?.id)}
                      />
                      {user?.is_active === true ? (
                        <AppButton
                          width={50}
                          height={30}
                          className={"min-width-remove"}
                          title={<BanIcon />}
                          color={COLORS.primary}
                          backgroundColor={"transparent"}
                          fontWeight={400}
                          onClick={() => handleOpenBan(user?.id)}
                        />
                      ) : (
                        <AppButton
                          width={50}
                          height={30}
                          className={"min-width-remove"}
                          title={<UnBanIcon />}
                          color={COLORS.primary}
                          backgroundColor={"transparent"}
                          fontWeight={400}
                          onClick={() => {
                            _unBanUser(user?.id)
                          }}
                        />
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {users?.length === 0 ? (
          <></>
        ) : (
          <Grid container justifyContent={"flex-end"}>
            <Pagination
              count={Math.trunc(count / 10)}
              page={page}
              onChange={(e, page) => clickNext(page)}
              shape="rounded"
            />
          </Grid>
        )}
      </TableContainer>
      <Modal
        open={visible}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className="mt-3" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: 2 }}
              >
                Are you sure you want to delete this user? You won’t be able to
                recover it
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-3">
            <AppButton
              title={"Delete"}
              height={40}
              loading={loadingDelete}
              onClick={() => _deleteUser(selectedID, handleCloseDelete)}
              width={"100%"}
              fontWeight={"normal"}
              backgroundColor={COLORS.orange}
              color={COLORS.white}
            />
            <AppButton
              title={"Cancel"}
              height={40}
              onClick={handleCloseDelete}
              width={"100%"}
              backgroundColor={"#fff"}
              className={"mt-2"}
              color={"#595959"}
              borderColor={"#D9D9D9"}
            />
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={isBlockModalVisible}
        onClose={handleCloseBan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className="mt-3" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: 2 }}
              >
                Are you sure you want to temporarily block this user?
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-3">
            <AppButton
              title={"Block"}
              height={40}
              loading={loadingDelete}
              onClick={() => _banUser(selectedID, handleCloseBan)}
              width={"100%"}
              fontWeight={"normal"}
              backgroundColor={COLORS.orange}
              color={COLORS.white}
            />
            <AppButton
              title={"Cancel"}
              height={40}
              onClick={handleCloseBan}
              width={"100%"}
              backgroundColor={"#fff"}
              className={"mt-2"}
              color={"#595959"}
              borderColor={"#D9D9D9"}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
