import axios from "axios"
import { API_URL } from "./config"
import React from "react"

export const navigationRef = React.createRef(null)

export const navigate = (name, params) => {
  if (navigationRef.current) {
    navigationRef.current.navigate(name, params)
  }
}
export const api = axios.create({
  baseURL: "https://amateur-sports-insi-42-staging.botics.co", // Base API URL
  headers: {
    "Content-Type": "application/json"
  }
})
const buildRequest = request => {
  const { body, method, url, token } = request

  const contentType =
    body instanceof FormData ? "multipart/form-data" : "application/json"
  const tokenA = token ? token : localStorage.getItem("token")
  const headers = {
    Accept: "application/json",
    Authorization: tokenA ? `Token ${tokenA}` : ""
  }

  const apiUrl = API_URL()

  const requestConfig = {
    baseURL: apiUrl,
    data: body,
    headers,
    method,
    url,
    withCredentials: false
  }

  return requestConfig
}

export const defaultResponse = {
  status: 500,
  data: {
    error: "Server error"
  }
}

export const formatError = responseError => {
  const response = responseError || defaultResponse
  const errors = response.data
  console.log(responseError, "Error===")
  console.log(response, "response===")
  return {
    code: response.status,
    message: errors
  }
}
api.interceptors.response.use(
  response =>
    new Promise((resolve, reject) => {
      resolve(response)
    }),
  error => {
    console.log(error, "error======")
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    if (error?.response?.data?.detail === "User inactive or deleted.") {
      localStorage.clear()
      window.location.href = "/login"
    } else {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
  }
)
export const makeRequest = async request => {
  const requestConfig = buildRequest(request)
  const axiosRequest = api(requestConfig)
  return {
    status: (await axiosRequest).status,
    data: (await axiosRequest).data
  }
}
