// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader } from "../../components"
import { Grid } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import { ReactComponent as BackButton } from "../../assets/svg/BackButton.svg"
import { ReactComponent as SMSAuth } from "../../assets/svg/SMSAuth.svg"
import { ReactComponent as EmailAuth } from "../../assets/svg/EmailAuth.svg"
import { ReactComponent as RightChevron } from "../../assets/svg/RightChevron.svg"
import { ReactComponent as GoogleAuth } from "../../assets/svg/GoogleAuth.svg"
import { ReactComponent as CopyIcon } from "../../assets/svg/CopyIcon.svg"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from "react-router-dom"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-phone-number-input/style.css'
import {
  generateOTP,
  loginUser,
  resendOTP,
  updateProfile,
  validateOTP,
  verifyOTP,
} from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import AuthCode from 'react-auth-code-input';

export default function OTPAuth({ }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const { _getProfile, user } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    email: "",
    password: "",
    code: "",
    loading: false,
    showPassword: false,
    isOTP: false,
    phone: '',
    selectOTPType: '',
    isSMSCode: false,
    isGoogleCode: false,
    generatedOTP: null
  })

  const {
    email,
    password,
    loading,
    selectOTPType,
    isOTP,
    code,
    phone,
    isSMSCode,
    isGoogleCode,
    generatedOTP
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const verifyCode = async () => {
    try {
      handleChange("isOTP", true)
      handleChange("loading", true)
      const payload = {
        token: code
      }
      const res = await validateOTP(payload, token)
      if (res?.data?.otp_valid) {
        localStorage.setItem("token", res?.data?.token)
        handleChange("loading", false)
        enqueueSnackbar(`OTP is verified`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        await updateProfile({ is_2fa: true }, user?.id, res?.data?.token)
        _getProfile()
        navigate("/location-access")
      } else {
        enqueueSnackbar(`OTP is invalid`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
      // setUser(res?.data?.user)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const verifyOTPCode = async () => {
    try {
      handleChange("isOTP", true)
      handleChange("loading", true)
      const payload = {
        token: code,
        email: user?.email
      }
      const res = await verifyOTP(payload, token)
      if (res?.data?.status === 'success') {
        localStorage.setItem("token", res?.data?.token)
        handleChange("loading", false)
        enqueueSnackbar(`OTP is verified`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        // await updateProfile({ is_2fa: true }, user?.id, res?.data?.token)
        _getProfile()
        navigate("/location-access")
      } else {
        enqueueSnackbar(`OTP is invalid`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        handleChange("loading", false)
      }
      // setUser(res?.data?.user)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const skip2fa = () => {
    _getProfile()
    navigate("/location-access")
  }

  const _generateOTP = async () => {
    try {
      handleChange("loading", true)
      const generatedOTP = await generateOTP('', token)
      handleChange("generatedOTP", generatedOTP?.data)
      handleChange("loading", false)
      handleChange("selectOTPType", "google")
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const _resendOTP = async () => {
    try {
      handleChange("loading", true)
      const payload = { email: user?.email }
      const res = await resendOTP(payload, token)
      handleChange("loading", false)
      enqueueSnackbar(`OTP is resent`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const _updateProfile = async (value) => {
    try {
      handleChange("loading", true)
      const payload = { default_auth: value }
      value === 'phone' && (payload['phone_number'] = phone)
      const res = await updateProfile(payload, user?.id, token)
      if (value === 'phone') {
        handleChange("isSMSCode", true)
      } else {
        handleChange("selectOTPType", value)
      }
      console.log('res', res?.data);
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const GoogleRender = () => {
    return (
      <Grid item xs={10} sm={10} md={6} lg={4} className="">
        <BackButton onClick={() => handleChange('selectOTPType', '')} className="cursor" />
        <div className="text-left">
          <div className="font-24 mb-4 font-bold greyColor">
            Google Authenticator
          </div>
          {
            isGoogleCode
              ?
              <>
                <div className="light-grey-text mb-3">
                  Enter the code sent to your phone number
                </div>
                <AuthCode
                  containerClassName={'outerotpInput'} inputClassName={"otpInput"}
                  onChange={(code) => handleChange('code', code)} />
                <div onClick={_resendOTP} className="font-bold font-14 mb-5 mt-2 greyColor cursor">
                  Resend
                </div>

              </>
              :
              <>
                <div className="font-18 font-bold mb-3 greyColor">
                  Instruction for set up
                </div>
                <div className="font-16 font-bold greyColor">
                  1. Download an authentication app
                </div>
                <div className="font-14 mb-3 greyColor">
                  We recommend downloading Google Authenticator if you don’t have one installed
                </div>
                <div className="font-16 font-bold greyColor">
                  2. Scan this QR code or copy the key
                </div>
                <div className="font-14 mb-3 greyColor">
                  Scan this QR code in the authentication app or copy the key and paste it in the authentication app.
                </div>
                <div className="text-center">
                  {
                    generatedOTP?.image &&
                    <img src={generatedOTP?.image} />
                  }
                  {
                    generatedOTP?.base32 &&
                    <div className="font-bold greyColor">
                      {generatedOTP?.base32}
                      <CopyToClipboard text={generatedOTP?.base32} onCopy={() => alert('copied')}>
                        <CopyIcon className="cursor" />
                      </CopyToClipboard>
                    </div>
                  }
                </div>
                <div className="font-16 mt-3 font-bold greyColor">
                  3. Copy and enter 6-digit code
                </div>
                <div className="font-14 mb-3 greyColor">
                  After the QR code has been scanned or the key has been entered, your authentication app will generate a 6-digit code. Copy the code and then comeback to enter it
                </div>
              </>
          }
          <AppButton
            title={isGoogleCode ? "Confirm" : "Continue"}
            onClick={() => isGoogleCode ? verifyCode() : handleChange("isGoogleCode", true)}
            loading={loading}
            disabled={isGoogleCode && code?.length !== 6}
            backgroundColor={COLORS.primary}
            className={'mb-5'}
            color={"#fff"}
          />
        </div>
      </Grid>
    )
  }

  const SMSRender = () => {
    return (
      <Grid item xs={10} sm={10} md={6} lg={4} className="">
        <BackButton onClick={() => {
          handleChange('isSMSCode', false)
          handleChange('selectOTPType', '')
        }} className="cursor" />
        <div className="text-left">
          <div className="font-24 font-bold greyColor">
            SMS
          </div>
          <div className="light-grey-text mb-5">
            {isSMSCode ? "Enter code sent to your phone number" : "Enter your phone number to get an otp code for verification"}
          </div>
        </div>
        {
          isSMSCode
            ?
            <>
              <AuthCode
                containerClassName={'outerotpInput'} inputClassName={"otpInput"}
                onChange={(code) => handleChange('code', code)} />
              <div onClick={_resendOTP} className="font-bold font-14 mb-5 mt-2 greyColor cursor">
                Resend
              </div>

            </>
            :
            <>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                className="inputPhone mb-4"
                onChange={(value) => handleChange('phone', value)}
              // error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
              />
              {/* <AppInput label={'Phone number'} value={phone} onChange={handleChange} name={"phone"} className={'mb-5'} placeholder={''} /> */}
            </>
        }
        <AppButton
          title={isSMSCode ? "Confirm" : "Continue"}
          onClick={() => isSMSCode ? verifyOTPCode() : _updateProfile('phone')}
          loading={loading}
          disabled={isSMSCode ? code?.length !== 6 : !phone || !isValidPhoneNumber(phone)}
          backgroundColor={COLORS.primary}
          color={"#fff"}
        />
      </Grid>
    )
  }

  const EmailRender = () => {
    return (
      <Grid item xs={10} sm={10} md={6} lg={4} className="">
        <BackButton onClick={() => handleChange('selectOTPType', '')} className="cursor" />
        <div className="text-left">
          <div className="font-24 font-bold greyColor">
            Email
          </div>
          <div className="light-grey-text mb-5">
            Enter code sent to your email address
          </div>
        </div>
        <AuthCode
          containerClassName={'outerotpInput'} inputClassName={"otpInput"}
          onChange={(code) => handleChange('code', code)} />
        <div onClick={_resendOTP} className="font-bold font-14 mb-5 mt-2 greyColor cursor">
          Resend
        </div>
        <AppButton
          title={"Confirm"}
          onClick={verifyOTPCode}
          loading={loading}
          disabled={code?.length !== 6}
          backgroundColor={COLORS.primary}
          color={"#fff"}
        />
      </Grid>
    )
  }

  return (
    <div>
      <HomeHeader />
      <section>
        <div className="container loginContainer">
          <Grid container justifyContent={"center"}>
            {
              selectOTPType === 'google'
                ?
                GoogleRender()
                :
                selectOTPType === "sms"
                  ?
                  SMSRender()
                  :
                  selectOTPType === 'email'
                    ?
                    EmailRender()
                    :
                    <Grid item xs={10} sm={10} md={6} lg={4} className="">
                      <div className="text-left">
                        <div className="font-24 font-bold greyColor">
                          Secure your account
                        </div>
                        <div className="light-grey-text mb-5">
                          Set up a 2FA authentication to secure your account. You have to enter the code every time you login.
                        </div>
                      </div>
                      <div className="googleAuth" onClick={_generateOTP}>
                        <div className="d-flex align-items-center">
                          <GoogleAuth />
                          <span className="ml-3 font-500">Google Authenticator</span>
                        </div>
                        <RightChevron />
                      </div>
                      <div className="googleAuth smsColor" onClick={() => handleChange("selectOTPType", "sms")}>
                        <div className="d-flex align-items-center">
                          <SMSAuth />
                          <span className="ml-3 font-500">SMS</span>
                        </div>
                        <RightChevron />
                      </div>
                      <div className="googleAuth emailColor" onClick={() => _updateProfile("email")}>
                        <div className="d-flex align-items-center">
                          <EmailAuth />
                          <span className="ml-3 font-500">Email</span>
                        </div>
                        <RightChevron />
                      </div>
                      <div
                        className="text-left mb-5 c-pointer"
                        onClick={skip2fa}
                      >
                        <p className="font-bold text_primary">Skip 2FA</p>
                      </div>
                    </Grid>
            }
          </Grid>
        </div>
      </section>
    </div>
  )
}
