import React from "react"
import { Navigate, Route, useLocation, useParams } from "react-router-dom"

const PrivateRoute = ({ children }) => {
  const { actionType } = useParams()
  const location = useLocation()
  const token = localStorage.getItem("token")
  if (!token) {
    // for parent-player linking
    if (actionType === "accept-link" || actionType === "decline-link") {
      const queryParams = new URLSearchParams(location.search)
      const linkId = queryParams.get("link_id")
      const locationString = JSON.stringify(location)

      localStorage.setItem("linkURL", locationString)
      localStorage.setItem("link_id", linkId)
      localStorage.setItem("action-type", actionType)
    }

    return <Navigate to="/login" />
  }
  return children
}

export default PrivateRoute
