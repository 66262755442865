import React from "react"

export default function Label({ className, fontNormal, text, color }) {
  return (
    <p
      style={{ fontWeight: fontNormal ? 500 : "bold", color: color }}
      className={`font-14 greyColor" mb-2 ${className ? className : ""}`}
    >
      {text}
    </p>
  )
}
