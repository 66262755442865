import { Button, CircularProgress } from '@mui/material'
import React from 'react'

export default function AppButton({
  title,
  backgroundColor,
  color,
  borderRadius,
  borderColor,
  className,
  onClick,
  loading,
  disabled,
  width,
  height,
  fontWeight,
  disabledColor,
  noOpacity,
  minWidth,
  noMarginText
}) {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={{
        minWidth: minWidth || 64,
        width: width || '100%',
        borderRadius: borderRadius || 12,
        backgroundColor: disabled && disabledColor ? disabledColor : backgroundColor || '#fff',
        borderColor: disabled && disabledColor ? "#D9D9D9" : borderColor || '#fff',
        fontWeight: fontWeight || 'bold',
        borderWidth: disabled && disabledColor ? 2 : borderColor ? 1 : 0,
        borderStyle: 'solid',
        color: disabled && disabledColor ? "#00000040" : color || '#000',
        textTransform: 'none',
        height: height || 50,
        cursor:disabled?"not-allowed":"pointer",
        opacity: disabled && !noOpacity ? 0.5 : 1
      }}
    >
      {loading ? (
        <CircularProgress
          className='loadingButton'
          style={{ color: color || '#fff' }}
        />
      ) : (
        <div className={!noMarginText && 'mt-1'}>{title}</div>
      )}
    </Button>
  )
}
