// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import {
  HomeHeader,
  AppButton,
  OrganizationTeamTable,
  AddReview,
  AddStats,
  AddCoachQuestionsReview,
  Loader,
  QRCode,
  Post
} from "../../components"
import { Avatar, Box, Divider, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import SeeMoreText from "../../components/Text/SeeMoreText"
import AppContext from "../../Context"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as QRcodeIcon } from "../../assets/svg/qrcode.svg"

import {
  checkCoachReviewStatus,
  createChannel,
  getAllPosts,
  getChannels,
  getOrganizationProfile,
  getOrganizationTeam,
  getUserProfile,
  publishMessage,
  reviewsCoachCatScore
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { useEffect } from "react"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { COLORS } from "../../constants"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import { getUsers } from "../../api/admin"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { gotoLink } from "../../utils/mixed"

export default function CoachProfile({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const { user, isPlayer, isParent } = useContext(AppContext)
  const [state, setState] = useState({
    showAll: false,
    reviewVisible: false,
    statsVisible: false,
    teams: [],
    posts: [],
    videoPlaying: "",
    showComment: "",
    loading: false,
    userData: null,
    questionReviews: [],
    questionReviewsModal: [],
    allChannels: [],
    allUsers: [],
    reviewStatus: false,
    questionsReviewVisible: false,
    loadingChat: false,
    showQRCode: false,
    organizationData: null
  })

  const {
    showAll,
    allChannels,
    allUsers,
    teams,
    loading,
    posts,
    videoPlaying,
    showComment,
    userData,
    questionReviews,
    questionReviewsModal,
    reviewStatus,
    questionsReviewVisible,
    loadingChat,
    showQRCode
  } = state
  const [getUserLoader, setGetUserLoader] = useState(false)
  const handleCloseQRCode = () => {
    handleChange("showQRCode", false)
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (id) {
      getProfileData()
      _getAllPosts()
      _getAllSharedUsers()
      _getChannels()
    }
  }, [id])

  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      getData(res?.data?.coach?.id)
      handleChange("userData", res?.data)
      // handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const res = await getAllPosts(`?user__id=${id}`, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const _createChannel = async (inquiry) => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      if (inquiry) {
        sendMessage(res?.data?.id)
      } else {
        navigate(`/messages/${res?.data?.id}`)
      }
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const sendMessage = async (id) => {
    const payload = new FormData()
    payload.append("text", "I'm interested in learning more about how working with you can help me achieve my development goals.")
    payload.append("channel", id)
    const res = await publishMessage(payload)
    if (res?.data?.status === "ERROR") {
      alert(res?.data?.message)
      return
    }
    enqueueSnackbar(`Inquiry sent`, {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      }
    })
  }

  const getData = async id => {
    try {
      handleChange("loading", true)
      handleChange("questionReviewsModal", [])
      const resReview = await reviewsCoachCatScore(`?coach_id=${id}`, token)
      const resReviewStatus = await checkCoachReviewStatus(
        `?coach_id=${id}`,
        token
      )
      handleChange("questionReviews", resReview?.data?.results)
      handleChange("questionReviewsModal", resReview?.data?.results)
      handleChange("reviewStatus", resReviewStatus?.data?.completed)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        console.log(res, "user all profile")
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const getAllCoaches = () => {
    const list = []
    teams?.results?.forEach(team => {
      if (team?.coach_name && !list?.includes(team?.coach_name)) {
        list?.push(team?.coach_name)
      }
    })
    return list
  }
  console.log("userData", userData)

  const isOwnProfile = id == user?.id
  console.log(userData, "userData====")
  return (
    <div className="">
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <section className="container p-0 width70 bg-transparent">
              <p className={"nameOrganization font-24 mt-4"}>
                {isOwnProfile ? "Your" : "Coach"} Profile
              </p>
              <Grid container>
                <Grid
                  container
                  alignItems={"center"}
                  sx={{ mb: 4 }}
                  item
                  xs={12}
                  md={8}
                >
                  <Grid item>

                    <img
                      src={
                        userData?.coach?.picture
                          ? userData?.coach?.picture
                          : AvatarIcon
                      }
                      className={"profileIMG"}
                    />
                    <Box
                      sx={{
                        marginTop: -4,
                        marginLeft: 10,
                        marginBottom: 3
                      }}
                      className="Ball-Responsive"
                    >
                      <Avatar
                        src={userData?.coach?.main_sport?.image}
                        sx={{ width: 60, borderRadius: 2, height: 60 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction={"column"}
                  alignItems={"flex-end"}
                  sx={{ mb: 4, mt: 4 }}
                  item
                  xs={12}
                  md={4}
                >
                  {isOwnProfile && (
                    <div className="d-flex">
                      <AppButton
                        width={35}
                        height={35}
                        onClick={() => handleChange("showQRCode", true)}
                        borderRadius={35}
                        className={"min-width-remove mr-2"}
                        title={
                          <QRcodeIcon style={{ marginTop: -3 }} width={20} />
                        }
                      />
                      <AppButton
                        width={35}
                        height={35}
                        borderRadius={35}
                        onClick={() => navigate(`/coach-profile/${id}/edit`)}
                        backgroundColor={COLORS.primary}
                        className={"min-width-remove mb-3"}
                        title={<Edit />}
                      />
                    </div>
                  )}
                  {(isPlayer || isParent) && !isOwnProfile && (
                    <AppButton
                      width={200}
                      height={35}
                      onClick={() =>
                        handleChange("questionsReviewVisible", true)
                      }
                      className={"mt-3 ml-1"}
                      title={"Add a Review"}
                      color={"#000000D9"}
                      backgroundColor={"#FFFFFF"}
                      borderColor={"#D9D9D9"}
                    />
                  )}
                  {user?.id != id && (
                    <AppButton
                      width={120}
                      height={35}
                      className={"ml-3 mt-2"}
                      loading={loadingChat}
                      color={"#000000D9"}
                      backgroundColor={"#FFFFFF"}
                      borderColor={"#D9D9D9"}
                      onClick={() => _createChannel('')}
                      title={
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: -5 }}
                        >
                          <MessageFill style={{ marginTop: -3 }} width={20} />{" "}
                          <span className="ml-1">Message</span>
                        </div>
                      }
                      fontWeight={"400"}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className="mt-3 mb-2"
              >
                {userData?.coach?.display_tiktok && (
                  <AppButton
                    width={35}
                    height={35}
                    onClick={() =>
                      gotoLink(userData?.coach?.tiktok_username)
                    }
                    borderRadius={15}
                    className={"min-width-remove"}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    title={
                      <Tiktok style={{ marginTop: -3 }} width={20} />
                    }
                  />
                )}
                {userData?.coach?.display_facebook && (
                  <AppButton
                    width={35}
                    height={35}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    onClick={() =>
                      gotoLink(userData?.coach?.facebook_username)
                    }
                    className={"min-width-remove ml-2 mr-2"}
                    borderRadius={15}
                    title={
                      <Facebook style={{ marginTop: -3 }} width={20} />
                    }
                  />
                )}
                {userData?.coach?.display_twitter && (
                  <AppButton
                    width={35}
                    height={35}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    onClick={() =>
                      gotoLink(userData?.coach?.twitter_username)
                    }
                    className={"min-width-remove mr-2"}
                    borderRadius={15}
                    title={
                      <Twitter style={{ marginTop: -3 }} width={20} />
                    }
                  />
                )}
                {userData?.coach?.display_instagram && (
                  <AppButton
                    width={35}
                    height={35}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    onClick={() =>
                      gotoLink(userData?.coach?.instagram_username)
                    }
                    borderRadius={15}
                    className={"min-width-remove mr-2"}
                    title={<Insta style={{ marginTop: -3 }} width={20} />}
                  />
                )}
                {userData?.coach?.display_youtube && (
                  <AppButton
                    width={35}
                    height={35}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    onClick={() =>
                      gotoLink(userData?.coach?.youtube_username)
                    }
                    borderRadius={15}
                    className={"min-width-remove mr-2"}
                    title={
                      <Youtube style={{ marginTop: -3 }} width={20} />
                    }
                  />
                )}
                {userData?.coach?.display_threads && (
                  <AppButton
                    width={35}
                    height={35}
                    backgroundColor={'transparent'}
                    borderColor={"#D9D9D9"}
                    onClick={() =>
                      gotoLink(userData?.coach?.threads_username)
                    }
                    borderRadius={15}
                    className={"min-width-remove"}
                    title={
                      <Threads style={{ marginTop: -3 }} width={20} />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px" }}>
                <p className="taglineOrganization">Bio</p>
                <span className="bioOrganization">
                  <SeeMoreText text={userData?.coach?.bio} maxLength={275} />
                </span>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px" }}>
                <p className="taglineOrganization">Coaching highlights</p>
                <span className="bioOrganization">
                  <SeeMoreText text={userData?.coach?.highlights} maxLength={275} />
                </span>
              </Grid>
            </section>
          </div>
          <section className="container width70 p-0 pl-3 bg-transparent">
            <p className="taglineOrganization1">Reviews ({questionReviews?.[0]?.max_review_count ?? 0})</p>
            <Grid container className="reviewBox">
              {questionReviews?.map((item, index) => (
                <Grid
                  key={index}
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  item
                  xs={6}
                  md={4}
                  lg={1.7}
                  style={{
                    padding: "10px 10px",
                    border:
                      index === questionReviews?.length - 1
                        ? "none"
                        : "1px solid rgba(0, 0, 0, 0.06)"
                  }}
                >
                  {" "}
                  <img
                    src={
                      item[item?.display_icon]
                        ? item[item?.display_icon]
                        : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                    }
                    className="reviewImage"
                  />
                  <div className="font-bold text-black text-center">
                    {item?.name}
                  </div>
                </Grid>
              ))}
            </Grid>
          </section>
          <div className="">
            <section className="container width70 p-0 bg-transparent">
              <Grid item xs={12} className="">
                <div className="font-bold mb-3 text-black">
                  Personal information
                </div>
              </Grid>
              <Grid container className="border p-3">
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">User Name</div>
                  <div className="valueCoach">{userData?.coach?.username}</div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Name</div>
                  <div className="valueCoach">{userData?.name}</div>
                </Grid>
                <Grid item xs={12} md={6} className="p-3">
                  <div className="labelCoach">Team Name</div>
                  <div className="d-flex align-items-center">
                    {userData?.coach?.current_organization?.logo && (
                      <img
                        src={userData?.coach?.current_organization?.logo}
                        className="teamLogo"
                      />
                    )}
                    <div className="valueCoach">
                      {userData?.coach?.current_organization?.organization_name ||
                        userData?.coach?.current_organization_custom}
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={12} md={6} className="p-3">
                  <div className="labelCoach">Sport</div>
                  <div className="d-flex align-items-center">
                    {userData?.coach?.main_sport?.image && (
                      <img
                        src={userData?.coach?.main_sport?.image}
                        className="teamLogo1"
                      />
                    )}
                    <div className="valueCoach">
                      {userData?.coach?.main_sport?.name}
                    </div>
                  </div>
                </Grid> */}
                <Grid item xs={12} className="p-3">
                  <div className="labelCoach">Email</div>
                  <div className="valueCoach">{userData?.email}</div>
                </Grid>
                {/* <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Phone Number</div>
                  <div className="valueCoach">{userData?.phone_number}</div>
                </Grid> */}
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Coaching Experience (yrs)</div>
                  <div className="valueCoach">{userData?.coach?.experience}</div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">I've coached</div>
                  {
                    userData?.coach?.ive_coached?.map((item, index) => (
                      <div key={index} className="valueCoach">{item?.context}</div>
                    ))
                  }
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Preferred coaching level</div>
                  {
                    userData?.coach?.preferred_coaching_level?.map((item, index) => (
                      <div key={index} className="valueCoach">{item?.context}</div>
                    ))
                  }
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Previous organization 1</div>
                  <div className={`${"w-res-value-1 d-flex align-items-center"}`}>
                    {userData?.coach?.previous_organization?.logo && (
                      <Avatar
                        alt="Remy Sharp"
                        src={userData?.coach?.previous_organization?.logo}
                        sx={{ width: 45, height: 45, marginRight: 1 }}
                      />
                    )}
                    <div className="valueCoach">{userData?.coach?.previous_organization?.organization_name || userData?.coach?.previous_organization_custom}</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Previous organization 2</div>
                  <div className={`${"w-res-value-1 d-flex align-items-center"}`}>
                    {userData?.coach?.previous_organization_two?.logo && (
                      <Avatar
                        alt="Remy Sharp"
                        src={userData?.coach?.previous_organization_two?.logo}
                        sx={{ width: 45, height: 45, marginRight: 1 }}
                      />
                    )}
                    <div className="valueCoach">{userData?.coach?.previous_organization_two?.organization_name || userData?.coach?.previous_organization_two_custom}</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Previous organization 3</div>
                  <div className={`${"w-res-value-1 d-flex align-items-center"}`}>
                    {userData?.coach?.previous_organization_three?.logo && (
                      <Avatar
                        alt="Remy Sharp"
                        src={userData?.coach?.previous_organization_three?.logo}
                        sx={{ width: 45, height: 45, marginRight: 1 }}
                      />
                    )}
                    <div className="valueCoach">{userData?.coach?.previous_organization_three?.organization_name || userData?.coach?.previous_organization_three_custom}</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Previous organization 4</div>
                  <div className={`${"w-res-value-1 d-flex align-items-center"}`}>
                    {userData?.coach?.previous_organization_four?.logo && (
                      <Avatar
                        alt="Remy Sharp"
                        src={userData?.coach?.previous_organization_four?.logo}
                        sx={{ width: 45, height: 45, marginRight: 1 }}
                      />
                    )}
                    <div className="valueCoach">{userData?.coach?.previous_organization_four?.organization_name || userData?.coach?.previous_organization_four_custom}</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">I'm good at</div>
                  {
                    userData?.coach?.im_good_at?.map((item, index) => (
                      <div key={index} className="valueCoach">{item?.context}</div>
                    ))
                  }
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Strategic coaching objectives</div>
                  {
                    userData?.coach?.strategic_coaching_objective?.map((item, index) => (
                      <div key={index} className="valueCoach">{item?.context}</div>
                    ))
                  }
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Available Training</div>
                  <div className="valueCoach">{userData?.coach?.training_available ? "Yes" : "No"}</div>
                </Grid>
              </Grid>
              {
                !isOwnProfile &&
                <Grid container justifyContent={"center"}>
                  <AppButton
                    title={
                      isOwnProfile
                        ? "Post Tournament"
                        : "Inquire about training Sessions"
                    }
                    backgroundColor={COLORS.primary}
                    color={COLORS.white}
                    width={isOwnProfile ? 150 : 250}
                    height={40}
                    loading={loadingChat}
                    onClick={() => _createChannel(true)}
                    className={"mt-4 mb-4"}
                  />
                </Grid>
              }
            </section>
          </div>
          <section className="container pb-3 mb-0 bg-transparent">
            <Grid item xs={6} md={2}>
              <div className="text-black font-24 font-500">Activities</div>
              <Divider className="mt-4" />
              {posts?.map((post, index) => (
                <Post
                  key={index}
                  state={state}
                  showComment={showComment}
                  handleChange={handleChange}
                  post={post}
                  getData={getData}
                  videoPlaying={videoPlaying}
                  allUsers={allUsers}
                  getUserLoader={getUserLoader}
                  allChannels={allChannels}
                  index={index}
                />
              ))}
            </Grid>
          </section>
        </>
      )}
      <QRCode
        type={"coach"}
        visible={showQRCode}
        handleClose={handleCloseQRCode}
      />
      <AddCoachQuestionsReview
        visible={questionsReviewVisible}
        questionReviews={questionReviewsModal}
        getData={getData}
        userData={userData}
        handleClose={() => handleChange("questionsReviewVisible", false)}
      />
    </div>
  )
}
