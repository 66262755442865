import React, { useContext, useEffect, useState } from 'react'
import { AppButton } from '..'
import {
    Box, Grid, Modal
} from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import AppContext from '../../Context';
import { saveAs } from 'file-saver';

function QRCode({ visible, handleClose, type }) {
    const { userProfile } = useContext(AppContext)
    const qrcode = type === "coach" ? userProfile?.coach?.qr_code || '' : userProfile?.player?.qr_code || ""

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: 6,
        height: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
    })
    const {
    } = state

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    useEffect(() => {
    }, [])

    const handleDownload = async () => {
        try {
            handleChange("loading", true)
            saveAs(qrcode, "image.jpg");
            handleChange("loading", false)
        } catch (error) {
            handleChange("loading", false)
        }
    }

    return (
        <Modal
            open={visible}
            onClose={() => handleClose(false)}
            sx={{ zIndex: 9999999 }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-20 font-bold greyColor'>{"QR Code"}</div>
                    <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                </Grid>
                <Grid container direction="column" alignItems={'center'} className='followerListDiv'>
                    <img src={qrcode} width={300} height={270} className='mb-4' />
                    <AppButton backgroundColor={COLORS.primary} color={COLORS.white} width={250} height={40} title={"Download"} fontWeight={'400'} onClick={handleDownload} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default QRCode