import React, { useContext, useEffect, useState } from 'react'
import { AppButton, AppInput } from '..'
import {
    Box, Grid, Modal, Checkbox, CircularProgress
} from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import { addMemberChannel, createChannel, searchUserProfile, sendFeedback, updateChannel } from '../../api/auth';
import { useSnackbar } from 'notistack';
import { getError } from '../../utils/getError';
import AppContext from '../../Context';
import { useNavigate, useParams } from 'react-router-dom';
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"

function MessageModal({ visible, type, handleClose, _getChannels, currentChannelData, _getChannelMessages }) {
    const { enqueueSnackbar } = useSnackbar()

    const { id } = useParams()
    const navigate = useNavigate()
    const { user } = useContext(AppContext)
    const token = localStorage.getItem("token")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        borderRadius: 6,
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        group_name: '',
        loading: false,
        loadingChat: false,
        list: [],
        filteredList: [],
        users: [],
        members: [],
        loadingUsers: false
    })
    const {
        group_name,
        filteredList,
        list,
        loadingChat,
        users,
        members,
        loadingUsers
    } = state

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    useEffect(() => {
        _getProfile('')
    }, [])

    useEffect(() => {
        if (type === "changeName") {
            handleChange('group_name', currentChannelData?.group_name)
        }
    }, [type, currentChannelData])



    const _getProfile = async searchText => {
        try {
            handleChange("loadingUsers", true)
            handleChange("filteredList", [])
            handleChange("searchText", searchText)
            const token = localStorage.getItem("token")
            const res = await searchUserProfile(`?search=${searchText}`, token)
            handleChange("list", res?.data?.results)
            handleChange("loadingUsers", false)
            handleChange("filteredList", res?.data?.results)
        } catch (error) {
            handleChange("loadingUsers", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const handleUserClick = (user) => {
        handleClose()
        const role = user?.role
        const route = role === 'Player' ? `/player-profile/${user?.id}` : role === 'Organization' ? `/organization-profile/${user?.id}` : `/parent-profile/${user?.id}`
        navigate(route)
    }

    const _createChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                users: [...users, user?.id],
                group_name
            }
            const res = await createChannel(payload)
            console.log("createChannel", res?.data)
            handleChange("loadingChat", false)
            _getChannels()
            handleClose(false)
            navigate(`/messages/${res?.data?.id}`)
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }
    const _updateChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                group_name
            }
            const res = await updateChannel(id, payload)
            handleChange("loadingChat", false)
            _getChannels()
            _getChannelMessages()
            handleClose(false)
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const _addMemberChannel = async () => {
        try {
            handleChange("loadingChat", true)
            const payload = {
                channel: id,
                users: members
            }
            const res = await addMemberChannel(payload)
            handleChange("loadingChat", false)
            _getChannels()
            _getChannelMessages()
            handleClose(false)
            handleChange("members", [])
            enqueueSnackbar(members?.length > 1 ? "Members has been added" : "Member has been added", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        } catch (error) {
            handleChange("loadingChat", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const removeExistingUsers = (users) => {
        return users.filter(function (objFromA) {
            return !currentChannelData?.users?.find(function (objFromB) {
                return objFromA.id === objFromB.id
            })
        })
    }

    const printName = (user) => {
        const isPlayer = user?.role === "Player"
        const isParent = user?.role === "Parent"
        const isCoach = user?.role === "Coach"
        const isOrganization = user?.role === "Organization"
        const ProfileDP = isPlayer
            ? user?.player?.picture
            : isParent
                ? user?.parent?.picture
                : isOrganization
                    ? user?.organization?.logo
                    : isCoach
                        ? user?.coach?.picture
                        : user?.player?.picture || AvatarIcon
        const ProfileName = isOrganization
            ? user?.organization?.organization_name
            : user?.name || "Guest"

        return { ProfileName, ProfileDP }
    }

    const currentUser = id == user?.id
    const currentUserID = user?.id

    if (type === "addMember") {
        return (
            <Modal
                open={visible}
                onClose={() => handleClose(false)}
                sx={{ zIndex: 9999999 }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: { xs: "90%", md: 500 } }}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                        <div className=' font-20 font-bold greyColor'>{"Add Member"}</div>
                        <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                    </Grid>
                    <div className="searchInputHeaderBox mb-3" style={{ width: '100%' }}>
                        <input className="searchInput" placeholder="Search..." onChange={(e) => _getProfile(e.target.value)} />
                        <div className="saerchIconHeaderDiv">
                            <SearchGrey />
                        </div>
                    </div>
                    {
                        loadingUsers && <div className='divCenter'><CircularProgress size={14} /></div>
                    }
                    <Grid style={{ maxHeight: '70%', overflow: "auto" }}>
                        {
                            Array.isArray(filteredList) && removeExistingUsers(filteredList)?.map((user, index) => (
                                <Grid key={index} className="followerListGrid" container alignItems={'center'} justifyContent={"space-between"}>
                                    <Grid container alignItems={"center"} item xs={8} onClick={() => handleUserClick(user)}>
                                        <img src={printName(user)?.ProfileDP} className="followerProfile" />
                                        <div>{printName(user)?.ProfileName}</div>
                                    </Grid>
                                    {
                                        currentUserID !== user?.id &&
                                        <Checkbox
                                            disabled={false}
                                            checked={members?.find(id => id === user?.id)}
                                            onClick={() => {
                                                if (members?.find(id => id === user?.id)) {
                                                    const removed = members?.filter(id => id !== user?.id)
                                                    handleChange("members", removed)
                                                } else {
                                                    handleChange("members", [...members, user?.id])
                                                }
                                            }}
                                            // className="checkbox1"
                                            style={{
                                                marginTop: -3
                                            }}
                                            sx={{
                                                color: "rgba(201, 208, 216, 1)",
                                                "&.Mui-checked": {
                                                    color: COLORS.primary
                                                }
                                            }}
                                        />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container justifyContent={'flex-end'}>
                        <AppButton
                            loading={loadingChat}
                            onClick={_addMemberChannel}
                            disabled={members?.length === 0}
                            title={'Add Member'}
                            backgroundColor={COLORS.primary} color={COLORS.white}
                            width={130} fontWeight={300} className={'mt-4'} height={35} borderRadius={15} />
                    </Grid>
                </Box>
            </Modal>
        )
    }

    if (type === "changeName") {
        return (
            <Modal
                open={visible}
                onClose={() => handleClose(false)}
                sx={{ zIndex: 9999999 }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: { xs: "90%", md: 500 }, height: { xs: 300, md: 250 } }}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                        <Grid sx={{ width: '80%' }} className='font-20 font-bold greyColor'>{"Changing the name of a group chat changes it for everyone."}</Grid>
                        <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                    </Grid>
                    <AppInput placeholder={'Group Name'} height={40} className={'mb-4'} value={group_name} name={'group_name'} onChange={handleChange} />
                    <Grid container justifyContent={'flex-end'}>
                        <AppButton
                            loading={loadingChat}
                            onClick={_updateChannel}
                            disabled={!group_name}
                            title={'Save'}
                            backgroundColor={COLORS.primary} color={COLORS.white}
                            fontWeight={300} className={'mt-2'} height={35} borderRadius={15} />
                    </Grid>
                </Box>
            </Modal>
        )
    }
    return (
        <Modal
            open={visible}
            onClose={() => handleClose(false)}
            sx={{ zIndex: 9999999 }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style, width: { xs: "90%", md: 500 } }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-20 font-bold greyColor'>{"New Group"}</div>
                    <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                </Grid>
                <AppInput placeholder={'Group Name'} height={40} className={'mb-4'} label={'Group Name'} value={group_name} name={'group_name'} onChange={handleChange} />
                <div className='font-16 mb-2'>Add Members</div>
                <div className="searchInputHeaderBox mb-3" style={{ width: '100%' }}>
                    <input className="searchInput" placeholder="Search..." onChange={(e) => _getProfile(e.target.value)} />
                    <div className="saerchIconHeaderDiv">
                        <SearchGrey />
                    </div>
                </div>
                <Grid className='messageUserListDiv' sx={{ maxHeight: { xs: "65%", md: "49%" } }}>
                    {
                        Array.isArray(filteredList) && filteredList?.map((user, index) => (
                            <Grid key={index} className="followerListGrid" container alignItems={'center'} justifyContent={"space-between"}>
                                <Grid container alignItems={"center"} item xs={8} onClick={() => handleUserClick(user)}>
                                    <img src={printName(user)?.ProfileDP} className="followerProfile" />
                                    <div>{printName(user)?.ProfileName}</div>
                                </Grid>
                                {
                                    currentUserID !== user?.id?.toString() &&
                                    <Checkbox
                                        disabled={false}
                                        checked={users?.some(id => id?.toString() === user?.id?.toString())}
                                        onClick={() => {
                                            if (users?.find(id => id?.toString() === user?.id?.toString())) {
                                                const removed = users?.filter(id => id?.toString() !== user?.id?.toString())
                                                handleChange("users", removed)
                                            } else {
                                                handleChange("users", [...users, user?.id?.toString()])
                                            }
                                        }}
                                        // className="checkbox1"
                                        style={{
                                            marginTop: -3
                                        }}
                                        sx={{
                                            color: "rgba(201, 208, 216, 1)",
                                            "&.Mui-checked": {
                                                color: COLORS.primary
                                            }
                                        }}
                                    />
                                }
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid container justifyContent={'flex-end'} className='mt-2'>
                    <AppButton
                        loading={loadingChat}
                        onClick={_createChannel}
                        disabled={!group_name || users?.length < 1}
                        title={'Create Group'}
                        backgroundColor={COLORS.primary} color={COLORS.white}
                        width={120} fontWeight={300} className={'mt-2'} height={35} borderRadius={15} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default MessageModal