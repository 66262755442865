import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput } from ".."
import { Box, Grid, Modal, Popover, Typography } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  endPlayerSeason,
  endTeamSeason
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"

function EndSeason({ organization, visible, handleClose, teamID, type, id }) {
  const { enqueueSnackbar } = useSnackbar()
  const { organizationData } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    end_date: "",
    loading: false
  })
  const { end_date, loading } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        end_date
      }
      const token = localStorage.getItem("token")
      console.log(payload, token, "TOMEK")
      if (type == "Player") {
        await endPlayerSeason(payload, token)
      } else {
        await endTeamSeason(payload, token)
      }

      handleChange("loading", false)
      handleChange("end_date", "")
      handleClose(true)

      enqueueSnackbar(`Successfully ended the season`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } finally {
    }
  }

  const open = Boolean(anchorEl)
  const ident = open ? "simple-popover" : undefined

  const disabled = !end_date

  return (
    <Modal
      open={visible}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">End Season</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppInput
              max={moment()}
              label={"When did the season end?"}
              type={"date"}
              height={40}
              onChange={handleChange}
              value={end_date}
              name={"end_date"}
            />
          </Grid>
        </Grid>

        <Popover
          id={ident}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to end this season. This cannot be
                  changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default EndSeason
