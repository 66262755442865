import * as React from "react"
import { styled } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Grid from "@mui/material/Grid"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import { useEffect } from "react"
import { Divider } from "@mui/material"
import UsersIcon from "../../assets/svg/admin/supervised_user_circle.svg"
import FeedbackIcon from "../../assets/svg/admin/feedback.svg"
import ReportProblemIcon from "../../assets/svg/admin/report_problem.svg"
import AdminLogo from "../../assets/svg/admin/AdminLogo.svg"
import LogoutIcon from "../../assets/svg/logout.svg"
import AdminHeader from "../Header/AdminHeader"

const drawerWidth = 280

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

function AdminLayoutContent({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, setUser } = useContext(AppContext)
  const [open, setOpen] = React.useState(true)
  useEffect(() => {
    const userData = localStorage.getItem("user")
    setUser(JSON.parse(userData))
  }, [])

  const handleListItemClick = (route, index) => {
    navigate(route)
  }
  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        className="drawerClass"
        classes={{ paper: "drawerClass" }}
        style={{
          height: "100vh"
        }}
        open={open}
      >
        <div>
          <img src={AdminLogo} className={"AdminLogo"} />
          {open && (
            <List component="nav">
              <ListItemButton
                selected={location.pathname?.includes("/webadmin/users")}
                onClick={() => handleListItemClick("/webadmin/users", 0)}
                className={
                  location.pathname?.includes("/webadmin/users")
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <img src={UsersIcon} className="iconDashboard" />
                <ListItemText primary="User Management" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname?.includes("/webadmin/feedbacks")}
                onClick={() => handleListItemClick("/webadmin/feedbacks", 0)}
                className={`mt-2  
                                ${
                                  location.pathname?.includes(
                                    "/webadmin/feedbacks"
                                  )
                                    ? "listButtonActive"
                                    : "listButton"
                                }
                                `}
              >
                <img src={FeedbackIcon} className="iconDashboard" />
                <ListItemText primary="Feedbacks" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname?.includes("/webadmin/reporteduser")}
                onClick={() => handleListItemClick("/webadmin/reporteduser", 0)}
                className={`mt-2  
                                ${
                                  location.pathname?.includes(
                                    "/webadmin/reporteduser"
                                  )
                                    ? "listButtonActive"
                                    : "listButton"
                                }
                                `}
              >
                <img src={ReportProblemIcon} className="iconDashboard" />
                <ListItemText primary="Reported Users" />
              </ListItemButton>
            </List>
          )}
        </div>

        {/* <Grid container className="mb-4 ml-3 c-pointer">
                    <img src={LogoutIcon} className="iconDashboard" />
                    <p onClick={logout} className="text_white mt-3">
                        Log Out
                    </p>
                </Grid> */}
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto"
        }}
      >
        <AdminHeader />
        {children}
      </Box>
    </Box>
  )
}

export default function AdminLayout({ children }) {
  return <AdminLayoutContent children={children} />
}
