export const OUTDOORSY_DATE_FORMAT = 'YYYY-MM-DD'

export const RECOMMENDATIONS_PAGE_SIZE = 25
export const MAP_API_KEY = "AIzaSyDAbf2CrUZuDR4csVH-7VlSwrZ-0zwZWkc"


export const slickSettings = {
  dots: false,
  prevArrow: <button type="button" className="slick-prev" />,
  nextArrow: <button type="button" className="slick-next" />,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  centerMode: true,
  centerPadding: '0px',
  adaptiveHeight: true,
};

export const COLORS = {
  primary: "#0059C1",
  white: "white",
  orange: "#FF4D4F"
}

// "https://static.skillshare.com/cdn-cgi/image/quality=85,width=1242,height=839,format=auto/uploads/project/16774/cover_1242_cd1cf85ff41eee086567e6925eff71a6.jpg"

