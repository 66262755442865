import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput } from ".."
import { Box, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { useSnackbar } from "notistack"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import ImageIcon from "@mui/icons-material/Image"
import WorkIcon from "@mui/icons-material/Work"
import BeachAccessIcon from "@mui/icons-material/BeachAccess"
import avatar from "../../assets/images/avatar.png"
export default function UserLikeModal({ visible, handleClose, likes }) {
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  return (
    <Modal
      open={visible}
      onClose={() => handleClose(false)}
      sx={{ zIndex: 9999999 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-bold greyColor">All User likes</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {likes?.map((item, index) => {
            return (
              <ListItem
                style={{
                  padding: "10px",
                  backgroundColor: "#FAFAFA",
                  borderRadius: "16px",
                  marginBottom: "10px",
                  width: "100%"
                }}
              >
                <ListItemAvatar key={index}>
                  {item?.user?.profile_picture !== null ? (
                    <Avatar
                      alt={item?.user?.name}
                      src={item?.user?.profile_picture}
                    />
                  ) : (
                    <Avatar src={avatar} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={item?.user?.name}
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Modal>
  )
}
