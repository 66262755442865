import React, { useContext, useEffect, useState } from 'react'
import { AppButton, AppInput } from '..'
import {
    Box, Grid, Modal
} from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import { getOrganizationTeam, sendFeedback } from '../../api/auth';
import { useSnackbar } from 'notistack';
import { getError } from '../../utils/getError';
import { validateEmail } from '../../utils/ValidateEmail';

function Feedback({ visible, handleClose }) {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem("token")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        borderRadius: 6,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        message: '',
        email: '',
        loading: false,

    })
    const {
        email,
        message,
        loading,
        isEmailValid
    } = state

    const handleChange = (key, value) => {
        if (key === 'email') {
            _isEmailValid(value)
        }
        setState(pre => ({ ...pre, [key]: value }))
    }

    useEffect(() => {
        getData()
    }, [])

    const _isEmailValid = (email) => {
        if (email) {
            const isValid = validateEmail(email)
            if (!isValid) {
                handleChange("isEmailValid", true)
            } else {
                handleChange("isEmailValid", false)
            }
        }
    }

    const getData = async () => {
        try {
            handleChange("loading", true)
            const res = await getOrganizationTeam(``, token)
            handleChange("teams", res?.data?.results)
            handleChange("loading", false)
        } catch (error) {
            handleChange("loading", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const handleSubmit = async () => {
        try {
            handleChange("loading", true)
            const payload = {
                email,
                message,
            }
            await sendFeedback(payload, token)
            handleChange("loading", false)
            handleChange("message", '')
            handleClose(true)
            enqueueSnackbar(`Feedback has been submitted`, {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        } catch (error) {
            handleChange("loading", false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const handleCloseModal = () => {
        handleChange("isEmailValid", false)
        handleChange("email", '')
        handleChange("message", '')
        handleClose(false)
    }

    const disabled = !email || !message || isEmailValid
    return (
        <Modal
            open={visible}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-24 font-bold greyColor'>Support/Send Feedback</div>
                    <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross />} onClick={() => handleClose(false)} />
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AppInput label={'Email'} errorText={isEmailValid ? "Email is invalid" : ""} placeholder={'Email'} height={40} onChange={handleChange} onBlur={() => _isEmailValid(email)} value={email} name={'email'} />
                    </Grid>
                    <Grid item xs={12}>
                        <AppInput label={'Message'} resize={"vertical"} placeholder={'Write your message'} multiline height={'100%'} minHeightMulti={100} onChange={handleChange} value={message} name={'message'} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-end"} className='mt-4'>
                    <AppButton title={'Cancel'} height={40} onClick={handleCloseModal} width={'auto'} backgroundColor={"#595959"} className={'mr-2'} color={'#fff'} />
                    <AppButton title={'Send'} backgroundColor={COLORS.primary} color={COLORS.white} width={'auto'} height={40} loading={loading} disabled={disabled} onClick={handleSubmit} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default Feedback