import React from "react"
import { AppButton } from ".."
import { Box, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"


function DeleteCalendarEventModal({ visible, loadingDelete, handleClose, handleCloseModal, handleDeleteEvent }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }

  return (
    <Modal
      open={visible}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-medium greyColor">Event</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross style={{ marginTop: -5 }} />}
            onClick={() => handleCloseModal(false)}
          />
        </Grid>
        <p>Are you sure want to delete this event?</p>

        <Grid container spacing={2} justifyContent={"flex-end"} sx={{ mt: 1 }}>
          <Grid item>
            <AppButton
              title={"Cancel"}
              color={COLORS.white}
              fontWeight={400}
              backgroundColor={"#595959"}
              height={45}
              width={"80px"}
              onClick={handleCloseModal}
            />
          </Grid>
          <Grid item>
            <AppButton
              title={"Delete"}
              backgroundColor={"#FF4D4F"}
              color={COLORS.white}
              fontWeight={400}
              height={45}
              width={"100px"}
              loading={loadingDelete}
              onClick={handleDeleteEvent}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default DeleteCalendarEventModal
