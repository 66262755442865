import * as React from "react"
import { styled } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import IconButton from "@mui/material/IconButton"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import FavoriteIcon from "@mui/icons-material/Favorite"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined"
import CommuteOutlinedIcon from "@mui/icons-material/CommuteOutlined"
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined"
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined"
import EventNoteIcon from '@mui/icons-material/EventNote';
const drawerWidth = 280

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))

function LayoutContent({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    user
  } = useContext(AppContext)
  const [open, setOpen] = React.useState(true)
  const [open1, setOpen1] = React.useState(false)
  const [state, setState] = React.useState({
    openDrawer: false
  })
  const { openDrawer } = state
  const handleListItemClick = (route, index) => {
    navigate(route)
  }
  const handleListItemClickInbox = () => {
    setOpen1(!open1)
  }
  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate("/")
  }

  const toggleDrawer = open => {
    setState({ ...state, openDrawer: open })
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant={"permanent"}
        className="displayMobileHide drawerClass"
        classes={{ paper: "drawerClass" }}
        style={{
          height: "100vh"
        }}
        open={open}
      >
        <div>
          {open && (
            <List component="nav">
              <ListItemButton
                selected={location.pathname === "/dashboard/profile"}
                onClick={() => handleListItemClick("/dashboard/profile", 4)}
                className={
                  location.pathname === "/dashboard/profile"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <PersonOutlineIcon className="iconDashboard1" />
                <ListItemText primary="Profile" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/inbox"}
                onClick={() => handleListItemClick("/dashboard/inbox")}
                className={
                  location.pathname === "/dashboard/inbox"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <DraftsOutlinedIcon className="iconDashboard1" />
                <ListItemText className="listItemInbox" primary="Inbox" />
                {user?.unread_message && <div className="unreadbox" />}
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/reservation"}
                onClick={() => handleListItemClick("/dashboard/reservation", 1)}
                className={
                  location.pathname === "/dashboard/reservation"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <EventNoteIcon className="iconDashboard1" />
                <ListItemText primary="Reservations" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/bookings"}
                onClick={() => handleListItemClick("/dashboard/bookings", 1)}
                className={
                  location.pathname === "/dashboard/bookings"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <EventAvailableIcon className="iconDashboard1" />
                <ListItemText primary="Bookings" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/calendar"}
                onClick={() => handleListItemClick("/dashboard/calendar", 2)}
                className={
                  location.pathname === "/dashboard/calendar"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <CalendarMonthIcon className="iconDashboard1" />
                <ListItemText primary="Calendar" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/listings"}
                onClick={() => handleListItemClick("/dashboard/listings", 2)}
                className={
                  location.pathname === "/dashboard/listings"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <FormatListBulletedOutlinedIcon className="iconDashboard1" />
                <ListItemText primary="Listings" />
              </ListItemButton>

              <ListItemButton
                selected={location.pathname === "/dashboard/trips"}
                onClick={() => handleListItemClick("/dashboard/trips", 2)}
                className={
                  location.pathname === "/dashboard/trips"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <CommuteOutlinedIcon className="iconDashboard1" />
                <ListItemText primary="Trips" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/favorites"}
                onClick={() => handleListItemClick("/dashboard/favorites", 2)}
                className={
                  location.pathname === "/dashboard/favorites"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <FavoriteIcon className="iconDashboard1" />
                <ListItemText primary="Favorites" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/matrics"}
                onClick={() => handleListItemClick("/dashboard/matrics", 3)}
                className={
                  location.pathname === "/dashboard/matrics"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <BarChartOutlinedIcon className="iconDashboard1" />
                <ListItemText primary="Metrics" />
              </ListItemButton>
              <ListItemButton
                selected={location.pathname === "/dashboard/wallet"}
                onClick={() => handleListItemClick("/dashboard/wallet", 2)}
                className={
                  location.pathname === "/dashboard/wallet"
                    ? "listButtonActive"
                    : "listButton"
                }
              >
                <CreditCardIcon className="iconDashboard1" />
                <ListItemText primary="Wallet" />
              </ListItemButton>
            </List>
          )}
        </div>
      </Drawer>
      <MuiDrawer
        anchor={"left"}
        // className="drawerClass"
        onClose={() => toggleDrawer(false)}
        // classes={{ paper: "drawerClass" }}
        open={openDrawer}
      >
        <Box
          sx={{
            width: 250
          }}
          role="presentation"
        >
          <List component="nav">
            <ListItemButton
              selected={location.pathname === "/dashboard/inbox"}
              onClick={() => handleListItemClick("/dashboard/inbox")}
              className={
                location.pathname === "/dashboard/inbox"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <DraftsOutlinedIcon className="iconDashboard1" />
              <ListItemText className="listItemInbox" primary="Inbox" />
              {user?.unread_message && <div className="unreadbox" />}
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/reservation"}
              onClick={() => handleListItemClick("/dashboard/reservation", 1)}
              className={
                location.pathname === "/dashboard/reservation"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <EventNoteIcon className="iconDashboard1" />
              <ListItemText primary="Reservations" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/bookings"}
              onClick={() => handleListItemClick("/dashboard/bookings", 1)}
              className={
                location.pathname === "/dashboard/bookings"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <EventAvailableIcon className="iconDashboard1" />
              <ListItemText primary="Bookings" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/calendar"}
              onClick={() => handleListItemClick("/dashboard/calendar", 2)}
              className={
                location.pathname === "/dashboard/calendar"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <CalendarMonthIcon className="iconDashboard1" />
              <ListItemText primary="Calendar" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/listings"}
              onClick={() => handleListItemClick("/dashboard/listings", 2)}
              className={
                location.pathname === "/dashboard/listings"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <FormatListBulletedOutlinedIcon className="iconDashboard1" />
              <ListItemText primary="Listings" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/trips"}
              onClick={() => handleListItemClick("/dashboard/trips", 2)}
              className={
                location.pathname === "/dashboard/trips"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <CommuteOutlinedIcon className="iconDashboard1" />
              <ListItemText primary="Trips" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/favorites"}
              onClick={() => handleListItemClick("/dashboard/favorites", 2)}
              className={
                location.pathname === "/dashboard/favorites"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <FavoriteIcon className="iconDashboard1" />
              <ListItemText primary="Favorites" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/matrics"}
              onClick={() => handleListItemClick("/dashboard/matrics", 3)}
              className={
                location.pathname === "/dashboard/matrics"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <BarChartOutlinedIcon className="iconDashboard1" />
              <ListItemText primary="Metrics" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/dashboard/wallet"}
              onClick={() => handleListItemClick("/dashboard/wallet", 2)}
              className={
                location.pathname === "/dashboard/wallet"
                  ? "listButtonActive"
                  : "listButton"
              }
            >
              <CreditCardIcon className="iconDashboard1" />
              <ListItemText primary="Wallet" />
            </ListItemButton>
          </List>
        </Box>
      </MuiDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          p: 5,
          mt: 6,
          overflow: "auto"
        }}
      >
        <div className="hideWeb">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleDrawer(true)}
            edge="start"
            sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        {children}
      </Box>
    </Box>
  )
}

export default function Layout({ children }) {
  return <LayoutContent children={children} />
}
