export const checkSportIsBasketball = (sports, id) => {
    return sports?.some(e => e?.id?.toString() === id?.toString() && e?.name?.toLowerCase() === "basketball")
}

export const gotoLink = link => {
    let url = link
    if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url
    }
    window.open(url, "_blank")
}