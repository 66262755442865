import React, { Component } from 'react'
import './index.css'

class Loader extends Component {
  render() {
    const { width, height, isWhite, margin } = this.props
    return (
      <div style={{ width: width || 80, height: height || 80, margin: margin ? margin : '100px auto' }} class={isWhite ? 'sk-cube-grid-white' : 'sk-cube-grid'}>
        <div class='sk-cube sk-cube1'></div>
        <div class='sk-cube sk-cube2'></div>
        <div class='sk-cube sk-cube3'></div>
        <div class='sk-cube sk-cube4'></div>
        <div class='sk-cube sk-cube5'></div>
        <div class='sk-cube sk-cube6'></div>
        <div class='sk-cube sk-cube7'></div>
        <div class='sk-cube sk-cube8'></div>
        <div class='sk-cube sk-cube9'></div>
      </div>
    )
  }
}

export default Loader
