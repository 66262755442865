const BASE_URL = process.env.REACT_APP_BASE_URL
const STAGING_BASE_URL = process.env.REACT_APP_STAGING_BASE_URL

export const defaultConfig = {
  // API_URL: BASE_URL || "https://square-hall-42760.botics.co" // Production URL
  API_URL:STAGING_BASE_URL || "https://amateur-sports-insi-42-staging.botics.co" // Staging URL
}

export const App = {
  config: defaultConfig
}

window.env = window.env || defaultConfig
App.config = { ...window.env }

export const API_URL = () => App.config.API_URL
