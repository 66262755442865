// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { ReactComponent as BackButton } from '../../assets/svg/Back.svg'
import { ReactComponent as SearchButton } from '../../assets/svg/Search.svg'
import { ReactComponent as PinMaplist } from '../../assets/svg/pinMaplist.svg'
import { ReactComponent as Direction } from '../../assets/svg/Direction.svg'
import { ReactComponent as MarkerIcon } from '../../assets/svg/Marker.svg'
import GoogleMapReact from "google-map-react"
import { Marker, useJsApiLoader } from "@react-google-maps/api"
import AppInput from "../AppInput"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import { reviewsCatScore, searchAPI } from "../../api/auth"
import AppButton from "../AppButton"
import { COLORS } from "../../constants"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useNavigate, useSearchParams } from "react-router-dom"
import AppContext from "../../Context"

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutoutPercentage: 50,
  cutout: 40
}

export default function GoogleMapComp({ handleChangeMain, isMapShow }) {
  const token = localStorage.getItem("token")
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { user } = useContext(AppContext)
  const [state, setState] = useState({
    questionReviews: [],
    center: {
      lat: -3.745,
      lng: -38.523
    },
    searchText: '',
    list: [],
    loading: false,
    selectedList: null
  })

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA8qkmVxCJuE2_LSU14ogM1vjnoEsRi_Iw"
  })

  console.log('user', user);

  const {
    center,
    loading,
    list,
    searchText,
    questionReviews,
    selectedList
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  useEffect(() => {
    if (user) {
      handleChange("center", {
        lat: Number(user?.latitude),
        lng: Number(user?.longitude),
      })
    }
  }, [user])

  console.log('user', user);

  const _searchAPI = async (value) => {
    try {
      handleChange("searchText", value)
      if (value) {
        handleChange("loading", true)
        const payload = {
          search: value
        }
        const res = await searchAPI(payload, token)
        handleChange('list', [...res?.data?.organizations, ...res?.data?.teams])
        handleChange("loading", false)
      } else {
        handleChange("list", [])
        handleChange("loading", false)
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })

    }
  }

  const handleBack = () => {
    handleChange("center", {
      lat: user?.latitude,
      lng: user?.longitude,
    })
    if (selectedList) {
      handleChange("selectedList", null)
      handleChange("questionReviews", [])
    } else if (list?.length > 0 && !selectedList) {
      handleChange("searchText", '')
      handleChange("list", [])
    } else if (isMapShow) {
      navigate(-1)
    } else {
      handleChangeMain("isMap", false)
    }
  }

  const _reviewsCatScore = async id => {
    try {
      handleChange("loading", true)
      const resReview = await reviewsCatScore(`?organization_id=${id}`, token)
      handleChange("questionReviews", resReview?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const showInMapClicked = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };

  const AnyReactComponent = ({ }) => (
    <div className="infoBox">
      <MarkerIcon height={50} width={50} style={{ width: 50, height: 50 }} />
    </div>
  )

  const handleUserClick = user => {
    console.log('user',user);
    const role = user?.role
    const route = `/organization-profile/${user?.user?.id}`
    navigate(route)
  }

  return (
    <div style={{ height: '100vh', width: '100%' }} className="mb-4">
      <Grid item xs={12} sm={12} md={6} style={{ width: '100%', height: '100%' }}>
        {/* {isLoaded ? ( */}
        <>
          <div className={'showList'} style={{
            background: selectedList || list?.length > 0 ? '#fff' : 'transparent',
            boxShadow: selectedList || list?.length > 0 ? "0px 19px 19px -3px rgba(0, 0, 0, 0.1)" : "none"
          }}>
            <div className="searchInputBox">
              <BackButton className="ml-2 cursor" onClick={handleBack} />
              <input className="searchInput" placeholder="Search..." value={searchText} onChange={(e) => _searchAPI(e.target.value)} />
              <div className="saerchIconDiv">
                <SearchButton />
              </div>
            </div>
            {
              !selectedList && list?.map((item, index) => (
                <div key={index} className="searchlistDiv" onClick={() => {
                  handleChange('selectedList', item)
                  _reviewsCatScore(item?.id)
                  handleChange('center', {
                    lat: item?.organization_name ? parseFloat(item?.user?.latitude) : parseFloat(item?.latitude),
                    lng: item?.organization_name ? parseFloat(item?.user?.longitude) : parseFloat(item?.longitude)
                  })

                }}>
                  <PinMaplist className="mr-2" />
                  <div className="nameLocation">
                    <div className="nameText">
                      {item?.organization_name || item?.team_name}
                    </div>
                    <div className="locationText">
                      {item?.user?.street_address || item?.practice_location}
                    </div>
                  </div>
                </div>
              ))
            }
            {
              selectedList &&
              <div className="selectedList">
                <div className="nameLocation cursor" onClick={() => {
                  selectedList?.organization_name ? handleUserClick(selectedList) : console.log()
                }}>
                  <div className="nameText">
                    {selectedList?.organization_name || selectedList?.team_name}
                  </div>
                  <div className="locationText text-black">
                    {selectedList?.organization_name ? "Organization" : "Team"}
                  </div>
                </div>
                <AppButton onClick={() => showInMapClicked(selectedList?.organization_name ? selectedList?.user?.latitude : selectedList?.latitude, selectedList?.organization_name ? selectedList?.user?.longitude : selectedList?.longitude)} className={'mt-4'} title={<div className="text-white"><Direction /> Direction</div>} backgroundColor={COLORS.primary} width={120} height={40} />
                {
                  selectedList?.organization_name
                    ?
                    <div className="reviewDiv">
                      <div className="reviewsHead">Reviews ({questionReviews?.[0]?.max_review_count ?? 0})</div>
                      <Grid container className="reviewBox" style={{ marginLeft: -7 }}>
                        {questionReviews?.map((item, index) => (
                          <Grid
                            key={index}
                            direction={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            item
                            xs={6}
                            md={4}
                            lg={4}
                            style={{
                              padding: "10px 10px",
                              border:
                                index === questionReviews?.length - 1 || index === 0
                                  ? "none"
                                  : "1px solid rgba(0, 0, 0, 0.06)",
                              textAlign: "center"
                            }}
                          >
                            <img
                              src={
                                item[item?.display_icon]
                                  ? item[item?.display_icon]
                                  : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                              }
                              className="reviewImage"
                            />
                            <p
                              className="font-bold text-black "
                              style={{
                                fontSize: 10,
                                marginLeft: index === 0 ? -5 : 0
                              }}
                            >
                              {item?.name}
                            </p>
                          </Grid>
                        ))}
                      </Grid>
                      {/* <Grid container alignItems={'center'} className="mt-2" justifyContent={'flex-start'}>
                        {
                          selectedList?.hash_tags && Object.entries(selectedList?.hash_tags).map((hash, index1) => (
                            <div key={index1} container justifyContent={'flex-start'}>
                              <div className="hashtag" style={{ marginLeft: 0, marginRight: 10 }}>
                                {hash[0] + " (" + hash[1] + ")"}
                              </div>
                            </div>
                          ))
                        }
                      </Grid> */}
                    </div>
                    :
                    <div className="reviewDiv">
                      <div className="reviewsHead">Stats</div>
                      <Grid container>
                        <Grid xs={12} md={6} lg={4}>
                          <div className="winText">Win Percentage</div>
                          <Doughnut
                            options={options}
                            data={{
                              labels: false,

                              datasets: [
                                {
                                  label: '# of Votes',
                                  data: [selectedList?.win_percent],
                                  backgroundColor: [
                                    '#1890FF',
                                  ],
                                  borderColor: [
                                    '#1890FF',
                                  ],
                                  borderWidth: 1,
                                }
                              ]
                            }}
                            plugins={[{
                              beforeDraw: function (chart) {
                                var width = chart.width,
                                  height = chart.height,
                                  ctx = chart.ctx;
                                ctx.restore();
                                var fontSize = (height / 70).toFixed(2);
                                ctx.font = fontSize + "em sans-serif";
                                ctx.textBaseline = "top";
                                var text = `${selectedList?.win_percent}%`,
                                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                                  textY = height / 2.2;
                                ctx.fillText(text, textX, textY);
                                ctx.save();
                              }
                            }]}
                          />
                        </Grid>
                        <Grid xs={12} md={6} lg={4}>
                          <div className="winText ml-3">Wins</div>
                          <Doughnut
                            options={options}
                            data={{
                              labels: false,
                              datasets: [
                                {
                                  label: '# of Votes',
                                  data: [selectedList?.win],
                                  backgroundColor: [
                                    '#14B46A',
                                  ],
                                  borderColor: [
                                    '#14B46A',
                                  ],
                                  borderWidth: 1,
                                }
                              ]
                            }}
                            plugins={[{
                              beforeDraw: function (chart) {
                                var width = chart.width,
                                  height = chart.height,
                                  ctx = chart.ctx;
                                ctx.restore();
                                var fontSize = (height / 70).toFixed(2);
                                ctx.font = fontSize + "em sans-serif";
                                ctx.textBaseline = "top";
                                var text = `${selectedList?.win}`,
                                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                                  textY = height / 2.2;
                                ctx.fillText(text, textX, textY);
                                ctx.save();
                              }
                            }]}
                          />
                        </Grid>
                        <Grid xs={12} md={6} lg={4}>
                          <div className="winText">Losses</div>
                          <Doughnut
                            options={options}
                            data={{
                              labels: false,
                              datasets: [
                                {
                                  label: '# of Votes',
                                  data: [selectedList?.loss],
                                  backgroundColor: [
                                    '#F5222D',
                                  ],
                                  borderColor: [
                                    '#F5222D',
                                  ],
                                  borderWidth: 1,
                                }
                              ]
                            }}
                            plugins={[{
                              beforeDraw: function (chart) {
                                var width = chart.width,
                                  height = chart.height,
                                  ctx = chart.ctx;
                                ctx.restore();
                                var fontSize = (height / 70).toFixed(2);
                                ctx.font = fontSize + "em sans-serif";
                                ctx.textBaseline = "top";
                                var text = `${selectedList?.loss}`,
                                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                                  textY = height / 2.2;
                                ctx.fillText(text, textX, textY);
                                ctx.save();
                              }
                            }]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                }
              </div>
            }
          </div>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyA8qkmVxCJuE2_LSU14ogM1vjnoEsRi_Iw"
            }}
            defaultCenter={center}
            // yesIWantToUseGoogleMapApiInternals={true}
            center={center}
            defaultZoom={15}
          >
            {
              center?.lat && center?.lng && (
                <AnyReactComponent
                  lat={center?.lat}
                  lng={center?.lng}
                />)
            }
          </GoogleMapReact>
        </>
        {/* ) : (
          <></>
        )} */}
      </Grid>
    </div>
  )
}
