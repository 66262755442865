import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput } from ".."
import { Box, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { getOrganizationTeam, sendFeedback } from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { blockUser, reportedUser } from "../../api/admin"
import AppContext from "../../Context"
import { useNavigate } from "react-router-dom"

function BlockUser({ visible, handleClose, userId }) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [state, setState] = useState({
    message: "",
    email: "",
    loading: false,
    selectedItem: null,
    otherItem: ''
  })
  const { user } = useContext(AppContext)
  console.log(user, "user")
  const { email, message, loading, selectedItem, otherItem } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(``, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        reason: otherItem,
        user: userId
      }
      await blockUser(payload, token)
      handleChange("loading", false)
      handleChange("message", "")
      handleClose(true)
      navigate(-1)
      enqueueSnackbar(`you have been block successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleSelect = item => {
    if (selectedItem === item?.value) {
      handleChange("selectedItem", null)
    } else {
      handleChange("selectedItem", item?.value)
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => handleClose(false)}
      sx={{ zIndex: 9999999 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          // alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-bold greyColor" style={{ width: "80%" }}>
            Are you sure you want to block this user?
          </div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <Grid>
          {/* {list?.map((item, index) => (
            <div
              key={index}
              className={
                selectedItem === item?.value ? "activeReportdiv" : "reportdiv"
              }
              onClick={() => handleSelect(item)}
            >
              {item?.key}
            </div>
          ))} */}
          {/* {selectedItem === "Other" && ( */}
          {/* <AppInput
            placeholder={""}
            height={40}
            label={"Mention reason"}
            value={otherItem}
            onChange={handleChange}
            name={"otherItem"}
          /> */}
          {/* )} */}
        </Grid>
        <Grid container justifyContent={"flex-end"} className="mt-4">
          <AppButton
            title={"Cancel"}
            height={40}
            onClick={handleClose}
            width={"auto"}
            backgroundColor={"#595959"}
            className={"mr-2"}
            color={"#fff"}
          />
          <AppButton
            title={"Block"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={"auto"}
            height={40}
            loading={loading}
            // disabled={disabled}
            onClick={handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default BlockUser
