import { API } from "./"

export const signupUser = payload => {
  return API.post("api/v1/signup/", payload)
}

export const loginUser = payload => {
  return API.post("api/v1/login/", payload)
}

export const generateOTP = (payload, token) => {
  return API.get("users/generate/otp/", payload, token)
}

export const validateOTP = (payload, token) => {
  return API.post("users/validate/otp/", payload, token)
}

export const verifyOTP = (payload, token) => {
  return API.post("users/verify/token/", payload, token)
}

export const verifyEmail = payload => {
  return API.post("api/v1/users/verify/", payload)
}

export const resendOTP = (payload, token) => {
  return API.post("users/resend/token/", payload, token)
}

export const setPassword = (payload, token) => {
  return API.post("api/v1/users/change_password/", payload, token)
}

export const changePasswordv2 = (payload, token) => {
  return API.post("users/password/change/", payload, token)
}

export const setForgotPassword = payload => {
  return API.post("users/forgot/password/verify/", payload)
}

export const updateProfile = (payload, id, token) => {
  return API.patch(`users/profile/${id}/`, payload, token)
}

export const editProfile = (payload, token) => {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    }
  }
  return API.patch(`api/v1/client/edit/`, payload, token)
  // return axios.patch(`${API_URL()}api/v1/client/edit/`, payload, options)
}

export const deleteAccount = (user_id, token) => {
  return API.delete(`api/v1/users/${user_id}/`, {}, token)
}

export const getNotifications = token => {
  return API.get(`api/v1/notifications/`, token)
}

export const markAllReadNotifications = token => {
  return API.post(`api/v1/notifications/mark_all_as_read/`, {}, token)
}

export const forgotpasswordCode = payload => {
  return API.post("api/v1/forgotpasswordcode", payload)
}

export const forgotpassword = payload => {
  return API.post("users/forgot/password/", payload)
}

export const addAddress = (payload, token) => {
  return API.post("api/v1/address/", payload, token)
}

export const changePassword = (payload, token) => {
  return API.post("api/v1/users/password/", payload, token)
}

export const removeAddress = (id, token) => {
  return API.delete(`api/v1/address/${id}/`, {}, token)
}

export const updateAddress = (id, payload, token) => {
  return API.patch(`api/v1/address/${id}/`, payload, token)
}

export const getProfile = (id, token) => {
  return API.get(`users/profile/${id}/`, token)
}

export const getUserProfile = (id, token) => {
  return API.get(`api/v1/users/${id}/`, token)
}

export const getUserProfileFollowing = (payload, token) => {
  return API.get(`api/v1/users/following/${payload}`, token)
}

export const getUserProfileFollower = (payload, token) => {
  return API.get(`api/v1/users/followers/${payload}`, token)
}

export const searchUserProfile = (payload, token) => {
  return API.get(`api/v1/users/${payload}`, token)
}

export const acceptLink = (payload, token) => {
  return API.post(`api/v1/users/accept_link/`, payload, token)
}
export const declineLink = (payload, token) => {
  return API.post(`api/v1/users/decline_link/`, payload, token)
}

export const updateUserProfile = (id, payload, token) => {
  return API.patch(`api/v1/users/${id}/`, payload, token)
}

export const getOrganizationProfile = token => {
  return API.get(`api/v1/organization-profile/`, token)
}

export const createOrganizationProfile = (payload, token) => {
  return API.post(`api/v1/organization-profile/`, payload, token)
}

export const updateOrganizationProfile = (id, payload, token) => {
  return API.put(`api/v1/organization-profile/${id}/`, payload, token)
}

export const getOrganizationTeamById = (id, token) => {
  return API.get(`api/v1/teams/${id}/`, token)
}

export const getOrganizationTeam = (payload, token) => {
  return API.get(`api/v1/teams/${payload}`, token)
}

export const getTeams = token => {
  return API.get(`api/v1/team-dropdown/`, token)
}

export const getIveCoached = token => {
  return API.get(`api/v1/ive-coached/`, token)
}

export const getCircuitParticipation = token => {
  return API.get(`api/v1/circuit-participation/`, token)
}

export const getCircuitAffiliation = token => {
  return API.get(`api/v1/circuit-affiliation/`, token)
}

export const getPreferredCoachLevel = token => {
  return API.get(`api/v1/preferred-coach-level/`, token)
}

export const getCoachingObjective = token => {
  return API.get(`api/v1/coaching-objective/`, token)
}

export const getCoachingStyle = token => {
  return API.get(`api/v1/coaching-style/`, token)
}

export const getImGoodAt = token => {
  return API.get(`api/v1/im-good-at/`, token)
}

export const createOrganizationTeam = (payload, token) => {
  return API.post(`api/v1/teams/`, payload, token)
}

export const updateOrganizationTeam = (id, payload, token) => {
  return API.put(`api/v1/teams/${id}/`, payload, token)
}

export const createOrganizationTeamStat = (payload, token) => {
  return API.post(`api/v1/organization-stats/`, payload, token)
}

export const createPlayerTeamStat = (payload, token) => {
  return API.post(`api/v1/player-stat-entry/`, payload, token)
}
export const updatePlayerTeamStat = (id, payload, token) => {
  return API.patch(`api/v1/player-stat-entry/${id}/`, payload, token)
}

export const getPositions = (queryParams, token) => {
  return API.get(`api/v1/positions/${queryParams}`, token)
}

export const getGrades = token => {
  return API.get(`api/v1/player-grade/`, token)
}

export const getPlayerToFav = (payload, token) => {
  return API.get(`api/v1/users/favorite-players/${payload}`, token)
}

export const reqLinkToPlayer = (payload, token) => {
  return API.post(`api/v1/users/link_to_player/`, payload, token)
}

export const addPlayerToFav = (payload, token) => {
  return API.get(`api/v1/players/add-to-favorites/${payload}`, token)
}

export const removePlayerToFav = (payload, token) => {
  return API.get(`api/v1/players/remove-from-favorites/${payload}`, token)
}

export const addFollow = (payload, token) => {
  return API.get(`api/v1/users/follow/${payload}`, token)
}

export const removeFollow = (payload, token) => {
  return API.get(`api/v1/users/unfollow/${payload}`, token)
}

export const getHashtags = token => {
  return API.get(`api/v1/organization-hashtags/`, token)
}

export const organizationReview = (payload, token) => {
  return API.post(`api/v1/organization-reviews/`, payload, token)
}

export const getPolicy = token => {
  return API.get(`api/v1/privacy-policy/`, token)
}

export const getTerms = token => {
  return API.get(`api/v1/terms-and-conditions/`, token)
}

export const getAllPosts = (payload, token) => {
  return API.get(`api/v1/all-posts/${payload}`, token)
}
export const getPostById = (id, token) => {
  return API.get(`api/v1/all-posts/${id}/`, token)
}

export const postCreate = (payload, token) => {
  return API.post(`api/v1/user-posts/`, payload, token)
}

export const postLike = (payload, token) => {
  return API.post(`api/v1/like/`, payload, token)
}
export const getLike = (id, token) => {
  return API.get(`api/v1/like/${id}/`, token)
}

export const addComments = (payload, token) => {
  return API.post(`api/v1/comments/`, payload, token)
}

export const getComments = (payload, token) => {
  return API.get(`api/v1/comments/${payload}`, token)
}

export const searchAPI = (payload, token) => {
  return API.post(`api/v1/search/`, payload, token)
}

export const reviewsCatScore = (payload, token) => {
  return API.get(`api/v1/reviews/organization-categories/${payload}`, token)
}

export const checkReviewStatus = (payload, token) => {
  return API.get(
    `api/v1/reviews/organization-questions/check_entry/${payload}`,
    token
  )
}

export const reviewCatQuestions = (payload, token) => {
  return API.get(
    `api/v1/reviews/organization-questions/list_questions/${payload}`,
    token
  )
}
export const reviewCatQuestionChoices = token => {
  return API.get(`api/v1/reviews/likert-choices/`, token)
}

export const submitQuestionReview = (payload, token) => {
  return API.post(
    `api/v1/reviews/organization-questions/submit_choice/`,
    payload,
    token
  )
}
//Coach Reivews
export const reviewsCoachCatScore = (payload, token) => {
  return API.get(`api/v1/reviews/coach-categories/${payload}`, token)
}

export const checkCoachReviewStatus = (payload, token) => {
  return API.get(`api/v1/reviews/coach-questions/check_entry/${payload}`, token)
}

export const reviewCoachCatQuestions = (payload, token) => {
  return API.get(
    `api/v1/reviews/coach-questions/list_questions/${payload}`,
    token
  )
}

export const submitCoachQuestionReview = (payload, token) => {
  return API.post(
    `api/v1/reviews/coach-questions/submit_choice/`,
    payload,
    token
  )
}

export const sendFeedback = (payload, token) => {
  return API.post(`users/send/feedback/`, payload, token)
}

export const removeFollower = (payload, token) => {
  return API.get(`api/v1/users/remove_follower/${payload}`, token)
}

export const getMotivations = token => {
  return API.get(`api/v1/motivations/`, token)
}

export const getSports = token => {
  return API.get(`api/v1/sports/`, token)
}

export const getSchools = (payload, token) => {
  return API.get(`api/v1/schools/${payload}`, token)
}

export const getTeamTravel = token => {
  return API.get(`api/v1/team-travel/`, token)
}

export const getTargetTeamCompetition = token => {
  return API.get(`api/v1/target-team-competition/`, token)
}

export const getTargetPlayerLevel = token => {
  return API.get(`api/v1/target-player-level/`, token)
}

export const endPlayerSeason = (payload, token) => {
  return API.post(`api/v1/end-player-season/`, payload, token)
}

export const endTeamSeason = (payload, token) => {
  return API.post(`api/v1/end-team-season/`, payload, token)
}

export const getPlayerSeasons = (id, token) => {
  return API.get(`api/v1/player-seasons/?player__id=${id}&limit=1000`, token)
}

export const getPlayerStats = (id, token) => {
  return API.get(`api/v1/player-stat-entry/?player__id=${id}&limit=1000`, token)
}
export const deletePlayerStats = (id, token) => {
  return API.delete(`api/v1/player-stat-entry/${id}/`, token)
}
export const getPlayerStatsWithSeason = (id, token, season) => {
  return API.get(`api/v1/player-stat-entry/?player__id=${id}&season=${season}&limit=1000`, token)
}

export const getAllPlayerSeason = (payload, token) => {
  return API.post(`api/v1/all-player-season/`, payload, token, [
    function (data) {
      // Attempt to parse the JSON received from the server
      try {
        const jsonData = JSON.parse(data)

        if (jsonData.value && Number.isFinite(jsonData.value)) {
          jsonData.value = jsonData.value.toFixed(1) // Convert to string with one decimal place
        }

        // Modify the response or log a message
        console.log(
          data,
          "Processing response in transformResponse",
          jsonData,
          jsonData.value,
          Number.isFinite(jsonData.value)
        )

        return jsonData // Return the modified data
      } catch (error) {
        // If parsing fails, return the original data
        console.log("Error processing response:", error)
        return data
      }
    }
  ])
}

export const getTeamPlayers = (id, token) => {
  return API.get(`api/v1/team-player/?team=${id}`, token)
}

export const getPotentialPlayers = token => {
  return API.get(`api/v1/potential-players/`, token)
}

export const addPotentialPlayer = (payload, token) => {
  return API.post(`api/v1/potential-players/`, payload, token)
}

export const deletePotentialPlayers = (payload = {}, token) => {
  return API.post(`api/v1/delete/potential-player/`, payload, token)
}

export const invitePotentialPlayer = (payload = {}, token) => {
  return API.post(`api/v1/invite/potential-player/`, payload, token)
}

export const assignPotentialPlayer = (payload = {}, token) => {
  return API.post(`api/v1/assign/potential-player/`, payload, token)
}

export const addTeamPlayer = (payload, token) => {
  return API.post(`api/v1/team-player/`, payload, token)
}

export const editTeamPlayer = (id, payload, token) => {
  return API.post(`api/v1/team-player/${id}`, payload, token)
}

export const deleteTeamPlayer = (payload = {}, token) => {
  return API.post(`api/v1/delete/team-player/`, payload, token)
}

export const updateTeamPlayer = (payload = {}, token) => {
  return API.post(`api/v1/update/team-player/`, payload, token)
}

export const acceptInvitation = (payload, token) => {
  return API.post(`api/v1/accept/potential-player/`, payload, token)
}

export const getChannels = () => {
  return API.get(`api/v1/chat/my_chats/`)
}

export const getListParent = (payload, token) => {
  return API.get(`api/v1/users/list_parents/${payload}`, token)
}

export const getChannelMessages = payload => {
  return API.get(`api/v1/chat/channel/${payload}`)
}

export const publishMessage = (payload, token) => {
  return API.post(`api/v1/chat/publish_message/`, payload, token)
}

export const createChannel = payload => {
  return API.post(`api/v1/chat/channel/`, payload)
}
export const updateChannel = (id, payload) => {
  return API.patch(`api/v1/chat/${id}/`, payload)
}

export const addMemberChannel = payload => {
  return API.post(`api/v1/chat/add_users/`, payload)
}

export const leaveGroup = payload => {
  return API.post(`api/v1/chat/remove_users/`, payload)
}

export const readChannel = payload => {
  return API.post(`api/v1/chat/mark_all_as_read/`, payload)
}

export const createTryout = (payload, token) => {
  return API.post(`api/v1/try-outs/`, payload, token)
}

export const deleteTryout = (id, token) => {
  return API.delete(`api/v1/try-outs/${id}/`, {}, token)
}

export const practiceSchedules = (payload, token) => {
  return API.post(`api/v1/practice-schedules/`, payload, token)
}

export const updatePracticeSchedules = (id, payload, token) => {
  return API.patch(`api/v1/practice-schedules/${id}/`, payload, token)
}

export const getGameSchedules = (payload, token) => {
  return API.get(`api/v1/schedules/${payload}`, token)
}

export const allSchedules = (payload, token) => {
  return API.get(`api/v1/show-all-schedules/${payload}`, token)
}

export const createGameSchedules = (payload, token) => {
  return API.post(`api/v1/schedules/`, payload, token)
}

export const updateGameSchedules = (id, payload, token) => {
  return API.put(`api/v1/schedules/${id}/`, payload, token)
}

export const deleteGameSchedules = (id, token) => {
  return API.delete(`api/v1/schedules/${id}/`, {}, token)
}

export const getAllCoaches = (qs, token) => {
  return API.get(`api/v1/all-coaches/${qs}`, token)
}

// Delete Post
export const DeleteUserPosts = (id, token) => {
  return API.delete(`api/v1/user-posts/${id}/`, token)
}

export const upcomingEvents = (token) => {
  return API.get(`api/v1/schedules/upcoming_events/`, token)
}
