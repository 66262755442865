import React, { useState } from "react"

const SeeMoreText = ({ text = "", maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = event => {
    event.preventDefault() // Prevent the link from navigating
    setIsExpanded(!isExpanded)
  }

  console.log(text?.length, maxLength, "text?.length <= maxLength")

  if (text?.length <= maxLength || text == undefined) {
    return <p>{text}</p>
  }

  return (
    <>
      {isExpanded ? text : `${text?.substring(0, maxLength)?.trim()}... `}
      <a
        href="#"
        onClick={toggleExpand}
        style={{ textDecoration: "none", color: "blue" }}
      >
        {isExpanded ? " See Less" : "See More"}
      </a>
    </>
  )
}

export default SeeMoreText
