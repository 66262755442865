import * as React from "react"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { AppButton, Loader } from ".."
import { COLORS } from "../../constants"
import { useNavigate } from "react-router-dom"
import avatar from "../../assets/images/avatar.png"
import { ReactComponent as DeleteIcon } from "../../assets/svg/admin/delete.svg"
import { ReactComponent as DeleteOrangeIcon } from "../../assets/svg/admin/deleteOrange.svg"
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Typography
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: "#000000D9"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 6,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
}

export default function FeedBackTable({
  feedback,
  users,
  loading,
  clickNext,
  page,
  count,
  loadingDelete,
  _deleteUser
}) {
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    selectedID: "",
    visible: false
  })

  const { visible, selectedID } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleEditTeam = (id, role) => {
    if (role === "Player") {
      navigate(`/webadmin/users/player/${id}`)
    }
    if (role === "Parent") {
      navigate(`/webadmin/users/parent/${id}`)
    }
    if (role === "Organization") {
      navigate(`/webadmin/users/organization/${id}`)
    }
  }

  const handleOpenDelete = id => {
    handleChange("selectedID", id)
    handleChange("visible", true)
  }
  const handleCloseDelete = () => {
    handleChange("selectedID", "")
    handleChange("visible", false)
  }
  const [expandedRows, setExpandedRows] = React.useState([])

  const toggleRowExpansion = index => {
    const expandedIndex = expandedRows.indexOf(index)
    if (expandedIndex !== -1) {
      setExpandedRows(expandedRows.filter(i => i !== index))
    } else {
      setExpandedRows([...expandedRows, index])
    }
  }
  const truncateString = (str, num) => {
    if (str?.length <= num) {
      return str
    }
    return str?.slice(0, num) + "..."
  }
  return (
    <>
      <TableContainer>
        {loading ? (
          <div className="divCenter fullWidthWeb">
            <Loader />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Feedback Message</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feedback?.length > 0 ? (
                feedback?.map((user, index) => {
                  let names = users.filter(item => item.id === user?.user)
                  console.log(names, "names===")
                  return (
                    <>
                      <React.Fragment key={user?.id}>
                        <StyledTableRow
                          onClick={() => handleEditTeam(user?.id, user?.role)}
                        >
                          <StyledTableCell align="left">
                            {index + 1}
                          </StyledTableCell>
                          {names?.map((item, nameIndex) => {
                            console.log("item ==>", item)
                            return (
                              <>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <Avatar
                                    src={
                                      item?.player?.picture ||
                                      item?.organization_data
                                        ?.profile_picture ||
                                      item?.coaches?.picture ||
                                      item?.parent?.picture
                                        ? item?.player?.picture ||
                                          item?.organization_data
                                            ?.profile_picture ||
                                          item?.coaches?.picture ||
                                          item?.parent?.picture
                                        : avatar
                                    }
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {item?.name ? item?.name : <p>No name</p>}
                                  </span>
                                </StyledTableCell>

                                <StyledTableCell component="th" scope="row">
                                  {item?.player?.user?.email ||
                                    item?.organization?.user?.email ||
                                    item?.coaches?.user?.email ||
                                    item?.parent?.user?.email}
                                </StyledTableCell>
                              </>
                            )
                          })}

                          <StyledTableCell align="left">
                            <div>
                              {expandedRows.includes(index)
                                ? user?.text
                                : truncateString(user?.text, 60)}
                              {user?.text.length > 50 && (
                                <IconButton
                                  onClick={() => toggleRowExpansion(index)}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </React.Fragment>
                    </>
                  )
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        )}
        <Grid container justifyContent={"flex-end"}>
          <Pagination
            count={Math.trunc(count / 10)}
            page={page}
            onChange={(e, page) => clickNext(page)}
            shape="rounded"
          />
        </Grid>
      </TableContainer>
      <Modal
        open={visible}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className="mt-3" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: 2 }}
              >
                Are you sure you want to delete this user? You won’t be able to
                recover it
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-3">
            <AppButton
              title={"Delete"}
              height={40}
              loading={loadingDelete}
              onClick={() => _deleteUser(selectedID, handleCloseDelete)}
              width={"100%"}
              fontWeight={"normal"}
              backgroundColor={COLORS.orange}
              color={COLORS.white}
            />
            <AppButton
              title={"Cancel"}
              height={40}
              onClick={handleCloseDelete}
              width={"100%"}
              backgroundColor={"#fff"}
              className={"mt-2"}
              color={"#595959"}
              borderColor={"#D9D9D9"}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
