// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import { HomeHeader, AppButton, AppInput, Label } from "../../components"
import {
  Checkbox,
  Grid,
  Autocomplete as TextAutocomplete,
  TextField,
  Typography
} from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as Camera } from "../../assets/svg/Camera.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { COLORS, MAP_API_KEY } from "../../constants"
import { getError } from "../../utils/getError"
import { getCoachingObjective, getImGoodAt, getIveCoached, getPreferredCoachLevel, getTeams, getUserProfile, searchUserProfile, updateUserProfile } from "../../api/auth"
import MultipleSelect from "../../components/MultipleSelect"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { validateURL } from "../../utils/ValidateEmail"

export default function CoachProfileForm({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const location = useLocation()
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const { _getProfile, user, sports } = useContext(AppContext)
  const hiddenFileInput = useRef(null)
  const [state, setState] = useState({
    userProfile: [],
    previousUsername: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    isNew: false,
    editMode: true,
    loading: false,
    profileImg: null,
    profileObj: null,
    team: "",
    team_custom: "",
    teams: [],
    organization_list: [],
    iveCoachedList: [],
    experience: "",
    ive_coached: [],
    preferred_coaching_level: [],
    preferred_coaching_level_list: [],
    im_good_at: [],
    im_good_at_list: [],
    highlights: "",
    strategic_coaching_objective: [],
    strategic_coaching_objective_list: [],
    coaching_style: [],
    training_available: false,
    current_organization: '',
    current_organization_custom: '',
    previous_organization: '',
    previous_organization_custom: '',
    previous_organization_two: '',
    previous_organization_two_custom: '',
    previous_organization_three: '',
    previous_organization_three_custom: '',
    previous_organization_four: '',
    previous_organization_four_custom: '',
    tiktok_username: "",
    display_twitter: false,
    facebook_username: "",
    preferred_jersey_number: "",
    display_facebook: false,
    instagram_username: "",
    display_instagram: false,
    twitter_username: "",
    threads_username: "",
    youtube_username: "",
    display_tiktok: false,
    display_threads: false,
    display_youtube: false,
    main_sport: "",
  })

  const {
    userProfile,
    previousUsername,
    username,
    bio,
    first_name,
    last_name,
    email,
    phone_number,
    isNew,
    editMode,
    profileImg,
    profileObj,
    loading,
    team,
    team_custom,
    teams,
    experience,
    ive_coached,
    preferred_coaching_level,
    preferred_coaching_level_list,
    im_good_at,
    im_good_at_list,
    highlights,
    strategic_coaching_objective,
    strategic_coaching_objective_list,
    coaching_style,
    training_available,
    previous_organization,
    previous_organization_custom,
    previous_organization_two,
    previous_organization_two_custom,
    previous_organization_three,
    previous_organization_three_custom,
    previous_organization_four,
    previous_organization_four_custom,
    tiktok_username,
    display_twitter,
    facebook_username,
    display_facebook,
    instagram_username,
    display_instagram,
    twitter_username,
    threads_username,
    youtube_username,
    display_tiktok,
    display_threads,
    display_youtube,
    iveCoachedList,
    organization_list,
    current_organization,
    current_organization_custom,
    main_sport
  } = state
  useEffect(() => {
    _getProfile()
    getProfileData()
    getData()
    if (location?.pathname === `/coach-profile/${user?.id}/add`) {
      handleChange("isNew", true)
    }
  }, [location, id])

  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      getData(res?.data?.coach?.id)
      handleChange("userProfile", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  useEffect(() => {
    if (userProfile) {
      handleChange("previousUsername", userProfile?.coach?.username || "")
      handleChange("username", userProfile?.coach?.username || "")
      handleChange("bio", userProfile?.coach?.bio || "")
      handleChange("first_name", userProfile?.first_name || "")
      handleChange("last_name", userProfile?.last_name || "")
      handleChange("main_sport", userProfile?.coach?.main_sport?.id)
      handleChange("email", userProfile?.email || "")
      handleChange("phone_number", userProfile?.phone_number || "")
      handleChange("city", userProfile?.city || "")
      handleChange("street_address", userProfile?.street_address || "")
      handleChange("zip_code", userProfile?.zip_code || "")
      handleChange("selectedState", userProfile?.state || "")
      handleChange("team", userProfile?.coach?.team?.id || "")
      handleChange(
        "team_custom",
        userProfile?.coach?.team?.team_name ||
        userProfile?.coach?.team_custom ||
        ""
      )
      handleChange("current_organization", userProfile?.coach?.current_organization?.id || "")
      handleChange(
        "current_organization_custom",
        userProfile?.coach?.current_organization?.organization_name ||
        userProfile?.coach?.current_organization_custom ||
        ""
      )
      handleChange("previous_organization", userProfile?.coach?.previous_organization?.id || "")
      handleChange(
        "previous_organization_custom",
        userProfile?.coach?.previous_organization?.organization_name ||
        userProfile?.coach?.previous_organization_custom ||
        ""
      )
      handleChange("previous_organization_two", userProfile?.coach?.previous_organization_two?.id || "")
      handleChange(
        "previous_organization_two_custom",
        userProfile?.coach?.previous_organization_two?.organization_name ||
        userProfile?.coach?.previous_organization_two_custom ||
        ""
      )
      handleChange("previous_organization_three", userProfile?.coach?.previous_organization_three?.id || "")
      handleChange(
        "previous_organization_three_custom",
        userProfile?.coach?.previous_organization_three?.organization_name ||
        userProfile?.coach?.previous_organization_three_custom ||
        ""
      )
      handleChange("previous_organization_four", userProfile?.coach?.previous_organization_four?.id || "")
      handleChange(
        "previous_organization_four_custom",
        userProfile?.coach?.previous_organization_four?.organization_name ||
        userProfile?.coach?.previous_organization_four_custom ||
        ""
      )
      handleChange("highlights", userProfile?.coach?.highlights || "")
      handleChange("experience", userProfile?.coach?.experience || "")
      handleChange("tiktok_username", userProfile?.coach?.tiktok_username)
      handleChange("threads_username", userProfile?.coach?.threads_username)
      handleChange("youtube_username", userProfile?.coach?.youtube_username)
      handleChange(
        "instagram_username",
        userProfile?.coach?.instagram_username
      )
      handleChange("twitter_username", userProfile?.coach?.twitter_username)
      handleChange("facebook_username", userProfile?.coach?.facebook_username)
      handleChange("display_twitter", userProfile?.coach?.display_twitter)
      handleChange("display_threads", userProfile?.coach?.display_threads)
      handleChange("display_youtube", userProfile?.coach?.display_youtube)
      handleChange("display_instagram", userProfile?.coach?.display_instagram)
      handleChange("display_facebook", userProfile?.coach?.display_facebook)
      handleChange("display_tiktok", userProfile?.coach?.display_tiktok)
      handleChange("training_available", userProfile?.coach?.training_available)
      handleChange("ive_coached", userProfile?.coach?.ive_coached?.map((ive_coached) => ive_coached?.id))
      handleChange("strategic_coaching_objective", userProfile?.coach?.strategic_coaching_objective?.map((strategic_coaching_objective) => strategic_coaching_objective?.id))
      handleChange("preferred_coaching_level", userProfile?.coach?.preferred_coaching_level?.map((preferred_coaching_level) => preferred_coaching_level?.id))
      handleChange("im_good_at", userProfile?.coach?.im_good_at?.map((im_good_at) => im_good_at?.id))
    }
  }, [userProfile])

  const getData = async () => {
    try {
      // handleChange("loading", true)
      const res = await getTeams(token)
      const res1 = await getIveCoached(token)
      const res2 = await getCoachingObjective(token)
      const res3 = await getPreferredCoachLevel(token)
      const res4 = await getImGoodAt(token)
      handleChange("teams", res?.data)
      handleChange("iveCoachedList", res1?.data)
      handleChange("strategic_coaching_objective_list", res2?.data)
      handleChange("preferred_coaching_level_list", res3?.data)
      handleChange("im_good_at_list", res4?.data)
      getUsers('')
      // handleChange("loading", false)
    } catch (error) {
      // handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const getUsers = async (text) => {
    try {
      const res = await searchUserProfile(`?role=Organization&search=${text}`, token)
      handleChange("organization_list", res?.data?.results)
    } catch (error) {
      // handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const onFileChange = event => {
    handleChange("profileImg", URL.createObjectURL(event.target.files[0]))
    handleChange("profileObj", event.target.files[0])
  }

  const handleSubmit = async () => {
    try {
      if (tiktok_username && !validateURL(tiktok_username)) {
        alert("Enter a valid tiktok URL")
        return
      }
      if (twitter_username && !validateURL(twitter_username)) {
        alert("Enter a valid twitter URL")
        return
      }
      if (instagram_username && !validateURL(instagram_username)) {
        alert("Enter a valid instagram URL")
        return
      }
      if (youtube_username && !validateURL(youtube_username)) {
        alert("Enter a valid youtube URL")
        return
      }
      if (threads_username && !validateURL(threads_username)) {
        alert("Enter a valid threads URL")
        return
      }
      if (facebook_username && !validateURL(facebook_username)) {
        alert("Enter a valid facebook URL")
        return
      }
      handleChange("loading", true)
      const payload = new FormData()
      // Append username to payload only if it has changed
      if (username !== previousUsername) {
        payload.append("coach.username", state.username)
      }
      bio && payload?.append("coach.bio", bio)
      first_name && payload?.append("first_name", first_name)
      last_name && payload?.append("last_name", last_name)
      payload?.append("name", first_name + " " + last_name)
      profileObj && payload?.append("coach.picture", profileObj)
      phone_number && payload?.append("phone_number", phone_number)
      experience && payload?.append("coach.experience", experience)
      highlights && payload?.append("coach.highlights", highlights)
      team && payload?.append("coach.team", team)
      main_sport && payload?.append("coach.main_sport", main_sport)
      !team && team_custom && payload?.append("coach.team_custom", team_custom)
      current_organization && payload?.append("coach.current_organization", current_organization)
      !current_organization && current_organization_custom && payload?.append("coach.current_organization_custom", current_organization_custom)
      previous_organization && payload?.append("coach.previous_organization", previous_organization)
      !previous_organization && previous_organization_custom && payload?.append("coach.previous_organization_custom", previous_organization_custom)
      previous_organization_two && payload?.append("coach.previous_organization_two", previous_organization_two)
      !previous_organization_two && previous_organization_two_custom && payload?.append("coach.previous_organization_two_custom", previous_organization_two_custom)
      previous_organization_three && payload?.append("coach.previous_organization_three", previous_organization_three)
      !previous_organization_three && previous_organization_three_custom && payload?.append("coach.previous_organization_three_custom", previous_organization_three_custom)
      previous_organization_four && payload?.append("coach.previous_organization_four", previous_organization_four)
      !previous_organization_four && previous_organization_four_custom && payload?.append("coach.previous_organization_four_custom", previous_organization_four_custom)
      tiktok_username &&
        payload?.append("coach.tiktok_username", tiktok_username)
      instagram_username &&
        payload?.append("coach.instagram_username", instagram_username)
      twitter_username &&
        payload?.append("coach.twitter_username", twitter_username)
      threads_username &&
        payload?.append("coach.threads_username", threads_username)
      youtube_username &&
        payload?.append("coach.youtube_username", youtube_username)
      facebook_username &&
        payload?.append("coach.facebook_username", facebook_username)
      payload?.append("coach.display_twitter", display_twitter)
      payload?.append("coach.display_instagram", display_instagram)
      payload?.append("coach.display_facebook", display_facebook)
      payload?.append("coach.display_tiktok", display_tiktok)
      payload?.append("coach.display_threads", display_threads)
      payload?.append("coach.display_youtube", display_youtube)
      payload?.append("coach.training_available", training_available)
      ive_coached?.length > 0 && ive_coached?.map((ive_coached) => payload?.append("coach.ive_coached", ive_coached))
      strategic_coaching_objective?.length > 0 && strategic_coaching_objective?.map((strategic_coaching_objective) => payload?.append("coach.strategic_coaching_objective", strategic_coaching_objective))
      preferred_coaching_level?.length > 0 && preferred_coaching_level?.map((preferred_coaching_level) => payload?.append("coach.preferred_coaching_level", preferred_coaching_level))
      im_good_at?.length > 0 && im_good_at?.map((im_good_at) => payload?.append("coach.im_good_at", im_good_at))
      if (username == null || username == "") {
        alert("User Name is required")
        handleChange("username", userProfile?.coach?.username || "")
        handleChange("loading", false)
      } else if (first_name == "") {
        alert("First Name is required")
        handleChange("loading", false)
        handleChange("first_name", userProfile?.first_name || "")
      } else if (last_name == "") {
        alert("Last Name is required")
        handleChange("loading", false)
        handleChange("last_name", userProfile?.last_name || "")
      } else {
        await updateUserProfile(userProfile?.id, payload, token)
        _getProfile(userProfile?.id)
        handleChange("loading", false)
        handleChange("editMode", false)
        navigate("/dashboard")
        enqueueSnackbar(
          `Coach profile has been ${location?.pathname === "/parent-profile/add" ? "created" : "updated"
          }`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            }
          }
        )
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("address", address)
      handleChange("city", city)
      handleChange("country", country)
      handleChange("selectedState", dState)
      handleChange("zip_code", postal_code)
    } catch (error) {
      alert(error?.message)
    }
  }

  const option1 = (
    <>
      <option value={""} disabled>
        Select Available Training
      </option>
      <option value={true}>
        Yes
      </option>
      <option value={false}>
        No
      </option>
    </>
  )

  console.log('organization_list', organization_list);
  const listSports = sports?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const option4 = <option value={""}>Select Sport</option>
  const sportsOptions = [option4, ...listSports]

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container width70 pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="pageHeading">{isNew ? "Create" : "Edit"} Profile</div>
        </Grid>
        <Grid
          className="mb-3 mt-4"
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div className="">
            <img
              src={profileImg || userProfile?.coach?.picture || AvatarIcon}
              className={"c-pointer profileIMG"}
            />
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            {editMode && (
              <AppButton
                title={<Camera />}
                onClick={handleClick}
                backgroundColor={"#FAFAFA"}
                borderColor={"#0000000F"}
                width={30}
                borderRadius={30}
                height={30}
                className={"min-width-remove camIcon"}
              />
            )}
          </div>
        </Grid>
        <Grid container className="mb-4">
          <div className="font-bold text-black">Personal information</div>
        </Grid>
        <Grid container spacing={2} className="border p-3 round-1">
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"User Name"}
              height={40}
              onChange={handleChange}
              value={username}
              name={"username"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"First Name"}
              height={40}
              onChange={handleChange}
              value={first_name}
              name={"first_name"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Last Name"}
              height={40}
              onChange={handleChange}
              value={last_name}
              name={"last_name"}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              disabled={!editMode}
              label={"Sports"}
              select
              selectOptions={sportsOptions}
              height={40}
              onChange={handleChange}
              value={main_sport}
              name={"main_sport"}
            />
          </Grid>
          <Grid item xs={12}>
            <AppInput
              disabled={true}
              label={"Email"}
              height={40}
              onChange={handleChange}
              value={email}
              name={"email"}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <AppInput
              disabled={!editMode}
              label={"Phone number"}
              type={"number"}
              height={40}
              onChange={handleChange}
              value={phone_number}
              name={"phone_number"}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Label text={"Team Name"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={current_organization_custom}
              onChange={(event, newValue) => {
                handleChange("current_organization_custom", newValue)
                handleChange(
                  "current_organization",
                  organization_list[event?.target?.dataset?.optionIndex]?.organization?.id
                )
              }}
              inputValue={current_organization_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  getUsers(newInputValue)
                }
                handleChange("current_organization_custom", newInputValue)
                handleChange("current_organization", "")
              }}
              options={organization_list?.map(option => option?.organization?.organization_name || 'No Name')}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            {
              editMode
                ?
                <>
                  <Label text={"Street Address"} />
                  <AutoComplete
                    apiKey={MAP_API_KEY}
                    className="locationSearch"
                    options={{ types: ["geocode", "establishment"] }}
                    style={{ height: 40, backgroundColor: "#fff" }}
                    onChange={(e) => handleChange("street_address", e?.target?.value)}
                    defaultValue={street_address}
                    onPlaceSelected={place => {
                      handleSearch(place)
                    }}
                    placeholder=""
                  />
                </>
                :
                <AppInput disabled={true} label={'Street address'} height={40} onChange={handleChange} value={street_address} name={'street_address'} />
            }
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput disabled={!editMode} label={'City'} height={40} onChange={handleChange} value={city} name={'city'} />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput disabled={!editMode} label={'Zipcode'} height={40} onChange={handleChange} value={zip_code} name={'zip_code'} />
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput disabled={!editMode} label={'State'} height={40} onChange={handleChange} value={selectedState} name={'selectedState'} />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <AppInput
              label={"Coaching Experience (yrs)"}
              height={40}
              type={'number'}
              onChange={handleChange}
              value={experience}
              name={"experience"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"I've coached"}
              data={iveCoachedList?.map(item => ({ ...item, name: item?.context }))}
              height={40}
              onChange={e => handleChange("ive_coached", e)}
              value={ive_coached?.map(v => (v?.id ? v.id : v))}
              name={"ive_coached"}
              placeholder="Select I've coached"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Preferred coaching level"}
              data={preferred_coaching_level_list?.map(item => ({ ...item, name: item?.context }))}
              height={40}
              onChange={e => handleChange("preferred_coaching_level", e)}
              value={preferred_coaching_level?.map(v => (v?.id ? v.id : v))}
              name={"preferred_coaching_level"}
              placeholder="Select preferred coaching level"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label text={"Previous organization 1"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={previous_organization_custom}
              onChange={(event, newValue) => {
                handleChange("previous_organization_custom", newValue)
                handleChange(
                  "previous_organization",
                  organization_list[event?.target?.dataset?.optionIndex]?.organization?.id
                )
              }}
              inputValue={previous_organization_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  getUsers(newInputValue)
                }
                handleChange("previous_organization_custom", newInputValue)
                handleChange("previous_organization", "")
              }}
              options={organization_list?.map(option => option?.organization?.organization_name || 'No Name')}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label text={"Previous organization 2"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={previous_organization_two_custom}
              onChange={(event, newValue) => {
                handleChange("previous_organization_two_custom", newValue)
                handleChange(
                  "previous_organization_two",
                  organization_list[event?.target?.dataset?.optionIndex]?.organization?.id
                )
              }}
              inputValue={previous_organization_two_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  getUsers(newInputValue)
                }
                handleChange("previous_organization_two_custom", newInputValue)
                handleChange("previous_organization_two", "")
              }}
              options={organization_list?.map(option => option?.organization?.organization_name || 'No Name')}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label text={"Previous organization 3"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={previous_organization_three_custom}
              onChange={(event, newValue) => {
                handleChange("previous_organization_three_custom", newValue)
                handleChange(
                  "previous_organization_three",
                  organization_list[event?.target?.dataset?.optionIndex]?.organization?.id
                )
              }}
              inputValue={previous_organization_three_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  getUsers(newInputValue)
                }
                handleChange("previous_organization_three_custom", newInputValue)
                handleChange("previous_organization_three", "")
              }}
              options={organization_list?.map(option => option?.organization?.organization_name || 'No Name')}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label text={"Previous organization 4"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={previous_organization_four_custom}
              onChange={(event, newValue) => {
                handleChange("previous_organization_four_custom", newValue)
                handleChange(
                  "previous_organization_four",
                  organization_list[event?.target?.dataset?.optionIndex]?.organization?.id
                )
              }}
              inputValue={previous_organization_four_custom}
              onInputChange={(event, newInputValue) => {
                if (newInputValue?.length > 2) {
                  getUsers(newInputValue)
                }
                handleChange("previous_organization_four_custom", newInputValue)
                handleChange("previous_organization_four", "")
              }}
              options={organization_list?.map(option => option?.organization?.organization_name || 'No Name')}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"I'm good at"}
              data={im_good_at_list?.map(item => ({ ...item, name: item?.context }))}
              height={40}
              onChange={e => handleChange("im_good_at", e)}
              value={im_good_at?.map(v => (v?.id ? v.id : v))}
              name={"im_good_at"}
              placeholder="Select I'm good at"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MultipleSelect
              disabled={!editMode}
              label={"Strategic coaching objectives"}
              data={strategic_coaching_objective_list?.map(item => ({ ...item, name: item?.context }))}
              height={40}
              onChange={e => handleChange("strategic_coaching_objective", e)}
              value={strategic_coaching_objective?.map(v => (v?.id ? v.id : v))}
              name={"strategic_coaching_objective"}
              placeholder="Select strategic coaching objectives"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              label={"Available Training"}
              select
              selectOptions={option1}
              height={40}
              onChange={handleChange}
              value={training_available}
              name={"training_available"}
              placeholder="Select Available Training"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Tiktok style={{ marginTop: 12 }} />}
              label={"Tiktok profile URL"}
              height={40}
              onChange={handleChange}
              errorText={tiktok_username && !validateURL(tiktok_username) && "url is not valid"}
              value={tiktok_username}
              name={"tiktok_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_tiktok}
                onClick={() => handleChange("display_tiktok", !display_tiktok)}
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Facebook style={{ marginTop: 12 }} />}
              label={"Facebook profile URL"}
              errorText={facebook_username && !validateURL(facebook_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={facebook_username}
              name={"facebook_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                defaultChecked={false}
                disabled={!editMode}
                checked={display_facebook}
                onClick={() =>
                  handleChange("display_facebook", !display_facebook)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Insta style={{ marginTop: 12 }} />}
              label={"Instagram User name"}
              errorText={instagram_username && !validateURL(instagram_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={instagram_username}
              name={"instagram_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                checked={display_instagram}
                defaultChecked={false}
                onClick={() =>
                  handleChange("display_instagram", !display_instagram)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              errorText={twitter_username && !validateURL(twitter_username) && "url is not valid"}
              prefix={<Twitter style={{ marginTop: 12 }} />}
              label={"Twitter profile URL"}
              height={40}
              onChange={handleChange}
              value={twitter_username}
              name={"twitter_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_twitter}
                onClick={() =>
                  handleChange("display_twitter", !display_twitter)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Youtube style={{ marginTop: 12 }} />}
              label={"Youtube profile URL"}
              errorText={youtube_username && !validateURL(youtube_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={youtube_username}
              name={"youtube_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_youtube}
                onClick={() =>
                  handleChange("display_youtube", !display_youtube)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <AppInput
              disabled={!editMode}
              prefix={<Threads style={{ marginTop: 12 }} />}
              label={"Threads profile URL"}
              errorText={threads_username && !validateURL(threads_username) && "url is not valid"}
              height={40}
              onChange={handleChange}
              value={threads_username}
              name={"threads_username"}
            />
            <div className="checkboxDiv mb-3">
              <Checkbox
                disabled={!editMode}
                defaultChecked={false}
                checked={display_threads}
                onClick={() =>
                  handleChange("display_threads", !display_threads)
                }
                className="checkbox1"
                style={{
                  marginTop: -3
                }}
                sx={{
                  color: "rgba(201, 208, 216, 1)",
                  "&.Mui-checked": {
                    color: COLORS.primary
                  }
                }}
              />
              <Typography variant="body2" className="greyColor checkboxLabel">
                Display on social media profile.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Label fontNormal text="Bio" className="mt-3" />
            <textarea
              disabled={!editMode}
              name="bio"
              style={{
                width: "100%",
                display: "flex",
                borderRadius: 8,
                backgroundColor: "#fff",
                color: "#000",
                height: 150,
                justifyContent: "space-between",
                border: "1px solid #D9D9D9"
              }}
              placeholder="write a description"
              multiple={true}
              value={bio}
              onChange={e => {
                handleChange("bio", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Label fontNormal text="Coaching highlights:" className="mt-3" />
            <textarea
              disabled={!editMode}
              name="bio"
              style={{
                width: "100%",
                display: "flex",
                borderRadius: 8,
                backgroundColor: "#fff",
                color: "#000",
                height: 150,
                justifyContent: "space-between",
                border: "1px solid #D9D9D9"
              }}
              placeholder="write a coaching highlights"
              multiple={true}
              value={highlights}
              onChange={e => {
                handleChange("highlights", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        {editMode && (
          <Grid container justifyContent={"flex-end"} sx={{ mt: 3 }}>
            {!isNew && (
              <AppButton
                onClick={() => handleChange("editMode", false)}
                title={"Cancel"}
                className={"mr-3"}
                color={"#000000D9"}
                borderColor={"#D9D9D9"}
                width={100}
                height={40}
              />
            )}
            <AppButton
              title={"Save"}
              color={COLORS.white}
              backgroundColor={COLORS.primary}
              width={80}
              height={40}
              onClick={handleSubmit}
              loading={loading}
            />
          </Grid>
        )}
      </section>
    </div>
  )
}
