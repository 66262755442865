// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react'
import { AppButton, AppInput, HomeFooter, HomeHeader } from '../../components'
import { Grid, Tab, Tabs, Typography, Box, Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { changePasswordv2, loginUser, setPassword, signupUser } from '../../api/auth'
import AppContext from '../../Context'
import { useContext } from 'react'
import eyeIcon from '../../assets/svg/eye.svg'
import eyeOff from '../../assets/svg/eyeOff.svg'
import { ReactComponent as PasswordIcon } from "../../assets/svg/password.svg"
import { COLORS } from '../../constants'

export default function ChangePassword({ }) {
  const navigate = useNavigate()
  const { user, setUser } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    email: '',
    oldpassword: '',
    password_1: '',
    password_2: '',
    loading: false,
    loadingSignup: false,
    checked: false,
    isShow: false,
    isShow1: false,
    isShow2: false
  })

  const {
    activeTab,
    email,
    oldpassword,
    loading,
    emailSignup,
    passwordSignup,
    loadingSignup,
    password_1,
    password_2,
    isShow,
    isShow1,
    isShow2
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleTab = value => {
    if (value === 0) {
      navigate('/signup')
    } else {
      navigate('/login')
    }
    handleChange('activeTab', value)
  }

  const handleSignup = async () => {
    try {
      handleChange('loadingSignup', true)
      const payload = {
        new_password1:password_1,
        new_password2:password_2,
        old_password: oldpassword
      }
      const res = await changePasswordv2(payload)
      alert('Password has been changed')
      handleChange('loadingSignup', false)
      navigate('/')
    } catch (error) {
      handleChange('loadingSignup', false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const disabled = !oldpassword || !password_1 || !password_2 || password_1 && password_1?.length < 8 || password_1 !== password_2

  return (
    <div>
      <HomeHeader dashboard />
      <section className="container width70 pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="mb-3 font-lexend font-36 font-bold">Change password</div>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={10} md={6} className='p-4'>
            <AppInput
              className='mb-4 mt-3'
              value={oldpassword}
              name={'oldpassword'}
              inputWidthFull
              onChange={handleChange}
              prefix={<div className="passwordIcon"><PasswordIcon /></div>}
              label={'Current Password'}
              type={isShow ? 'text' : 'password'}
              postfix={
                <img
                  src={!isShow ? eyeIcon : eyeOff}
                  onClick={() => handleChange('isShow', !isShow)}
                  width={20}
                  className={'c-pointer'}
                />
              }
              placeholder={'********'}
            />
            <AppInput
              className='mb-4 mt-3'
              value={password_1}
              name={'password_1'}
              inputWidthFull
              prefix={<div className="passwordIcon"><PasswordIcon /></div>}
              onChange={handleChange}
              label={'Create Password'}
              type={isShow1 ? 'text' : 'password'}
              postfix={
                <img
                  src={!isShow1 ? eyeIcon : eyeOff}
                  onClick={() => handleChange('isShow1', !isShow1)}
                  width={20}
                  className={'c-pointer'}
                />
              }
              placeholder={'********'}
            />
            {password_1 && password_1?.length < 8 && (
              <div className="validationmessage">{"Password must have atleast 8 characters"}</div>
            )}
            <AppInput
              className='mt-3'
              value={password_2}
              prefix={<div className="passwordIcon"><PasswordIcon /></div>}
              inputWidthFull
              name={'password_2'}
              onChange={handleChange}
              label={'Confirm Password'}
              type={isShow2 ? 'text' : 'password'}
              postfix={
                <img
                  src={!isShow2 ? eyeIcon : eyeOff}
                  onClick={() => handleChange('isShow2', !isShow2)}
                  width={20}
                  className={'c-pointer'}
                />
              }
              placeholder={'********'}
            />
            {
              password_1 && password_2 && password_1 !== password_2 &&
              <div className="errorText">Password doesn't match</div>
            }
            <AppButton
              title={'Change password'}
              onClick={handleSignup}
              className={'mt-4'}
              loading={loadingSignup}
              disabled={disabled}
              backgroundColor={COLORS.primary}
              color={'#fff'}
            />
          </Grid>
        </Grid>
      </section >
    </div >
  )
}
