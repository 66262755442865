import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  TextField,
  Autocomplete as TextAutocomplete
} from "@mui/material"
import { COLORS, MAP_API_KEY } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import AppContext from "../../Context"
import { getTeams, practiceSchedules } from "../../api/auth"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import AutoComplete from "react-google-autocomplete"
import moment from "moment"

function PracticeSchedule({ getData, visible, handleClose, teamID }) {
  const { grades } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [state, setState] = useState({
    loading: false,
    start_date: "",
    end_date: "",
    location: "",
    duration: "",
    time: new Date(),
    note: "",
    teams: [],
    frequency: "",
    latitude: "",
    longitude: ""
  })
  const {
    start_date,
    end_date,
    duration,
    location,
    note,
    time,
    frequency,
    loading,
    latitude,
    longitude
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    _getTeams()
  }, [])

  const _getTeams = async () => {
    try {
      handleChange("loading", true)
      const res = await getTeams(token)
      handleChange("teams", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        start_date,
        end_date,
        location,
        latitude,
        longitude,
        duration,
        time: moment(new Date(time)).format("HH:mm"),
        frequency,
        notes: note,
        team: teamID
      }
      const res = await practiceSchedules(payload, token)
      handleChange("loading", false)
      handleReset()
      getData?.()
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleReset = () => {
    handleChange("start_date", "")
    handleChange("end_date", "")
    handleChange("duration", "")
    handleChange("location", "")
    handleChange("latitude", "")
    handleChange("longitude", "")
    handleChange("note", "")
    handleChange("frequency", "")
    handleChange("time", new Date())
    handleClose()
  }

  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("location", place?.formatted_address)
      handleChange("city", city)
      handleChange("country", country)
      handleChange("selectedState", dState)
      handleChange("zip_code", postal_code)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const fre = [
    { key: "Daily", value: 1 },
    { key: "Weekly", value: 7 },
    { key: "Weekdays", value: 5 },
    { key: "Weekends", value: 2 }
  ]
  const list4 = [
    { key: "Daily", value: 1 },
    { key: "Weekly", value: 7 },
    { key: "Weekdays", value: 5 },
    { key: "Weekends", value: 2 }
  ]?.map((item, index) => (
    <option key={index} value={item?.key}>
      {item?.key}
    </option>
  ))
  const option4 = <option value={""}>Select Frequency</option>

  const frequencyOptions = [option4, ...list4]

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, height: "90%", overflow: "auto" }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Practice Schedule</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross className="mb-1 mr-1" />}
            onClick={() => handleClose(false)}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"date"}
              height={40}
              placeholder={""}
              min={moment().format("YYYY-MM-DD")}
              label={"Start Date"}
              value={start_date}
              name={"start_date"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"date"}
              height={40}
              placeholder={""}
              min={moment(start_date).format("YYYY-MM-DD")}
              label={"End Date"}
              value={end_date}
              name={"end_date"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <>
              <Label text={"Location"} fontNormal />
              <AutoComplete
                apiKey={MAP_API_KEY}
                className="locationSearch"
                options={{ types: ["geocode", "establishment"] }}
                style={{ height: 40, backgroundColor: "#fff" }}
                onChange={e => handleChange("location", e?.target?.value)}
                defaultValue={location}
                onPlaceSelected={place => {
                  handleSearch(place)
                }}
                placeholder=""
              />
            </>
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              type={"time"}
              muiTime
              height={40}
              placeholder={""}
              label={"Time"}
              value={time}
              name={"time"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Duration (hrs)"}
              value={duration}
              name={"duration"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              placeholder={""}
              label={"Frequency"}
              select
              selectOptions={frequencyOptions}
              value={frequency}
              name={"frequency"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AppInput
              height={40}
              minHeightMulti={25}
              textAreaMarginTop={7}
              marginBottom={5}
              textAreaWidth={'95%'}
              placeholder={""}
              label={"Notes(Optional)"}
              multiline
              value={note}
              name={"note"}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <AppButton
            title={<div>Cancel</div>}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            color={"#000000D9"}
            width={100}
            height={40}
            className={"mt-5 mr-3"}
            onClick={handleReset}
          />
          <AppButton
            title={"Save"}
            disabled={
              !start_date ||
              !end_date ||
              !location ||
              !time ||
              !duration ||
              !frequency
            }
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={100}
            height={40}
            className={"mt-5"}
            onClick={handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default PracticeSchedule
