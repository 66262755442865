// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import { HomeHeader, AppButton, AppInput, Label, Tryout, PracticeSchedule } from "../../components"
import { Grid, Autocomplete as TextAutocomplete, TextField } from "@mui/material"
import { COLORS, MAP_API_KEY } from "../../constants"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"
import { useNavigate, useParams } from "react-router-dom"
import {
  createChannel,
  createOrganizationTeam,
  getAllCoaches,
  getOrganizationTeamById,
  searchUserProfile,
  updateChannel,
  updateOrganizationTeam
} from "../../api/auth"
import AppContext from "../../Context"
import Autocomplete from "react-google-autocomplete"

export default function AddTeam({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { user, organizationData, _getProfile } = useContext(AppContext)
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    team_name: "",
    coach_name: "",
    team_description: "",
    practice_location: "",
    max_roster_size: "",
    open_roster_space: "",
    loading: false,
    longitude: "",
    latitude: "",
    tryoutVisible: false,
    practiceScheduleVisible: false,
    teamID: '',
    coachList: [],
    coach_user_id: '',
    coach_name_custom: '',
    limit: 20,
    offset: 0,
    teamData: null
  })

  const {
    team_name,
    coach_name,
    team_description,
    practice_location,
    max_roster_size,
    open_roster_space,
    tryoutVisible, practiceScheduleVisible, teamID,
    loading,
    longitude,
    latitude,
    coachList,
    coach_user_id,
    coach_name_custom,
    limit,
    offset,
    teamData
  } = state

  const handleChange = (key, value, isEdit) => {
    if (!isEdit && key === "max_roster_size" && Number(value) < 0) {
      enqueueSnackbar("value must be positive", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      return
    }
    if (!isEdit && key === "open_roster_space") {
      if (Number(value) < 0) {
        enqueueSnackbar("value must be positive", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
        return
      }
      if (Number(value) > Number(max_roster_size)) {
        enqueueSnackbar(
          "Open roster space must be less than max roster size",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            }
          }
        )
        return
      }
    }
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    _getAllCoaches('?role=Coach')
    if (id) {
      _getTeamByID()
    }
  }, [id])

  const _getTeamByID = async () => {
    try {
      handleChange("loadingData", true)
      const teamData = await getOrganizationTeamById(id)
      console.log('teamData', teamData);
      handleChange("teamData", teamData?.data)
      handleChange("team_name", teamData?.data?.team_name)
      handleChange("coach_name", teamData?.data?.coach)
      handleChange("coach_name_custom", teamData?.data?.coach ? teamData?.data?.coaches?.user?.name || '' : teamData?.data?.coach_name || '')
      handleChange("team_description", teamData?.data?.team_description)
      handleChange("practice_location", teamData?.data?.practice_location)
      handleChange("max_roster_size", teamData?.data?.max_roster_size, true)
      // handleChange("open_roster_space", teamData?.data?.open_roster_space, true)
      handleChange("latitude", teamData?.data?.latitude)
      handleChange("longitude", teamData?.data?.longitude)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getAllCoaches = async (payload) => {
    try {
      const qs = payload ?? ''
      const res = await searchUserProfile(qs, token)
      handleChange("coachList", res?.data?.results ?? [])
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      let payload = {
        team_name,
        // coach_name,
        // coach_name_custom,
        team_description,
        practice_location,
        max_roster_size,
        // open_roster_space,
        longitude,
        latitude,
        organization: organizationData?.id
      }
      if (coach_name) {
        payload = {
          ...payload,
          coach: coach_name,
          coach_name: ''
        }
        // (payload['coach_name'] = "")
      }
      if (!coach_name && coach_name_custom) {
        payload = {
          ...payload,
          coach: null,
          coach_name: coach_name_custom
        }
      }
      if (id) {
        await updateOrganizationTeam(id, payload, token)
        // if (teamData?.coach !== coach_name){
        // if(coach_name){
        //   const payload = {
        //     channel: id,
        //     users: [Number(coach_name)]
        // }
        // const res = await addMemberChannel(payload)
        // }
        // if(teamData?.coach){
        // const payload2 = {
        //   channel: id,
        //   users: [teamData?.coach]
        // }
        // await leaveGroup(payload)
        // }
        // }
      } else {
        const res = await createOrganizationTeam(payload, token)
        const payload1 = {
          users: !!coach_user_id ? [Number(coach_user_id), user?.id] : [user?.id],
          group_name: team_name
        }
        const res2 = await updateChannel(res?.data?.chat_channel, payload1)
        // const res = await createChannel(payload1)
      }
      _getProfile()
      handleChange("loading", false)
      navigate("/teams")
      enqueueSnackbar(id ? "Team has been updated" : `Team has been created`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      console.log('error', error);
      handleChange("loading", false)
      // enqueueSnackbar(getError(error), {
      //   variant: "error",
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "right"
      //   }
      // })
    }
  }


  const handleSearch = async place => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address =
        res1?.results?.length > 0 && res1?.results[0]?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("latitude", place?.geometry.location?.lat())
      handleChange("longitude", place?.geometry.location?.lng())
      handleChange("practice_location", address)
    } catch (error) {
      alert(error?.message)
    }
  }

  const onInputChange = (event, value, reason) => {
    handleChange("coach_name_custom", value)
    handleChange("coach_name", '')
    if (value?.length > 2) {
      const payload = `?role=Coach&name=${value}`
      _getAllCoaches(payload)
    }
  }

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container width70 pb-3 mb-0 bg-transparent">
        <Grid className="mb-3" container justifyContent={"space-between"}>
          <div className="font-20 font-500">Create a team</div>
        </Grid>
        <Grid container spacing={3}>
          <Grid container spacing={3} item xs={12} md={8}>
            <Grid item xs={12} md={6}>
              <AppInput
                label={"Team Name"}
                height={40}
                onChange={handleChange}
                value={team_name}
                name={"team_name"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label text={"Coach Name"} fontNormal />
              <TextAutocomplete
                freeSolo
                fullWidth
                id="free-solo-2-demo"
                disableClearable
                onInputChange={(event, value) => {
                  if (value?.length > 2) {
                    const payload = `?role=Coach&search=${value}`
                    _getAllCoaches(payload)
                  }
                  handleChange("coach_name_custom", value)
                  handleChange("coach_name", '')
                  handleChange("coach_user_id", '')
                }}
                inputValue={coach_name_custom}
                value={coach_name_custom}
                label={false}
                options={coachList?.map(
                  option => `${option?.coach?.user?.name}`
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{ borderRadius: 10 }}
                  />
                )}
                sx={{
                  "& legend": { display: "none" },
                  "& .MuiInputLabel-shrink": {
                    opacity: 0,
                    transition: "all 0.2s ease-in"
                  },
                  "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#D9D9D9",
                    borderWidth: 1
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#D9D9D9"
                  },
                  "& fieldset": {
                    borderRadius: 2,
                    maxHeight: "40px",
                    marginTop: "5px"
                  }
                }}
                onChange={(event, newValue) => {
                  handleChange(
                    "coach_name",
                    coachList[event?.target?.dataset?.optionIndex]?.coach?.id
                  )
                  handleChange(
                    "coach_user_id",
                    coachList[event?.target?.dataset?.optionIndex]?.coach?.user?.id
                  )
                  handleChange("coach_name_custom", newValue)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AppInput
                label={"Team Description"}
                multiline
                height={80}
                onChange={handleChange}
                value={team_description}
                name={"team_description"}
              />
            </Grid>
            <Grid item xs={12}>
              <Label text={"Practice Location"} />
              <Autocomplete
                apiKey={MAP_API_KEY}
                className="locationSearch"
                options={{ types: ["geocode", "establishment"] }}
                style={{ height: 55, backgroundColor: "#fff" }}
                defaultValue={practice_location}
                onChange={e =>
                  handleChange("practice_location", e?.target?.value)
                }
                onPlaceSelected={place => {
                  handleSearch(place)
                }}
                placeholder=""
              />
              {/* <AppInput label={'Practice Location'} height={40} onChange={handleChange} value={practice_location} name={'practice_location'} /> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <AppInput
                label={"Max Roster Size"}
                height={40}
                type={"number"}
                onChange={handleChange}
                value={max_roster_size}
                name={"max_roster_size"}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <AppInput label={'Open Roster Spots'} height={40} type={'number'} onChange={handleChange} value={open_roster_space} name={'open_roster_space'} />
            </Grid> */}
            <Grid item xs={12} md={6}>
              {/* <Label text={"Team Schedule"} fontNormal /> */}
              <AppButton
                title={"Game Schedule"}
                color={COLORS.white}
                backgroundColor={COLORS.primary}
                width={"100%"}
                disabled={!id}
                onClick={() => {
                  navigate(`/game-schedule/${id}`)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Label text={"Team Try-out"} fontNormal /> */}
              <AppButton
                title={"Practice Schedule"}
                color={"#000000D9"}
                backgroundColor={COLORS.white}
                borderColor={'#D9D9D9'}
                width={"100%"}
                disabled={!id}
                onClick={() => {
                  if (id) {
                    handleChange("practiceScheduleVisible", true)
                    handleChange("teamID", id)
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }}>
          <AppButton
            title={id ? "Update a Team" : "Create a Team"}
            disabledColor={"#D9D9D9"}
            disabled={
              !team_name ||
              !team_description ||
              (!coach_name && !coach_name_custom) ||
              !practice_location ||
              !max_roster_size
            }
            color={COLORS.white}
            fontWeight={500}
            backgroundColor={COLORS.primary}
            width={150}
            onClick={handleSubmit}
            loading={loading}
          />
        </Grid>
      </section>
      <Tryout
        visible={tryoutVisible}
        teamID={teamID}
        handleClose={() => {
          handleChange("tryoutVisible", false)
          handleChange("teamID", '')
        }}
      />
      <PracticeSchedule
        visible={practiceScheduleVisible}
        teamID={teamID}
        handleClose={() => {
          handleChange("practiceScheduleVisible", false)
          handleChange("teamID", '')
        }}
      />
    </div>
  )
}
