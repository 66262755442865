// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeHeader,
  AppButton,
  AddPost,
  AppInput,
  Post,
  AddStats
} from "../../components"
import { Element } from "react-scroll"
import { Divider, Grid } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import Postimage from "../../assets/images/dashboard.jpg"
import { ReactComponent as LocationOnGrey } from "../../assets/svg/location_on_grey.svg"
import { ReactComponent as LikeIcon } from "../../assets/svg/Like.svg"
import { ReactComponent as MessageIcon } from "../../assets/svg/Message.svg"
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg"
import { ReactComponent as InstaIcon } from "../../assets/svg/insta.svg"
import { ReactComponent as SettingWhiteIcon } from "../../assets/svg/settingWhite.svg"
import { ReactComponent as SendIcon } from "../../assets/svg/Send.svg"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import AddIcon from "@mui/icons-material/Add"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  acceptLink,
  addComments,
  declineLink,
  getAllPosts,
  getChannels,
  getComments,
  postLike,
  searchUserProfile
} from "../../api/auth"
import { getError } from "../../utils/getError"
import LinkPlayer from "../../components/GeneralModal/LinkPlayer"
import { getAllMentionUsers, getUsers } from "../../api/admin"
import moment from "moment"
import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar.svg"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function Dashboard({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const guestRef = useRef(null)
  const navigate = useNavigate()
  // for link action
  const { actionType } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const linkId = queryParams.get("link_id")
  const local_linkId = localStorage.getItem("link_id")
  const local_actionType = localStorage.getItem("action-type")
  const {
    fetchPost,
    setFetchPost,
    userProfile,
    user,
    userData,
    organizationData,
    hashtagsText,
    _upcomingEvents,
    allUpcomingEvents
  } = useContext(AppContext)
  console.log(allUpcomingEvents, "allUpcomingEvents")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const [state, setState] = useState({
    posts: [],
    page: 0,
    limit: 50,
    showComment: "",
    videoPlaying: "",
    linkVisible: false,
    allPlayers: [],
    allUsers: [],
    mentionUsers: [],
    linkedPlayers: [],
    statsVisible: false,
    playerData: {},
    showAllLinkedPlayers: false,
    scrollVisible: false,
    allChannels: []
  })
  const {
    posts,
    allUsers,
    page,
    limit,
    showComment,
    videoPlaying,
    linkVisible,
    mentionUsers,
    allPlayers,
    linkedPlayers,
    statsVisible,
    playerData,
    showAllLinkedPlayers,
    allChannels,
    scrollVisible
  } = state
  const token = localStorage.getItem("token")
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      handleChange('scrollVisible', true)
    }
    else if (scrolled <= 300) {
      handleChange('scrollVisible', false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  useEffect(() => {
    getData(true)
    if (user?.role === "Parent") {
      getLinkedPlayers()
      // getAllPlayers()
    } else if (user?.role === "Player") {
      getLinkedPlayers()
      // getAllPlayers()
    } else if (user?.role === "Organization") {
      _upcomingEvents()
      // getAllPlayers()
    }
  }, [fetchPost, linkVisible, user?.role])
  useEffect(() => {
    if (hashtagsText) {
      getData(true, 0, hashtagsText)
    }
  }, [hashtagsText])

  useEffect(() => {
    getData(true)
    _getChannels()
    //getAllChats()
    if (fetchPost) {
      getData(true)
      setFetchPost(false)
    }
  }, [fetchPost, linkVisible])

  useEffect(() => {
    if (actionType === "accept-link" || local_actionType === "accept-link") {
      acceptLinkAction(Number(linkId || local_linkId))
    } else if (
      actionType === "decline-link" ||
      local_actionType === "decline-link"
    ) {
      declineLinkAction(Number(linkId || local_linkId))
    }
  }, [actionType, local_actionType])

  const acceptLinkAction = async linkId => {
    try {
      handleChange("loading", true)
      const payload = {
        link_id: linkId
      }
      const res = await acceptLink(payload, token)
      handleChange("loading", false)
      enqueueSnackbar(`Parent linking successful`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })

      localStorage.removeItem("link_id")
      localStorage.removeItem("action-type")
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const declineLinkAction = async linkId => {
    try {
      handleChange("loading", true)
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      handleChange("loading", false)
      enqueueSnackbar(`Parent linking denied`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      localStorage.removeItem("link_id")
      localStorage.removeItem("action-type")
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getData = async (resetPage, paged) => {
    console.log('hashtagsText', hashtagsText);

    const payload = `?limit=${limit}&offset=${resetPage ? 0 : paged}&hashtags=${hashtagsText?.substring(1)}`
    await _getAllPosts(payload, resetPage)
    await _getAllMentionUsers()
    await _getAllSharedUsers()
  }

  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const queryParams = payload ? payload : ""
      const token = localStorage.getItem("token")
      const res = await getAllPosts(queryParams, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const _getComments = async (post, index) => {
    try {
      handleChange("loading", true)
      const payload = `?post__id=${post}`
      const token = localStorage.getItem("token")
      const res = await getComments(payload, token)
      handleChange(`comment${index}list`, res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }

  const clickNext = newPage => {
    getData(false, newPage)
    handleChange("page", newPage)
  }
  const handleCloseLink = () => {
    handleChange("linkVisible", false)
  }
  const handleOpenLink = type => {
    handleChange("linkVisible", true)
  }

  // const getAllPlayers = async () => {
  //   try {
  //     handleChange("loading", true)
  //     const res = await searchUserProfile(
  //       "?limit=1000&offset=20&role=Player",
  //       token
  //     )
  //     console.log(res, "user all profile")
  //     handleChange("allPlayers", res?.data?.results)
  //     handleChange("loading", false)
  //   } catch (error) {
  //     handleChange("loading", false)
  //     enqueueSnackbar(getError(error), {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right"
  //       }
  //     })
  //   }
  // }
  const _getAllMentionUsers = async () => {
    try {
      const res = await getAllMentionUsers(token)
      console.log(res, "user all profile")
      handleChange("mentionUsers", res?.data)
      localStorage.setItem("allUsers", JSON.stringify(res?.data))
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        console.log(res, "user all profile")
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const getLinkedPlayers = async () => {
    try {
      handleChange("loading", true)
      const res = await searchUserProfile("linked_relationships/", token)
      console.log(res, "linked player relation")
      handleChange("linkedPlayers", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("playerData", {})
    if (getTeam) {
      // getData(0)
    }
  }
  const isValidUrl = str => {
    // Regular expression to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g
    // Test if the string contains a URL
    return urlPattern.test(str)
  }

  // Function to extract URLs from a string
  const extractUrls = str => {
    // Regular expression to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g
    // Extract URLs from the string and return them as an array
    return str.match(urlPattern) || []
  }

  const playerStatOption = linkedPlayers
    ?.filter(item => item?.is_confirmed)
    ?.map((v, i) => {
      return (
        <option key={i} value={v?.player?.user?.name}>
          {v?.player?.user?.name}
        </option>
      )
    })
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const fullName = user?.first_name + " " + user?.last_name
  console.log(organizationData, "organizationData")
  const parseHTMLContent = htmlString => {
    console.log(htmlString, "htmlString")
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, "text/html")

    const result = {}

    doc.querySelectorAll("p").forEach(p => {
      const [key, ...valueParts] = p.textContent.split(":")
      const value = valueParts.join(":").trim()
      if (key && value) {
        result[key.trim()] = value
      }
    })

    const paymentLinkElement = doc.querySelector("p a")
    if (paymentLinkElement) {
      result["Payment Link"] = paymentLinkElement.href
    }

    return result
  }
  return (
    <div className="bg-white">
      <HomeHeader dashboard data={posts} />
      <Element name="Element1" className="element">
        <section className="container">
          <div className="container d-flex  justify-content-center align-items-center">
            <Grid container justifyContent={"center"}>
              <Grid container justifyContent={"center"}>
                <div className="width100">
                  <p className={"JOINTHE font-36"}>
                    <span className="greyColor">
                      Welcome,
                      <span className="mx-2" style={{ color: "#004694" }}>
                        {" "}
                        {user?.role == "Organization" &&
                          organizationData?.organization_name != null
                          ? organizationData?.organization_name
                          : userData?.name ||
                          user?.name ||
                          userProfile?.name ||
                          user?.email.split("@")[0] ||
                          "Guest"}
                      </span>
                    </span>
                  </p>
                  <Grid container spacing={3} className="mt-0">
                    <Grid item xs={12} md={8}>
                      <div className="greenBox">
                        <div className="text-white font-bold">
                          Are you on a team?
                        </div>
                        <div className="text-white">
                          Anyone can start and connect a new team! The Sports Portal is great for any sports group and
                          organization
                        </div>
                      </div>
                      <Grid item xs={12} md={4} className="upcomingSectionMobile">
                        {user?.role === "Organization" && allUpcomingEvents?.length > 0 && (
                          <div className="memberGreyBox">
                            <div className="text-black font-bold font-20">
                              {user?.role === "Parent" ? "Members" : "Up Coming"}
                            </div>
                            {allUpcomingEvents?.map((member, index) => (
                              <div className="memberBox" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <CalendarIcon style={{ width: 30, height: 30 }} className="mr-2" />
                                    <div className="">
                                      <div className="font-bold text-black font-14">
                                        {member?.event_type === "try_out" ? "Try Outs" : member?.event_type === "practice_schedule" ? "Practice Schedule" : "Game Schedule"}
                                        {
                                          ` (${moment(
                                            (member?.matched_date) + " " + (member?.event_type === "schedule"
                                              ? member?.game_time :
                                              member?.event_type === "practice_schedule"
                                                ? member?.time
                                                : member?.arrival_time)
                                          ).format("MM-DD, hh:MM A")})`
                                        }
                                      </div>
                                      <div className="lightgreyColor font-12">
                                        {member?.location}
                                      </div>
                                    </div>
                                  </div>
                                  <span onClick={() => navigate("/calendar")} className="ml-2 mt-2 cursor text-black">
                                    View
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {user?.role === "Parent" && (
                          <div
                            className="memberGreyBox"
                            style={{ padding: "20px 15px" }}
                          >
                            <div
                              className="flex-between"
                              style={{ marginBottom: "1.5rem" }}
                            >
                              <div className="text-black font-bold font-20">
                                {user?.role === "Parent"
                                  ? "Linked Players"
                                  : "Up Coming"}
                              </div>
                              {!showAllLinkedPlayers ? (
                                <div
                                  className="text_blue_link font-14 cursor"
                                  onClick={() =>
                                    handleChange("showAllLinkedPlayers", true)
                                  }
                                >
                                  View All
                                </div>
                              ) : (
                                <div
                                  className="text_blue_link font-14 cursor"
                                  onClick={() =>
                                    handleChange("showAllLinkedPlayers", false)
                                  }
                                >
                                  View Less
                                </div>
                              )}
                            </div>
                            {showAllLinkedPlayers
                              ? linkedPlayers?.map((member, index) => (
                                <div className="memberBox" key={index}>
                                  <div
                                    className="flex-between"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="d-flex">
                                      <img
                                        src={
                                          member?.player?.picture || AvatarIcon
                                        }
                                        className={"c-pointer memberprofile"}
                                      />
                                      <div>
                                        <div
                                          className="font-bold text-black font-14 d-flex"
                                          style={{ gap: 3 }}
                                        >
                                          {member?.player?.user?.name}
                                          {!member?.is_confirmed ? (
                                            <div className="request_pending font-normal font-12 text-center px-1">
                                              Request Pending
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="lightgreyColor font-12">
                                          {
                                            member?.player
                                              ?.committed_to_organization
                                              ?.organization_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleChange("statsVisible", true)
                                        handleChange("playerData", {
                                          id: member?.player?.id,
                                          name: member?.player?.user?.name
                                        })
                                      }}
                                    >
                                      {user?.role === "Parent" && (
                                        <div
                                          className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                            "disabled-link"
                                            }`}
                                        >
                                          Add Stats
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                              : linkedPlayers
                                ?.slice(0, 4)
                                .map((member, index) => (
                                  <div className="memberBox" key={index}>
                                    <div
                                      className="flex-between"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            member?.player?.picture ||
                                            AvatarIcon
                                          }
                                          className={"c-pointer memberprofile"}
                                        />
                                        <div>
                                          <div
                                            className="font-bold text-black font-14 d-flex"
                                            style={{ gap: 3 }}
                                          >
                                            {member?.player?.user?.name}
                                            {!member?.is_confirmed ? (
                                              <div className="request_pending font-normal font-12 text-center px-1">
                                                Request Pending
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="lightgreyColor font-12">
                                            {
                                              member?.player
                                                ?.committed_to_organization
                                                ?.organization_name
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        onClick={() => {
                                          handleChange("statsVisible", true)
                                          handleChange("playerData", {
                                            id: member?.player?.id,
                                            name: member?.player?.user?.name
                                          })
                                        }}
                                      >
                                        {user?.role === "Parent" && (
                                          <div
                                            className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                              "disabled-link"
                                              }`}
                                          >
                                            Add Stat
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            <div className="member_dotted_Box">
                              <div
                                className="flex-between font-16 font-medium cursor"
                                style={{ justifyContent: "flex-start", gap: 10 }}
                                onClick={() => handleOpenLink("link")}
                              >
                                <div className="add_bg">
                                  <AddIcon fontSize="16" />
                                </div>
                                <div>Link New Player</div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="pinkBox">
                          <Grid className="mt-3">
                            <div className="">
                              <div className="adButton">
                                <div className="text-white">ad</div>
                              </div>
                              <div className="text-white font-20 mb-1">
                                The Sports Portal Pulse
                              </div>
                              <div className="text-white font-12">
                                This dynamic section brings you the hottest hashtags, the most talked-about athletes, and the latest sports topics that are setting the pace in the sports universe.
                              </div>
                              <Grid
                                container
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                className="mt-2"
                              >
                                <div />
                                {/* <div className="LearnMore">Learn More</div> */}
                                <div className="removeText">Remove ads</div>
                              </Grid>
                            </div>
                          </Grid>
                        </div>
                      </Grid>
                      <Divider className="mt-4" />
                      {posts?.map((post, index) => {
                        const textTOHTML = parseHTMLContent(post.text)
                        console.log(textTOHTML, "text")
                        // const lines = post?.text
                        //   .trim()
                        //   .split("<div>/n")
                        //   .map(line => line.trim())
                        // console.log("lines ==>", lines)
                        // const textTOHTML = {}
                        // lines.forEach(line => {
                        //   const [key, value] = line
                        //     .split(":")
                        //     .map(part => part.trim())
                        //   if (key && value) {
                        //     textTOHTML[key.toLowerCase()] = value
                        //   }
                        // })
                        console.log(textTOHTML, "jsonObject")

                        return (
                          <>
                            <Post
                              key={index}
                              state={state}
                              getData={getData}
                              showComment={showComment}
                              handleChange={handleChange}
                              post={post}
                              videoPlaying={videoPlaying}
                              index={index}
                              allPlayers={allPlayers}
                              allUsers={allUsers}
                              getUserLoader={getUserLoader}
                              allChannels={allChannels}
                              mentionUsers={mentionUsers}
                              textTOHTML={textTOHTML}
                            />
                          </>
                        )
                      })}
                    </Grid>
                    <Grid item xs={12} md={4} className="upcomingSection">
                      {user?.role === "Organization" && allUpcomingEvents?.length > 0 && (
                        <div className="memberGreyBox">
                          <div className="text-black font-bold font-20">
                            {user?.role === "Parent" ? "Members" : "Up Coming"}
                          </div>
                          {allUpcomingEvents?.map((member, index) => (
                            <div className="memberBox" key={index}>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <CalendarIcon style={{ width: 30, height: 30 }} className="mr-2" />
                                  <div className="">
                                    <div className="font-bold text-black font-14">
                                      {member?.event_type === "try_out" ? "Try Outs" : member?.event_type === "practice_schedule" ? "Practice Schedule" : "Game Schedule"}
                                      {
                                        ` (${moment(
                                          (member?.matched_date) + " " + (member?.event_type === "schedule"
                                            ? member?.game_time :
                                            member?.event_type === "practice_schedule"
                                              ? member?.time
                                              : member?.arrival_time)
                                        ).format("MM-DD, hh:MM A")})`
                                      }
                                    </div>
                                    <div className="lightgreyColor font-12">
                                      {member?.location}
                                    </div>
                                  </div>
                                </div>
                                <span onClick={() => navigate("/calendar")} className="ml-2 mt-2 cursor text-black">
                                  View
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {user?.role === "Parent" && (
                        <div
                          className="memberGreyBox"
                          style={{ padding: "20px 15px" }}
                        >
                          <div
                            className="flex-between"
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div className="text-black font-bold font-20">
                              {user?.role === "Parent"
                                ? "Linked Players"
                                : "Up Coming"}
                            </div>
                            {!showAllLinkedPlayers ? (
                              <div
                                className="text_blue_link font-14 cursor"
                                onClick={() =>
                                  handleChange("showAllLinkedPlayers", true)
                                }
                              >
                                View All
                              </div>
                            ) : (
                              <div
                                className="text_blue_link font-14 cursor"
                                onClick={() =>
                                  handleChange("showAllLinkedPlayers", false)
                                }
                              >
                                View Less
                              </div>
                            )}
                          </div>
                          {showAllLinkedPlayers
                            ? linkedPlayers?.map((member, index) => (
                              <div className="memberBox" key={index}>
                                <div
                                  className="flex-between"
                                  style={{ width: "100%" }}
                                >
                                  <div className="d-flex">
                                    <img
                                      src={
                                        member?.player?.picture || AvatarIcon
                                      }
                                      className={"c-pointer memberprofile"}
                                    />
                                    <div>
                                      <div
                                        className="font-bold text-black font-14 d-flex"
                                        style={{ gap: 3 }}
                                      >
                                        {member?.player?.user?.name}
                                        {!member?.is_confirmed ? (
                                          <div className="request_pending font-normal font-12 text-center px-1">
                                            Request Pending
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="lightgreyColor font-12">
                                        {
                                          member?.player
                                            ?.committed_to_organization
                                            ?.organization_name
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    onClick={() => {
                                      handleChange("statsVisible", true)
                                      handleChange("playerData", {
                                        id: member?.player?.id,
                                        name: member?.player?.user?.name
                                      })
                                    }}
                                  >
                                    {user?.role === "Parent" && (
                                      <div
                                        className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                          "disabled-link"
                                          }`}
                                      >
                                        Add Stats
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                            : linkedPlayers
                              ?.slice(0, 4)
                              .map((member, index) => (
                                <div className="memberBox" key={index}>
                                  <div
                                    className="flex-between"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="d-flex">
                                      <img
                                        src={
                                          member?.player?.picture ||
                                          AvatarIcon
                                        }
                                        className={"c-pointer memberprofile"}
                                      />
                                      <div>
                                        <div
                                          className="font-bold text-black font-14 d-flex"
                                          style={{ gap: 3 }}
                                        >
                                          {member?.player?.user?.name}
                                          {!member?.is_confirmed ? (
                                            <div className="request_pending font-normal font-12 text-center px-1">
                                              Request Pending
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="lightgreyColor font-12">
                                          {
                                            member?.player
                                              ?.committed_to_organization
                                              ?.organization_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        handleChange("statsVisible", true)
                                        handleChange("playerData", {
                                          id: member?.player?.id,
                                          name: member?.player?.user?.name
                                        })
                                      }}
                                    >
                                      {user?.role === "Parent" && (
                                        <div
                                          className={`text_blue_link font-14 cursor  ${!member.is_confirmed &&
                                            "disabled-link"
                                            }`}
                                        >
                                          Add Stat
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          <div className="member_dotted_Box">
                            <div
                              className="flex-between font-16 font-medium cursor"
                              style={{ justifyContent: "flex-start", gap: 10 }}
                              onClick={() => handleOpenLink("link")}
                            >
                              <div className="add_bg">
                                <AddIcon fontSize="16" />
                              </div>
                              <div>Link New Player</div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="pinkBox">
                        <Grid className="mt-3">
                          <div className="">
                            <div className="adButton">
                              <div className="text-white">ad</div>
                            </div>
                            <div className="text-white font-20 mb-1">
                              The Sports Portal Pulse
                            </div>
                            <div className="text-white font-12">
                              This dynamic section brings you the hottest hashtags, the most talked-about athletes, and the latest sports topics that are setting the pace in the sports universe.
                            </div>
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              className="mt-2"
                            >
                              <div />
                              {/* <div className="LearnMore">Learn More</div> */}
                              <div className="removeText">Remove ads</div>
                            </Grid>
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          {
            scrollVisible &&
            <div className="scrollArrowUp">
              <AppButton
                backgroundColor={'#0059C1'}
                width={130}
                height={40}
                minWidth={40}
                borderRadius={15}
                noMarginText={true}
                title={
                  <div className="backtoTopDiv">
                    <ArrowUpwardIcon style={{ color: "#fff", width: 30 }} />
                    <span className="backtoTop">Back to top</span>
                  </div>
                }
                onClick={scrollToTop}
              />
            </div>
          }
        </section>
        <LinkPlayer
          visible={linkVisible}
          handleClose={handleCloseLink}
          data={allPlayers}
        />
        <AddStats
          visible={statsVisible}
          playerId={playerData}
          handleClose={handleClose}
        // playerStatOption={playerStatOption}
        />
      </Element>
    </div>
  )
}
