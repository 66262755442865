import React, { useContext, useEffect, useRef, useState } from "react"
import { AppButton, AppInput, HomeHeader, Label, Loader } from "../../components"
import { useNavigate, useParams } from "react-router-dom"
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { COLORS } from "../../constants"
import AppContext from "../../Context"
import {
  addPotentialPlayer,
  addTeamPlayer,
  searchUserProfile
} from "../../api/auth"
import { getError } from "../../utils/getError"
import { useSnackbar } from "notistack"

const AddPlayer = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [options, setOptions] = useState([])
  const previousController = useRef()
  const {
    positionList: positions,
    _getPositions,
    grades
  } = useContext(AppContext)
  const [state, setState] = useState({
    name: null,
    email: "",
    phone_number: "",
    position: "",
    grade: "",
    loading: false,
    btnLoading: false,
    userList: [],
    customName: ""
  })
  const {
    name,
    email,
    phone_number,
    position,
    grade,
    loading,
    userList,
    btnLoading,
    customName
  } = state
  useEffect(() => {
    _getPositions(`?sport=1`)
  }, [])

  const _getProfile = async searchText => {
    try {
      if (previousController.current) {
        previousController.current.abort()
      }
      const controller = new AbortController()
      const signal = controller.signal
      previousController.current = controller
      handleChange("searchText", searchText)
      const token = localStorage.getItem("token")
      if (searchText) {
        const res = await searchUserProfile(
          `?search=${searchText}&role=Player`,
          token
        )
        handleChange(
          "userList",
          res?.data?.results.map(p => {
            return { title: p.name, value: p.player.id, ...p }
          })
        )
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const onInputChange = (event, value, reason) => {
    handleChange("customName", value)
    if (value) {
      _getProfile(value)
    } else {
      setOptions([])
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const positionOptionsLabel = (
    <option value={""} disabled>
      Select Position
    </option>
  )

  const positionList = positions?.map((item, index) => (
    <option key={`${index}-position`} value={item?.name}>
      {item?.name}
    </option>
  ))
  const positionOptions = [positionOptionsLabel, ...positionList]

  const gradeOptionsLabel = (
    <option value={""} disabled>
      Select Grade
    </option>
  )

  const gradeList = grades?.map((item, index) => (
    <option key={`${index}-grade`} value={item?.name}>
      {item?.name}
    </option>
  ))
  const gradeOptions = [gradeOptionsLabel, ...gradeList]

  useEffect(() => {
    if (name) {
      handleChange("email", name?.email)
      handleChange("phone_number", name?.phone_number)
      handleChange(
        "position",
        name?.player?.first_position_play?.name ||
          name?.player?.second_position_play?.name
      )
      handleChange("grade", name?.player?.grade?.name || null)
    }
  }, [name])

  const _addPlayer = async () => {
    try {
      handleChange("btnLoading", true)
      const token = localStorage.getItem("token")

      const reqObj = {
        email,
        phone_number,
        primary_position: position,
        grade,
        organization: id
      }

      console.log(reqObj, "reqObj")

      if (name?.value) {
        reqObj.player = name?.value
      } else {
        reqObj.name = customName
      }

      await addPotentialPlayer(reqObj, token)
      handleChange("btnLoading", false)
      handleChange("email", "")
      handleChange("phone_number", "")
      handleChange("position", "")
      handleChange("grade", "")
      handleChange("name", null)
      handleChange("customName", "")

      enqueueSnackbar(`User has been added successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      console.log(error, "ERROR")
      handleChange("btnLoading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <section
              className="container p-0 pt-3 bg-transparent"
              style={{ width: 450, height: "100%" }}
            >
              <Grid
                container
                spacing={3}
                sx={{ mt: -1, mb: 3 }}
                alignItems={"center"}
              >
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    size=""
                    sx={{
                      bgcolor: COLORS.white,
                      borderRadius: 2,
                      color: "#222"
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon color="#222" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <p className="font-medium font-18">Add Player</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Label fontNormal text={"Name"} />
                  <Autocomplete
                    freeSolo
                    fullWidth
                    id="combo-box-demo"
                    options={userList}
                    onInputChange={onInputChange}
                    inputValue={customName}
                    getOptionLabel={option => {
                      return option.title
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        style={{ borderRadius: 10 }}
                      />
                    )}
                    sx={{
                      "& legend": { display: "none" },
                      "& .MuiInputLabel-shrink": {
                        opacity: 0,
                        transition: "all 0.2s ease-in"
                      },
                      "& fieldset": { borderRadius: 2 }
                    }}
                    onChange={(e, newValue) => handleChange("name", newValue)}
                    name={"name"}
                    value={name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AppInput
                    label={"Email"}
                    height={40}
                    onChange={handleChange}
                    value={email}
                    name={"email"}
                    disabled={name?.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppInput
                    label={"Phone Number"}
                    height={40}
                    onChange={handleChange}
                    value={phone_number}
                    name={"phone_number"}
                    placeholder={"123-456-7890"}
                    type={"number"}
                    disabled={name?.phone_number}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppInput
                    label={"Position"}
                    select
                    selectOptions={positionOptions}
                    height={40}
                    onChange={handleChange}
                    value={position?.id || position}
                    name={"position"}
                    disabled={name?.position}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AppInput
                    label={"Grade"}
                    select
                    selectOptions={gradeOptions}
                    height={40}
                    onChange={handleChange}
                    value={grade?.id || grade}
                    name={"grade"}
                    disabled={name?.grade}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent={"flex-end"}
                    sx={{ mt: 2, mb: 2 }}
                  >
                    <Grid item>
                      <AppButton
                        title={"Cancel"}
                        color={"#000000D9"}
                        fontWeight={400}
                        backgroundColor={"#FFFFFF"}
                        height={35}
                        width={"75px"}
                        borderColor={"#d9d9d9"}
                        borderWidth={1}
                        onClick={() => navigate(-1)}
                      />
                    </Grid>
                    <Grid item>
                      <AppButton
                        title={"Save"}
                        backgroundColor={COLORS.primary}
                        color={COLORS.white}
                        fontWeight={400}
                        height={35}
                        width={"80px"}
                        disabled={
                          (!name?.id && !customName) || !grade
                          // (!name?.id && !customName) || !grade || !phone_number
                        }
                        onClick={_addPlayer}
                        loading={btnLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </section>
          </div>
        </>
      )}
    </div>
  )
}

export default AddPlayer
