import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import axios from "axios"
import { debounce } from "lodash" // Import debounce function from lodash
import { AppButton, AppInput } from ".."
import { styled } from "@mui/material/styles"
import { Box, Button, CircularProgress, Grid, Modal } from "@mui/material"
import { COLORS } from "../../constants"
import avatar from "../../assets/images/avatar.png"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ShareLink } from "../../assets/svg/share_link.svg"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import FolderIcon from "@mui/icons-material/Folder"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import {
  createChannel,
  getLike,
  getOrganizationTeam,
  publishMessage,
  searchUserProfile,
  sendFeedback
} from "../../api/auth"
import { useSnackbar } from "notistack"
import SendIcon from "@mui/icons-material/Send"
import Stack from "@mui/material/Stack"
import Checkbox from "@mui/material/Checkbox"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import AppContext from "../../Context"
import { getError } from "../../utils/getError"
import { getUsers } from "../../api/admin"
import { API_URL, STAGING_BASE_URL, defaultConfig } from "../../api/config"
function generate(element) {
  return [0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value
    })
  )
}
const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}))
export default function UserShareModal({
  visible,
  handleClose,
  post,
  allUsers,
  getUserLoader,
  allChannels
}) {
  const token = localStorage.getItem("token")
  const [state, setState] = useState({
    group_name: "",
    loadingChat: false,
    list: [],
    filteredList: [],
    users: [],
    members: [],
    loadingUsers: false
  })
  const {
    group_name,
    filteredList,
    list,
    loadingChat,
    users,
    members,
    loadingUsers
  } = state
  const [dense, setDense] = React.useState(false)
  const [sendMessageLoader, setSendMessageLoader] = useState(false)
  const [secondary, setSecondary] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 650,
    borderRadius: 6,
    bgcolor: "background.paper",
    boxShadow: 24,
    py: 4,
    px: 2
  }
  const [loading, setLoading] = useState()
  const { user } = useContext(AppContext)
  // console.log(user, "user");
  const label = { inputProps: { "aria-label": "Checkbox demo" } }

  // Assuming userId is initially an empty array
  const [selectedIds, setSelectedIds] = useState([])
  console.log(selectedIds, "selectedIds")
  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [selectedIds[0], user?.id]
      }
      const res = await createChannel(payload)
      console.log("createChannel", res?.data)
      return res
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const updateFilteredList = (users, channels) => {
    const updatedUsers = users?.map(user => {
      const channelWithUser = channels.find(channel =>
        channel?.users?.some(u => u.id === user?.id)
      )
      const channel_id = channelWithUser ? channelWithUser.id : ""
      return {
        ...user,
        channel_id
      }
    })

    const updatedUsersWithEmptyChannelId = updatedUsers?.map(user => ({
      ...user,
      channel_id: user.channel_id || ""
    }))

    console.log(
      updatedUsersWithEmptyChannelId,
      "updatedUsersWithEmptyChannelId"
    )

    return updatedUsersWithEmptyChannelId
  }

  useEffect(() => {
    const updatedFilteredList = updateFilteredList(allUsers, allChannels)
    setState(prevState => ({
      ...prevState,
      filteredList: updatedFilteredList
    }))
  }, [allUsers, allChannels])

  const sendMessage = async () => {
    setSendMessageLoader(true)
    try {
      const urlString = window.location.href
      const urlObject = new URL(urlString)
      const baseURL = `${urlObject.protocol}//${urlObject.hostname}:${urlObject.port}/post/${post?.id}`

      // Create a new list to store both new and existing channel IDs
      const channelIdsList = []

      for (const obj of selectedIds) {
        if (obj.channel_id === null) {
          const channelPayload = {
            users: [obj.user_id, user?.id]
          }

          // Create channel for current object
          const channelRes = await createChannel(channelPayload)
          console.log("createChannel", channelRes?.data?.id)

          // Check if channel creation was successful
          if (channelRes?.data?.status === "ERROR") {
            alert(channelRes?.data?.message)
            return
          }

          // Add the new channel ID to the list
          channelIdsList.push(channelRes?.data?.id)
        } else if (obj.channel_id) {
          // Add existing channel ID to the list
          channelIdsList.push(obj.channel_id)
        }
      }

      // Now you have a list containing both new and existing channel IDs
      console.log("Channel IDs List:", channelIdsList)

      // Create FormData object
      const formData = new FormData()
      for (const channelId of channelIdsList) {
        formData.append("channel", channelId)
        formData.append("text", baseURL)

        // Send FormData object to server
        const res = await publishMessage(formData, token)
        setSendMessageLoader(false)
        console.log("res", res)

        // Handle response
        if (res?.data?.status === "ERROR") {
          alert(res?.data?.message)
          return
        }
      }
      if (sendMessageLoader === false) {
        // Reset state after sending messages
        handleClose(false)
        setSelectedIds([])
        setState({
          filteredList: allUsers
        })

        // Show success message
        enqueueSnackbar(`Messages have been sent`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      setSendMessageLoader(false)
      console.error("Error sending message:", error)
    }
  }

  // const handleCheckboxChange = (isChecked, { user_id, channel_id }) => {
  //   console.log(user_id, channel_id, "user_id, channel_id");

  //   // Iterate over allChannels array to find a matching user.id
  //   allChannels.forEach((channel) => {
  //     channel.users.forEach((user) => {
  //       if (user.id === user_id) {
  //         // If a match is found, assign the channel id to channel_id
  //         channel_id = channel.id;
  //       }
  //     });
  //   });

  //   if (isChecked.target.checked) {
  //     // If checkbox is checked, add the id to the selectedIds array
  //     setSelectedIds((prevIds) => [...prevIds, { user_id, channel_id }]);
  //   } else {
  //     // If checkbox is unchecked, remove the id from the selectedIds array
  //     setSelectedIds((prevIds) =>
  //       prevIds.filter(
  //         (prevId) =>
  //           prevId.user_id !== user_id || prevId.channel_id !== channel_id
  //       )
  //     );
  //   }
  // };
  // const handleCheckboxChange = (isChecked, { user_id, channel_id }) => {
  //   console.log(user_id, channel_id, "user_id, channel_id");
  //   // console.log("isChecked ===>", isChecked);

  //   let addToSelectedIds = true;
  //   const isCheckedState = isChecked.target.checked;

  //   allChannels.forEach((channel) => {
  //     channel.users.forEach((user) => {
  //       if (user.id === user_id) {
  //         channel_id = channel.id;
  //         if (selectedIds.some((item) => item.channel_id === channel_id)) {
  //           addToSelectedIds = false;
  //         }
  //       }
  //     });
  //   });

  //   if (isChecked.target.checked && addToSelectedIds) {
  //     // If checkbox is checked and addToSelectedIds is true, add the id to the selectedIds array
  //     if (channel_id) {
  //       // If channel_id exists, add it
  //       setSelectedIds((prevIds) => [...prevIds, { channel_id }]);
  //     } else {
  //       // If channel_id doesn't exist, add the { user_id: user_id } pair
  //       setSelectedIds((prevIds) => [...prevIds, { user_id: user_id }]);
  //     }
  //   } else {
  //     // If checkbox is unchecked or addToSelectedIds is false, remove the id from the selectedIds array
  //     if (isCheckedState) {
  //       // If the checkbox is unchecked, remove user_id and channel_id
  //       setSelectedIds((prevIds) =>
  //         prevIds.filter(
  //           (prevId) =>
  //             prevId.user_id !== user_id || prevId.channel_id !== channel_id
  //         )
  //       );
  //     } else {
  //       // If the checkbox is checked but addToSelectedIds is false, remove user_id and channel_id
  //       setSelectedIds((prevIds) =>
  //         prevIds.filter(
  //           (prevId) =>
  //             prevId.user_id !== user_id && prevId.channel_id !== channel_id
  //         )
  //       );
  //     }
  //   }
  // };

  const handleCheckboxChange = (isChecked, { user_id, channel_id }) => {
    const isCheckedState = isChecked.target.checked
    let addToSelectedIds = true
    allChannels.forEach(channel => {
      channel.users.forEach(user => {
        if (user.id === user_id) {
          channel_id = channel.id
          if (selectedIds?.some(item => item.channel_id === channel_id)) {
            addToSelectedIds = false
          }
        }
      })
    })

    setSelectedIds(prevIds => {
      if (isCheckedState) {
        // If the checkbox is checked, add the user_id or channel_id to the selectedIds array
        return [
          ...prevIds,
          {
            user_id: user_id,
            channel_id: channel_id
          }
        ]
      } else {
        // If the checkbox is unchecked, remove the user_id or channel_id from the selectedIds array
        return prevIds.filter(idObj =>
          user_id ? idObj.user_id !== user_id : idObj.channel_id !== channel_id
        )
      }
    })
  }

  // onClick handler function for the copy button
  const handleCopyClick = copyText => {
    console.log(copyText, "copyText=====")
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const [isCopied, setIsCopied] = useState(false)

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const filtered = async value => {
    console.log(value?.length, "value?.length")
    if (!value) {
      // Check if value is falsy (includes empty string and null)
      handleChange("filteredList", allUsers)
    } else {
      setLoading(true)
      try {
        const response = await fetch(
          `${defaultConfig.API_URL}/api/v1/users/search/?search=${encodeURIComponent(value)}`,
          {
            headers: {
              Authorization: `token ${token}`
            }
          }
        )
        if (!response.ok) {
          throw new Error("Failed to fetch data")
        }
        // Parse response
        const data = await response.json()
        // Update filteredList state
        handleChange("filteredList", data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error:", error)
      }
    }
  }

  const [offset, setOffset] = useState(0)
  const limit = 20
  const listRef = useRef(null)

  const fetchAllUsers = async (offset, limit) => {
    try {
      const url = `?limit=${limit}&offset=${offset}/`
      const res = await getUsers(url, token)
      return res
    } catch (error) {
      console.error("Error fetching users:", error)
      throw error
    }
  }
  const handleLoadMore = async () => {
    try {
      const nextPageOffset = offset + limit
      setOffset(nextPageOffset)

      const res = await fetchAllUsers(nextPageOffset, limit)
      const newData = res?.data?.results
      // console.log("appened response ===>", newData);
      const updatedData = updateFilteredList(
        [...state.filteredList, ...newData],
        allChannels
      )
      handleChange("filteredList", updatedData)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  useEffect(() => {
    handleChange("filteredList", allUsers)
  }, [visible])
  useEffect(() => {
    const handleScroll = () => {
      if (
        listRef.current.scrollTop + listRef.current.clientHeight >=
        listRef.current.scrollHeight
      ) {
        handleLoadMore()
      }
    }

    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleLoadMore])

  useEffect(() => {
    if (listRef.current) {
      const handleScroll = () => {
        console.log("List scrolled:", listRef.current.scrollTop)
      }

      listRef.current.addEventListener("scroll", handleScroll)

      return () => {
        listRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])
  console.log(allUsers, "allUsers")
  return (
    <Modal
      open={visible}
      onClose={() => {
        handleClose(false)
        handleChange("filteredList", allUsers)
        setSelectedIds([])
      }}
      sx={{ zIndex: 9999999 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-20 font-bold greyColor">Share</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => {
              setSelectedIds([])
              setState({
                filteredList: filteredList
              })
              handleClose(false)
            }}
          />
        </Grid>
        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              const urlString = window.location.href
              // Create a URL object
              const urlObject = new URL(urlString)
              // Extract base URL (protocol, hostname, and port)
              const baseURL = `${urlObject.protocol}//${urlObject.hostname}:${urlObject.port}/post/${post?.id}`
              handleCopyClick(baseURL)
            }}
            color="inherit"
            startIcon={<ShareLink />}
          >
            {isCopied ? "Copied!" : "Copy Link"}
          </Button>
        </Box>
        <div
          className="searchInputHeaderBox mb-3 mt-3"
          style={{ width: "100%" }}
        >
          <input
            className="searchInput"
            placeholder="Search..."
            onChange={e => filtered(e.target.value)}
          />
          <div className="saerchIconHeaderDiv">
            <SearchGrey />
          </div>
        </div>

        <div>
          {loading ? (
            // If loading is true, display the loading indicator
            <div
              style={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                minHeight: "380px",
                position: "relative" // To position it relative to its container
              }}
            >
              <div>loading</div>
            </div>
          ) : (
            // If loading is false, display either the list or "No data" message
            <React.Fragment>
              {filteredList?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    minHeight: "380px",
                    position: "relative" // To position it relative to its container
                  }}
                >
                  <div>No data</div>
                </div>
              ) : (
                <List
                  dense={dense}
                  ref={listRef}
                  style={{ overflowY: "scroll", height: 380 }}
                >
                  {filteredList?.map((item, index) => {
                    const usernames = [
                      item?.player?.username,
                      item?.coach?.username,
                      item?.parent?.username,
                      item?.organization?.username
                    ]
                    const allUndefined = usernames.every(
                      username => username == null
                    )
                    const displayedUsername = allUndefined
                      ? "no username"
                      : usernames.filter(Boolean).join(", ")
                    console.log(displayedUsername, "displayedUsername==")
                    return (
                      <ListItem
                        key={index}
                        style={{
                          padding: "10px",
                          backgroundColor: "#FAFAFA",
                          borderRadius: "16px",
                          marginBottom: "10px",
                          width: "100%"
                        }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <Checkbox
                              checked={selectedIds?.some(
                                idObj =>
                                  (item.channel_id &&
                                    idObj.channel_id === item.channel_id) ||
                                  (item.id && idObj.user_id === item.id)
                              )}
                              onChange={e => {
                                console.log(item, "item")
                                let user_id = null
                                let channel_id = null
                                // Logic to determine user_id and channel_id based on the item structure
                                if (item.channel_id) {
                                  channel_id = item.channel_id
                                } else if (item.player) {
                                  user_id = item?.id
                                } else if (item.coach) {
                                  user_id = item?.id
                                } else if (item.parent) {
                                  user_id = item?.id
                                } else if (item.organization) {
                                  user_id = item?.id
                                }
                                // Call handleCheckboxChange with the checkbox event and the determined id
                                handleCheckboxChange(e, {
                                  user_id,
                                  channel_id
                                })
                              }}
                              // Assuming you want checkboxes unchecked initially
                            />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={
                              item?.player?.picture ||
                              item?.coach?.picture ||
                              item?.parent?.picture ||
                              item?.organization?.picture ||
                              avatar
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          // Display email and role of the user
                          primary={`${item?.name} (${displayedUsername})`}
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" }
                          }}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              )}
            </React.Fragment>
          )}
        </div>

        <Box sx={{ display: "flex", justifyContent: "end", marginTop: 3 }}>
          {sendMessageLoader ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                sendMessage()
              }}
              endIcon={<SendIcon />}
            >
              Send Message
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
