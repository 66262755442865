import * as React from "react"
import { styled } from "@mui/material/styles"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import InputBase from "@mui/material/InputBase"
import { MenuItem, Select } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function AppSelect({ data, placeholder, ...props }) {
  return (
    <div>
      <FormControl fullWidth size="small" sx={{ minWidth: 70 }}>
        <Select
          {...props}
          sx={{
            bgcolor: "#fff",
            height: 30,
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 3,
              borderColor: "#fff"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderRadius: 3,
              borderColor: "#fff"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderRadius: 3,
              borderColor: "#fff"
            }
          }}
          IconComponent={() => (
            <ExpandMoreIcon
              color="#222"
              fontSize={"small"}
              style={{ marginLeft: -25 }}
            />
          )}
          displayEmpty
          renderValue={
            props?.value ? undefined : () => placeholder || "Switch Team"
          }
        >
          {data?.map(v => (
            <MenuItem value={v.value}>{v.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
