// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react"
import PubNub from "pubnub"
import { PubNubProvider } from "pubnub-react"
import AppContext from "../../Context"
import Messages from "."

export default function ChatScreen({}) {
  const { user } = useContext(AppContext)
  const [pubnub, setPubnub] = useState(null)

  useEffect(() => {
    if (user?.id) {
      const _pubnub = new PubNub({
        publishKey: "pub-c-b34236d7-2411-4ade-8eaa-b3133ed5681a",
        subscribeKey: "sub-c-dab9409f-4a2f-4b0e-81bf-16003ee34a43",
        uuid: user?.id?.toString() || "Guest",
        secretKey: "sec-c-ZDE4NjdlZGUtOWM0NS00MWNjLTllMDYtM2U1YjYyMDQxNDU4"
      })
      setPubnub(_pubnub)
    }
  }, [user])

  return (
    <div>
      {pubnub && (
        <PubNubProvider client={pubnub}>
          <Messages />
        </PubNubProvider>
      )}
    </div>
  )
}
