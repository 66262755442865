export const getError = (error) => {
    const errorText = error?.response?.data ? error?.response?.data?.organization ? Object.values(error?.response?.data?.organization) : Object.values(error?.response?.data) : ''
    const errorKeys = error?.response?.data ? error?.response?.data?.organization ? Object.keys(error?.response?.data?.organization) : Object.keys(error?.response?.data) : ""
    if (error?.response?.data && error?.response?.data?.status !== 'fail' && error?.response?.data?.message) {
        return `Error:  ${error?.response?.data?.message}`
    } else if (error?.response?.data?.status === 'fail' && error?.response?.data?.message) {
        return `Error: ${error?.response?.data?.message}`
    } else if (error?.response?.data?.status !== 'fail' && Array.isArray(errorText) && errorText.length > 0) {
        return `Error: ${errorKeys?.[0]?.toLocaleUpperCase?.()} ${errorText[0]}`
    } else {
        return `Something went wrong`
    }
}