// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  HomeHeader,
  AppButton,
  AppInput,
  AddStats,
  Label,
  AdminLayout
} from "../../components"
import { Box, Grid, Modal, Typography } from "@mui/material"
import AvatarIcon from "../../assets/images/avatar.png"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"
import { getError } from "../../utils/getError"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getUserProfile } from "../../api/auth"
import { deleteUser } from "../../api/admin"
import { ReactComponent as DeleteOrangeIcon } from "../../assets/svg/admin/deleteOrange.svg"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 6,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
}

export default function AdminOrganizationProfileForm({}) {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem("admintoken")
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = useState({
    organization_name: "",
    email: "",
    program_director_name: "",
    phone_number: "",
    street_address: "",
    city: "",
    price_range: "",
    zip_code: "",
    selectedState: "",
    aau_membership_number: "",
    editMode: false,
    profileImg: null,
    statsVisible: false,
    loading: false,
    organizationData: null,
    visible: false,
    loadingDelete: false
  })

  const {
    loading,
    organization_name,
    email,
    program_director_name,
    phone_number,
    street_address,
    city,
    zip_code,
    selectedState,
    editMode,
    profileImg,
    aau_membership_number,
    price_low,
    price_high,
    organizationData,
    visible,
    loadingDelete
  } = state

  useEffect(() => {
    if (id) {
      _getProfileData()
    }
  }, [id])

  const _getProfileData = async noLoading => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("admintoken")
      const res = await getUserProfile(id, token)
      const organizationData = res?.data?.organization
      handleChange("organizationData", organizationData)
      handleChange("organization_name", organizationData?.organization_name)
      handleChange(
        "program_director_name",
        organizationData?.program_director_name
      )
      handleChange("phone_number", organizationData?.user?.phone_number)
      handleChange("price_low", organizationData?.price_low)
      handleChange("price_high", organizationData?.price_high)
      handleChange("price_range", organizationData?.price_range)
      handleChange(
        "aau_membership_number",
        organizationData?.aau_membership_number
      )
      handleChange("city", organizationData?.user?.city)
      handleChange("email", organizationData?.user?.email)
      handleChange("street_address", organizationData?.user?.street_address)
      handleChange("zip_code", organizationData?.user?.zip_code)
      handleChange("latitude", organizationData?.user?.latitude)
      handleChange("longitude", organizationData?.user?.longitude)
      handleChange("selectedState", organizationData?.user?.state)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteUser = async () => {
    try {
      handleChange("loadingDelete", true)
      await deleteUser(id, token)
      handleChange("loadingDelete", false)
      navigate(-1)
      enqueueSnackbar("User is Deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingDelete", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleOpenDelete = () => {
    handleChange("visible", true)
  }
  const handleCloseDelete = () => {
    handleChange("visible", false)
  }

  return (
    <div className="">
      <AdminLayout>
        <section className="container pb-3 mb-0 bg-transparent">
          <Grid className="mb-3" container justifyContent={"space-between"}>
            <div className="pageHeading">Profile</div>
          </Grid>
          <Grid
            className="mb-3 mt-4"
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <div className="">
              <img
                src={profileImg || organizationData?.logo || AvatarIcon}
                className={"c-pointer profileIMG"}
              />
            </div>
            <AppButton
              width={100}
              height={35}
              borderRadius={5}
              onClick={handleOpenDelete}
              backgroundColor={COLORS.white}
              color={"#595959"}
              borderColor={"#D9D9D9"}
              className={"min-width-remove"}
              title={"Delete"}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"Organization Name"}
                height={40}
                onChange={handleChange}
                value={organization_name}
                name={"organization_name"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"Program Director"}
                height={40}
                onChange={handleChange}
                value={program_director_name}
                name={"program_director_name"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"Email"}
                height={40}
                onChange={handleChange}
                value={email}
                name={"email"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                placeholder={"123-456-7890"}
                label={"Phone number"}
                type={"number"}
                height={40}
                onChange={handleChange}
                value={phone_number}
                name={"phone_number"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"AAU Membership Number"}
                height={40}
                onChange={handleChange}
                value={aau_membership_number}
                name={"aau_membership_number"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text={"Ticket Price Range"} fontNormal />
              <Grid container sx={{ mt: -1 }}>
                <Grid item xs={5.5}>
                  <AppInput
                    disabled={!editMode}
                    type={"number"}
                    prefix={<div className="mt-2">$</div>}
                    label={""}
                    height={40}
                    onChange={handleChange}
                    value={price_low}
                    name={"price_low"}
                  />
                </Grid>
                <Grid item xs={1} className="dashCenter">
                  {" "}
                  -{" "}
                </Grid>
                <Grid item xs={5.5}>
                  <AppInput
                    disabled={!editMode}
                    type={"number"}
                    prefix={<div className="mt-2">$</div>}
                    label={""}
                    height={40}
                    onChange={handleChange}
                    value={price_high}
                    name={"price_high"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 1, mb: 3 }}>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={true}
                label={"Street address"}
                height={40}
                onChange={handleChange}
                value={street_address}
                name={"street_address"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"City"}
                height={40}
                onChange={handleChange}
                value={city}
                name={"city"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"Zipcode"}
                height={40}
                onChange={handleChange}
                value={zip_code}
                name={"zip_code"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AppInput
                disabled={!editMode}
                label={"State"}
                height={40}
                onChange={handleChange}
                value={selectedState}
                name={"selectedState"}
              />
            </Grid>
          </Grid>
        </section>
      </AdminLayout>
      <Modal
        open={visible}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DeleteOrangeIcon className="mt-3" />
            </Grid>
            <Grid item xs={10}>
              <Typography
                id="modal-modal-description"
                className="text-black"
                sx={{ mt: 2 }}
              >
                Are you sure you want to delete this user? You won’t be able to
                recover it
              </Typography>
            </Grid>
          </Grid>
          <Grid className="mt-3">
            <AppButton
              title={"Delete"}
              height={40}
              loading={loadingDelete}
              onClick={() => _deleteUser()}
              width={"100%"}
              fontWeight={"normal"}
              backgroundColor={COLORS.orange}
              color={COLORS.white}
            />
            <AppButton
              title={"Cancel"}
              height={40}
              onClick={handleCloseDelete}
              width={"100%"}
              backgroundColor={"#fff"}
              className={"mt-2"}
              color={"#595959"}
              borderColor={"#D9D9D9"}
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
