import React, { useContext, useEffect, useState } from 'react'
import { AppButton, AppInput, Loader } from '..'
import { Box, Grid, Modal, Popover, Typography, } from '@mui/material'
import { COLORS } from '../../constants';
import { ReactComponent as Cross } from '../../assets/svg/Cross.svg'
import { ReactComponent as BackWhite } from "../../assets/svg/backWhite.svg"
import AppContext from '../../Context';
import { reviewCatQuestionChoices, reviewCatQuestions, reviewCoachCatQuestions, submitCoachQuestionReview, submitQuestionReview } from '../../api/auth';
import { getError } from '../../utils/getError';
import { useSnackbar } from 'notistack';


function AddCoachQuestionsReview({ getData, userData, visible, handleClose, questionReviews }) {
    const { hashtagsList } = useContext(AppContext)
    const { enqueueSnackbar } = useSnackbar()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        borderRadius: 6,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        loading: false,
        activeDot: null,
        activeIndex: null,
        reviewCatQuestionList: [],
        reviewChoices: [],
        selectedChoices: [],
    })
    const {
        activeDot,
        activeIndex,
        reviewCatQuestionList,
        reviewChoices,
        selectedChoices,
        loading
    } = state

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    useEffect(() => {
        if (questionReviews?.length > 0) {
            handleChange('activeDot', questionReviews[0])
            handleChange('activeIndex', 0)
            const list = []
            questionReviews?.forEach(element => {
                const obj = {
                    category: element?.id,
                    choices: []
                }
                list.push(obj)
            });
            handleChange('selectedChoices', list)
        }
    }, [questionReviews?.length])
    useEffect(() => {
        if (visible && questionReviews?.length > 0) {
            _getData(questionReviews[0]?.id)
            _reviewCatQuestionChoices()
        }
    }, [questionReviews?.length, visible])

    console.log('selectedChoices', selectedChoices);

    const _getData = async (category_id) => {
        try {
            handleChange('loading', true)
            const token = localStorage.getItem("token")
            const payload = `?category_id=${category_id}&coach_id=${userData?.coach?.id}`
            const res = await reviewCoachCatQuestions(payload, token)
            handleChange('loading', false)
            handleChange('reviewCatQuestionList', res?.data)
            const list = []
            const activeCat = selectedChoices?.find(e => e?.category === category_id)
            selectedChoices?.forEach(element => {
                if (element?.category === category_id) {
                    const choicesList = []
                    res?.data?.map(question => {
                        const already = element?.choices?.find(e => e?.question === question?.id)
                        const removed = element?.choices?.filter(e => e?.question !== question?.id)
                        if (already) {
                            choicesList.push(already)
                        } else {
                            choicesList.push({ question: question?.id, choice: question?.previous_choice?.choice?.id })
                        }
                    })
                    list.push({ ...element, choices: choicesList })
                } else {
                    list.push(element)
                }
            })
            // res?.data?.map(question => {
            //     const already = activeCat?.choices?.find(e => e?.question === question?.id)
            //     const removed = activeCat?.choices?.filter(e => e?.question !== question?.id)
            //     if (already) {
            //         list.push(activeCat)
            //     } else {
            //         list.push({ ...activeCat, choices: [...removed, { question: question?.id, choice: question?.previous_choice?.choice?.id }] })
            //     }
            //     // activeCat?.choices?.forEach(element => {
            //     //     if (element?.category === question?.category && question?.id !== element?.question) {
            //     //         const already = element?.choices?.find(e => e?.question === question?.id)
            //     //         const removed = element?.choices?.filter(e => e?.question !== question?.id)
            //     //         if (already) {
            //     //             list.push(element)
            //     //         } else {
            //     //             list.push({ ...element, choices: [...removed, { question: question?.id, choice: question?.previous_choice?.choice?.id }] })
            //     //         }
            //     //     } else {
            //     //         list.push(element)
            //     //     }
            //     // });
            // })
            handleChange('selectedChoices', list)
        } catch (error) {
            handleChange('loading', false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const _reviewCatQuestionChoices = async () => {
        try {
            handleChange('loadingChoices', true)
            const token = localStorage.getItem("token")
            const res = await reviewCatQuestionChoices(token)
            handleChange('loadingChoices', false)
            handleChange('reviewChoices', res?.data?.results)
        } catch (error) {
            handleChange('loadingChoices', false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const _submitQuestionReview = async () => {
        try {
            handleChange('loadingSubmit', true)
            const token = localStorage.getItem("token")
            const list = []
            selectedChoices?.forEach(element => {
                if (element?.choices?.length > 0) {
                    list.push(element)
                }
            });
            const payload = {
                coach_id: userData?.coach?.id,
                choice_question_ids: list
            }
            await submitCoachQuestionReview(payload, token)
            handleChange('loadingSubmit', false)
            getData(userData?.coach?.id)
            handleCloseModal(false)
        } catch (error) {
            handleChange('loadingSubmit', false)
            enqueueSnackbar(getError(error), {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            })
        }
    }

    const handleCloseModal = () => {
        handleChange('selectedChoices', [])
        getData(userData?.coach?.id)
        handleClose(false)
    }

    const finalIndex = activeIndex === questionReviews?.length - 1
    const handleNext = () => {
        if (finalIndex) {
            _submitQuestionReview()
        }
        else if (activeIndex < questionReviews?.length - 1) {
            handleChange('activeDot', questionReviews[activeIndex + 1])
            handleChange('activeIndex', activeIndex + 1)
            _getData(questionReviews[activeIndex + 1]?.id)
        }
    }

    const handlePrevious = () => {
        if (activeIndex > 0) {
            handleChange('activeDot', questionReviews[activeIndex - 1])
            handleChange('activeIndex', activeIndex - 1)
            _getData(questionReviews[activeIndex - 1]?.id)
        }
    }

    return (
        <Modal
            open={visible}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style, maxHeight: '90%', overflow: "auto", width: { xs: "90%", md: '60%' }, }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'} className='mb-3'>
                    <div className='font-24 font-bold greyColor'>Add Review</div>
                    <div className='d-flex'>
                        {
                            questionReviews?.map((dot, index) => (
                                <div className='dotReview' style={{ backgroundColor: index === activeIndex ? "#FAAD14" : index < activeIndex ? '#52C41A' : "#00000040" }} />
                            ))
                        }
                    </div>
                    <AppButton width={35} height={35} borderRadius={35} className={'min-width-remove'} backgroundColor={COLORS.white} borderColor={'#D9D9D9'} title={<Cross className='mb-1 mr-1' />} onClick={() => handleCloseModal(false)} />
                </Grid>
                {
                    loading ?
                        <Loader width={50} height={50} margin={"37px auto"} />
                        :

                        <Grid>
                            <div className='d-flex align-items-center'>
                                <img className="reviewImageModal" src={activeDot?.default_logo ? activeDot?.default_logo : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"} />
                                <div className='font-500 ml-3 font-16 text-black'>{activeDot?.name}</div>
                            </div>
                            {
                                reviewCatQuestionList?.map((question, indexx) => (
                                    <div key={indexx} className='mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <div className='font-500 font-16 text-black'>Q{question?.order}. {question?.question}</div>
                                        </div>
                                        <Grid container justifyContent={'space-between'} className='mt-3'>
                                            {
                                                reviewChoices?.map((choice, indexxx) => (
                                                    <div className='d-flex align-items-center' key={indexxx}>
                                                        <input type='radio' className='loginGenderRadio mr-2' checked={selectedChoices?.[activeIndex]?.choices?.some(e => e?.question === question?.id && e?.choice === choice?.id)}
                                                            onClick={() => {
                                                                if (selectedChoices?.[activeIndex]?.choices?.some(e => e?.question === question?.id && e?.choice === choice?.id)) {
                                                                    const list = []
                                                                    selectedChoices?.forEach(element => {
                                                                        if (element?.category === activeDot?.id) {
                                                                            const removed = element?.choices?.filter(e => e?.question !== question?.id)
                                                                            list.push({ ...element, choices: removed })
                                                                        } else {
                                                                            list.push(element)
                                                                        }
                                                                    });
                                                                    handleChange('selectedChoices', list)
                                                                } else {
                                                                    const list = []
                                                                    selectedChoices?.forEach(element => {
                                                                        if (element?.category === activeDot?.id) {
                                                                            const removed = element?.choices?.filter(e => e?.question !== question?.id)
                                                                            list.push({ ...element, choices: [...removed, { question: question?.id, choice: choice?.id }] })
                                                                        } else {
                                                                            list.push(element)
                                                                        }
                                                                    });
                                                                    handleChange('selectedChoices', list)
                                                                }
                                                            }}
                                                        />
                                                        <div className='text-black cursor'
                                                            onClick={() => {
                                                                if (selectedChoices?.[activeIndex]?.choices?.some(e => e?.question === question?.id && e?.choice === choice?.id)) {
                                                                    const list = []
                                                                    selectedChoices?.forEach(element => {
                                                                        if (element?.category === activeDot?.id) {
                                                                            const removed = element?.choices?.filter(e => e?.question !== question?.id)
                                                                            list.push({ ...element, choices: removed })
                                                                        } else {
                                                                            list.push(element)
                                                                        }
                                                                    });
                                                                    handleChange('selectedChoices', list)
                                                                } else {
                                                                    const list = []
                                                                    selectedChoices?.forEach(element => {
                                                                        if (element?.category === activeDot?.id) {
                                                                            const removed = element?.choices?.filter(e => e?.question !== question?.id)
                                                                            list.push({ ...element, choices: [...removed, { question: question?.id, choice: choice?.id }] })
                                                                        } else {
                                                                            list.push(element)
                                                                        }
                                                                    });
                                                                    handleChange('selectedChoices', list)
                                                                }
                                                            }}
                                                        >{choice?.choice}</div>
                                                    </div>
                                                ))
                                            }
                                        </Grid>
                                    </div>
                                ))
                            }
                        </Grid>
                }
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    {
                        activeIndex > 0 ?
                            <AppButton title={<div><BackWhite width={20} height={12} style={{ marginTop: -2, marginLeft: -5 }} /> Previous</div>} backgroundColor={COLORS.primary} color={COLORS.white} width={100} height={40} className={'mt-5'} onClick={handlePrevious} />
                            : <div />
                    }
                    <AppButton title={finalIndex ? "Save" : <div>Next <BackWhite width={20} height={12} style={{ transform: "rotate(180deg)", marginTop: -2, marginRight: -5 }} /></div>} disabled={activeIndex < 0} backgroundColor={COLORS.primary} color={COLORS.white} width={100} height={40} className={'mt-5'} onClick={handleNext} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default AddCoachQuestionsReview