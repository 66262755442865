// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import {
  HomeHeader,
  AppButton,
  OrganizationTeamTable,
  AddReview,
  AddStats,
  AddCoachQuestionsReview,
  Loader,
  Post
} from "../../components"
import { Grid, Divider, Box, Avatar } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import AvatarIcon from "../../assets/images/avatar.png"
import {
  checkCoachReviewStatus,
  createChannel,
  declineLink,
  getAllPosts,
  getChannels,
  getOrganizationProfile,
  getOrganizationTeam,
  getUserProfile,
  reviewsCoachCatScore,
  searchUserProfile
} from "../../api/auth"
import SeeMoreText from "../../components/Text/SeeMoreText"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { useEffect } from "react"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { COLORS } from "../../constants"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import AddIcon from "@mui/icons-material/Add"
import LinkProfile from "../../components/GeneralModal/LinkPlayer"
import { getUsers } from "../../api/admin"
import DeleteIcon from "@mui/icons-material/Delete"

export default function ParentProfile({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const { user, _getProfile, isPlayer, isParent } = useContext(AppContext)
  const [state, setState] = useState({
    showAll: false,
    reviewVisible: false,
    statsVisible: false,
    teams: [],
    loading: false,
    userData: null,
    questionReviews: [],
    allUsers: [],
    allChannels: [],
    videoPlaying: "",
    showComment: "",
    posts: [],
    reviewStatus: false,
    questionsReviewVisible: false,
    loadingChat: false,
    organizationData: null,
    followVisible: false,
    loadingRemove: "",
    loadingFollow: "",
    allPlayers: [],
    linkedPlayers: [],
    playerId: {},
    linkVisible: false
  })

  const {
    showAll,
    teams,
    loading,
    userData,
    videoPlaying,
    showComment,
    posts,
    allUsers,
    allChannels,
    questionReviews,
    reviewStatus,
    questionsReviewVisible,
    loadingChat,
    followVisible,
    loadingRemove,
    loadingFollow,
    statsVisible,
    allPlayers,
    linkedPlayers,
    playerId,
    linkVisible
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    _getProfile()
    // getAllPlayers()
    getLinkedPlayers(id)
    if (id) {
      getProfileData()
      _getAllPosts()
      _getAllSharedUsers()
      _getChannels()
    }
  }, [id, linkVisible, showAll])

  // const getAllPlayers = async () => {
  //   try {
  //     handleChange("loading", true)
  //     const res = await searchUserProfile(
  //       "?limit=1000&offset=20&role=Player",
  //       token
  //     )
  //     console.log(res, "user all profile")
  //     handleChange("allPlayers", res?.data?.results)
  //     handleChange("loading", false)
  //   } catch (error) {
  //     handleChange("loading", false)
  //     enqueueSnackbar(getError(error), {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right"
  //       }
  //     })
  //   }
  // }
  const getLinkedPlayers = async () => {
    try {
      handleChange("loading", true)
      // const res = await searchUserProfile(`linked_relationships/`, token)
      let endpoint = `linked_relationships/`
      if (id) {
        endpoint += `?user_id=${id}`
      }
      const res = await searchUserProfile(endpoint, token)
      console.log(res, "getLinkedPlayers")
      handleChange("linkedPlayers", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const res = await getAllPosts(`?user__id=${id}`, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      handleChange("userData", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      navigate(`/messages/${res?.data?.id}`)
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleOpenFollow = type => {
    handleChange("followVisible", true)
    handleChange("followVisibleType", type)
  }

  const handleCloseLink = () => {
    handleChange("linkVisible", false)
  }

  const handleOpenLink = type => {
    handleChange("linkVisible", true)
  }

  const handleCloseFollow = () => {
    handleChange("linkVisible", false)
    handleChange("followVisibleType", "")
  }

  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
    if (getTeam) {
      // getData(0)
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        console.log(res, "user all profile")
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const declineLinkAction = async linkId => {
    try {
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      enqueueSnackbar(`Linking request deleted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", !showAll)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", true)
    }
  }
  const isOwnProfile = id == user?.id

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ paddingTop: "3rem" }}>
            <section className="container p-0 width60 bg-transparent">
              {/* <p className={"nameOrganization font-24 mt-4"}>
                {isOwnProfile ? "Your" : "Parent"} Profile
              </p> */}
              <Grid container alignItems={"center"} gap={5}>
                <Grid item>
                  <img
                    src={
                      userData?.parent?.picture
                        ? userData?.parent?.picture
                        : AvatarIcon
                    }
                    className={"profileIMG"}
                  />
                  <Box
                    sx={{
                      marginTop: -4,
                      marginLeft: 10,
                      marginBottom: 3
                    }}
                    className="Ball-Responsive"
                  >
                    <Avatar
                      src={userData?.parent?.main_sport?.image}
                      sx={{ width: 60, borderRadius: 2, height: 60 }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <p className="parent_name">{userData?.name}</p>
                  {/* <div
                    className="parentEmailNumber"
                    style={{ color: "rgba(0, 0, 0, 0.45)", gap: 10 }}
                  >
                    <span>{userData?.email}</span>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        borderRightWidth: 3,
                        borderColor: "rgba(0, 0, 0, 0.45)"
                      }}
                    />
                    <span>{userData?.phone_number}</span>
                  </div> */}
                </Grid>
                <Grid
                  item
                  // container
                  direction={"column"}
                  // alignItems={"flex-end"}
                  sx={{ mb: 4, mt: 0 }}
                // xs={12}
                // md={4}
                >
                  {isOwnProfile && (
                    <AppButton
                      width={35}
                      height={35}
                      borderRadius={35}
                      onClick={() => navigate(`/parent-profile/${id}/edit`)}
                      backgroundColor={COLORS.primary}
                      className={"min-width-remove mb-3"}
                      title={<Edit />}
                    />
                  )}
                  {user?.id != id && (
                    <AppButton
                      width={120}
                      height={35}
                      className={"ml-3 mt-2"}
                      loading={loadingChat}
                      color={"#000000D9"}
                      backgroundColor={"#FFFFFF"}
                      borderColor={"#D9D9D9"}
                      onClick={_createChannel}
                      title={
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: -5 }}
                        >
                          <MessageFill style={{ marginTop: -3 }} width={20} />{" "}
                          <span className="ml-1">Message</span>
                        </div>
                      }
                      fontWeight={"400"}
                    />
                  )}
                </Grid>
              </Grid>
            </section>
          </div>
          <section className="container p-0 width60 bg-transparent">
            <div>
              <p className="font-bold font-18">Bio</p>
              <span className="bioOrganization">
                <SeeMoreText text={userData?.parent?.bio} maxLength={275} />
              </span>
            </div>
          </section>

          {/* <section className="container width70 p-0 pl-3 bg-transparent">
            <Grid container className="reviewBox">
              {questionReviews?.map((item, index) => (
                <Grid
                  key={index}
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  item
                  xs={6}
                  md={4}
                  lg={1.7}
                  style={{
                    padding: "10px 10px",
                    border:
                      index === questionReviews?.length - 1
                        ? "none"
                        : "1px solid rgba(0, 0, 0, 0.06)"
                  }}
                >
                  {" "}
                  <img
                    src={
                      item[item?.display_icon]
                        ? item[item?.display_icon]
                        : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                    }
                    className="reviewImage"
                  />
                  <div className="font-bold text-black text-center">
                    {item?.name}
                  </div>
                </Grid>
              ))}
            </Grid>
          </section> */}
          {/* <div className="">
            <section className="container width70 p-0 bg-transparent">
              <Grid item xs={12} className="">
                <div className="font-bold mb-3 text-black">
                  Personal information
                </div>
              </Grid>
              <Grid container className="border p-3">
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Name</div>
                  <div className="valueCoach">{userData?.name}</div>
                </Grid>
                <Grid item xs={12} className="p-3">
                  <div className="labelCoach">Email</div>
                  <div className="valueCoach">{userData?.email}</div>
                </Grid>
                <Grid item xs={12} md={6} className=" p-3">
                  <div className="labelCoach">Phone Number</div>
                  <div className="valueCoach">{userData?.phone_number}</div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"}>
                <AppButton
                  title={
                    isOwnProfile
                      ? "Post Tournament"
                      : "Enquiry Training Sessions"
                  }
                  backgroundColor={COLORS.primary}
                  color={COLORS.white}
                  width={isOwnProfile ? 150 : 200}
                  height={40}
                  className={"mt-4 mb-4"}
                />
              </Grid>
            </section>
          </div> */}
        </>
      )}

      <LinkProfile
        visible={linkVisible}
        loadingRemove={loadingRemove}
        loading={loadingFollow}
        handleClose={handleCloseLink}
        data={allPlayers}
      />

      <AddStats
        visible={statsVisible}
        playerId={playerId}
        handleClose={handleClose}
      />
    </div>
  )
}
