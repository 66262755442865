import { API } from "./"

export const getDashboard = token => {
  return API.get(`api/v1/admin/dashboard/`, token)
}
export const getAllMentionUsers = token => {
  return API.get(`api/v1/all-ids/`, token)
}
export const getAllUsers = (payload, token) => {
  return API.get(`users/all-users/${payload}`, token)
}
export const getAllUsersGet = token => {
  return API.get(`api/v1/all-user-get/`, token)
}
export const getUsers = (payload, token) => {
  return API.get(`api/v1/users/${payload}`, token)
}
export const getZipcodes = (payload, token) => {
  return API.get(`api/v1/admin/zipcodes/${payload}`, token)
}

export const updateZipcodes = (id, payload, token) => {
  return API.patch(`api/v1/admin/zipcodes/${id}/`, payload, token)
}

export const updateUser = (id, payload, token) => {
  return API.patch(`api/v1/admin/users/${id}/`, payload, token)
}

export const deleteZipcodes = (id, token) => {
  return API.delete(`api/v1/admin/zipcodes/${id}/`, {}, token)
}

export const deleteOrder = (id, token) => {
  return API.delete(`api/v1/admin/orders/${id}/`, {}, token)
}

export const deleteUser = (id, token) => {
  return API.delete(`users/all-users/${id}/`, {}, token)
}

export const addZipcodes = (payload, token) => {
  return API.post(`api/v1/admin/zipcodes/`, payload, token)
}

export const getOrders = (payload, token) => {
  return API.get(`api/v1/admin/orders/${payload}`, token)
}

export const getOrderDetails = (id, token) => {
  return API.get(`api/v1/admin/orders/${id}/`, token)
}

export const getFeedbacks = (payload, token) => {
  return API.get(`users/send/feedback/${payload}`, token)
}
export const reportedUser = (payload, token) => {
  return API.post(`/users/report-users/`, payload, token)
}

export const getReportedUsers = (payload, token) => {
  return API.get(`users/report-users/${payload}`, token)
}
export const deleteReportedUsers = (id, token) => {
  return API.delete(`/users/report-users/${id}/`, {}, token)
}

export const banUser = (id, token) => {
  return API.post(`/users/api/ban-user/${id}/`, {}, token)
}

export const unBanUser = (id, token) => {
  return API.post(`/users/api/unban-user/${id}/`, {}, token)
}
export const getPriceRange = token => {
  return API.get(`/api/v1/price-range/`, token)
}


export const blockUser = (payload, token) => {
  return API.post(`/api/v1/block-users/`, payload, token)
}

export const getBlockedUsers = (token) => {
  return API.get(`/api/v1/block-users/`, token)
}